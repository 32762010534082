import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, TextField } from "@mui/material";

function CustomRadioGroup({ disabled, options, selectedValue, onChange }) {
  return (
    <FormControl>
      <RadioGroup
        row
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: 18,
          },
        }}
        name="custom-radio-buttons-group"
        value={selectedValue}
        onChange={onChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            control={<Radio disabled={disabled} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default CustomRadioGroup;
