import * as React from "react";
import * as XLSX from "xlsx";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../componets/apiConfig";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem } from "@mui/material";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../mui/useFullPageLoader";
import DataTable from "react-data-table-component";

const ViewTarget = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const columns = [
    {
      name: "Sales Person",
      selector: (row) => row.Sales_Person,
      sortable: true,
    },
    {
      name: "Month",
      selector: (row) => row.Month_Name,
      sortable: true,
    },
    {
      name: "Year",
      selector: (row) => row.Year,
      sortable: true,
    },
    {
      name: "Achivement",
      selector: (row) => row.Achivement,
      sortable: true,
    },
    {
      name: "Overall Monthly Target",
      selector: (row) => row.Overall_Monthly_Target,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            onClick={(event) => handleMenuClick(event, row.Id)}
            aria-controls="row-menu"
            aria-haspopup="true"
          >
            <i className="ri-more-2-fill" style={{ fontSize: "16px" }}></i>
          </IconButton>
          <Menu
            id="row-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {/* <MenuItem>
              <Link
                to={`/updateTarget/${selectedRowSrNo}`}
                className="dropdown-item"
              >
                <span> Update</span>
              </Link>
            </MenuItem> */}

            <MenuItem
              onClick={() => {
                handleMenuClose();
                deleteUser(selectedRowSrNo);
              }}
              style={{ color: "red" }}
            >
              <span> Delete</span>
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowSrNo, setSelectedRowSrNo] = useState(null);
  const [uniqueStatus, setUniqueStatus] = useState([]);
  const [records, setRecords] = useState([]);
  const [data, setData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("-1");

  useEffect(() => {
    (async () => {
      loadExpensesData();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      handleFilterButton();
    })();
  }, [selectedStatus]);

  const loadExpensesData = async () => {
    showLoader();
    const response = await axios.get(
      `${apiUrl}/getTargetSalesPersonByMonth?Month=${currentMonth}&Year=${currentYear}`
    );
    const res = await axios.get(`${apiUrl}/getTargetSalesPerson`);
    const status = [...new Set(res.data.map((item) => item.Month_Name))];
    const sortedData = response.data.sort((a, b) => b.Id - a.Id);
    setUniqueStatus(status);
    setData(sortedData);
    setRecords(sortedData);
    hideLoader();
    return response, res;
  };

  const handleFilterButton = async () => {
    showLoader();
    const response = await axios.get(
      `${apiUrl}/getTargetSalesPersonByMonth?Month=${currentMonth}&Year=${currentYear}`
    );
    const sortedData = response.data.sort((a, b) => b.Id - a.Id);
    setData(sortedData);
    setRecords(sortedData);
    hideLoader();
  };

  const deleteUser = (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id);
      }
    });
  };

  const handleMenuClick = (event, Id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowSrNo(Id);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    loadExpensesData();
  };

  async function handleDelete(Id) {
    await axios.delete(`${apiUrl}/deleteTargetSalesPerson/${Id}`);
    Swal.fire("Deleted!", "Your file has been deleted.", "success");
    loadExpensesData();
  }

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("SalesPerson Targets", 20, 10);

    const columns = ["Sales Person", "Month", "Year", "Achivement", "Overall Monthly Target"];

    const rows = records.map((row) => [
      row.Sales_Person,
      row.Month_Name,
      row.Year,
      row.Achivement,
      row.Overall_Monthly_Target,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("SalesPerson_Target.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Sales Person", "Month", "Year","Achivement", "Overall Monthly Target"];

    const rows = [columns];
    records.forEach((row) => {
      rows.push([
        row.Sales_Person,
        row.Month_Name,
        row.Year,
        row.Achivement,
        row.Overall_Monthly_Target,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Sales Person Targets");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "SalesPerson_Target.xlsx");
  };

  const filterData =  (selectedRole) => {
    setSelectedStatus(selectedRole);
   
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();

    const filteredData = data.filter((row) => {
      return (
        (row.Sales_Person &&
          row.Sales_Person.toLowerCase().includes(filterValue)) ||
        (row.Month_Name &&
          row.Month_Name.toLowerCase().includes(filterValue)) ||
        (row.Year && row.Year.toLowerCase().includes(filterValue)) ||
        (row.Achivement &&
          row.Achivement.toString().toLowerCase().includes(filterValue))||
        (row.Overall_Monthly_Target &&
          row.Overall_Monthly_Target.toString().toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }
  const monthChanged = (event) => {
    const month = parseInt(event.target.value);
    setCurrentMonth(month);
  };
  const yearChanged = (event) => {
    const year = parseInt(event.target.value);
    setCurrentYear(year);
  };
  const Filter = async () => {loadExpensesData();}

  return (
    <div className="row">
        <div className="col-12">          
        <div class="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 class="page-title">View Target</h4>
        </div>
            <div className="card">
              <h4 className="card-title" style={{ margin: "10px 0 0 10px" }}>
                Filters
              </h4>
              <div className="card-body" style={{ paddingTop: "5px" }}>
                <div className="row justify-content-center mt-2">
                  <div className="col-4">
                    <label htmlFor="example-select" className="form-label">
                      Month
                    </label>
                    <select
                      className="form-select"
                      id="example-select"
                      onChange={monthChanged}
                      defaultValue={currentMonth}
                    >
                      <option value={1}>January</option>
                      <option value={2}>February</option>
                      <option value={3}>March</option>
                      <option value={4}>April</option>
                      <option value={5}>May</option>
                      <option value={6}>June</option>
                      <option value={7}>July</option>
                      <option value={8}>August</option>
                      <option value={9}>September</option>
                      <option value={10}>October</option>
                      <option value={11}>November</option>
                      <option value={12}>December</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <label htmlFor="example-select" className="form-label">
                      Year
                    </label>
                    <select
                      className="form-select"
                      id="example-select"
                      onChange={yearChanged}
                      defaultValue={currentYear}
                    >
                      <option value={2022}>2022</option>
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                      <option value={2025}>2025</option>
                    </select>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-2">
                    <button
                      className="btn btn-primary"
                      onClick={Filter}
                      style={{ width: "100%" }}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      <div className="col-12 ">
        <div className="card">
          <div className="card-body">
            <div>
              <>
                {/* <div className="row d-flex justify-content-end mb-2 ml-1">
                  <div className="col-2 ">
                    <label className="form-label">Filter By Month:</label>
                    <select
                      className="form-select"
                      id="example-select"
                      onChange={(e) => filterData(e.target.value)}
                    >
                      <option value={"-1"}>All</option>
                      {uniqueStatus.map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}

                <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
                  <TextField
                    onChange={handleFilter}
                    size="small"
                    label="Search..."
                  />

                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  ></Typography>
                  <Tooltip title="Export to PDF">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </IconButton>
                  </Tooltip>
                  <Link to={"/setTarget"}>
                    <Button variant="contained" endIcon={<AddCircleIcon />}>
                      Add
                    </Button>
                  </Link>
                </Stack>
                <Box height={10} />
                <div style={{ zIndex: 0, position: "relative" }}>
                  <DataTable
                    columns={columns}
                    data={records}
                    customStyles={customStyles}
                    fixedHeader
                    pagination
                  />
                </div>
              </>
              {loader}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTarget;
