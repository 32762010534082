import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { useUser } from "../../componets/UserContext";
import { apiUrl } from "../../componets/apiConfig";
import { Link } from "react-router-dom";

const LateHalfDay = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const [data, setData] = useState({
    Late_Mark_In: "",
    Late_Mark_Out: "",
    HalfDay_Mark_In: "",
    HalfDay_Mark_Out: "",
    Absentism_In: "",
    Absentism_Out: "",
    Late_Amt: "",
    Added_By_Id: null,
    Added_By: "",
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsEditing(false);

    const updatedData = {
      ...data,

      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateLateHalfDay/${1}`, updatedData)
        .then((res) => {
          Swal.fire("Success!", "Your Record has been Updated.", "success");

          hideLoader();
        })
        .catch((err) => console.log(err));
    } else {
      setErrors(errors);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const result = await axios.get(`${apiUrl}/getLateHalfDay`);
        const data = result.data[0];
        const getData = {
          ...data,
          Late_Mark_In: data.Late_Mark_In,
          Late_Mark_Out: data.Late_Mark_Out,
          HalfDay_Mark_In: data.HalfDay_Mark_In,
          HalfDay_Mark_Out: data.HalfDay_Mark_Out,
          Absentism_In: data.Absentism_In,
          Absentism_Out: data.Absentism_Out,
          Late_Amt: data.Late_Amt,
          Added_By_Id: data.Added_By,
          Added_By: data.Added_By,
        };

        setData(getData);
        hideLoader();
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
        hideLoader();
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">Add Extending Time/Minutes</h4>
              <div className="card-block">
                <div className="form-body">
                  <div className="col-md-12">
                    <div
                      className="table-responsive"
                      style={{ overflowX: "-webkit-paged-x" }}
                    >
                      <div>
                        <form>
                          <table
                            className="mytable table  table-hover"
                            cellspacing="0"
                            cellpadding="1"
                            align="Center"
                            rules="cols"
                            id="ContentPlaceHolder1_gvStudents"
                            style={{
                              color: "black",
                              backgroundColor: "white",
                              borderColor: "#DEDFDE",
                              borderWidth: "1px",
                              borderStyle: "none",
                              borderCollapse: "collapse",
                            }}
                          >
                            <tbody>
                              <tr>
                                <th scope="col">#</th>
                                <th align="center" scope="col">
                                  LateMark In
                                </th>
                                <th align="center" scope="col">
                                  HalfDay In
                                </th>
                                <th align="center" scope="col">
                                  Absent In
                                </th>
                                <th align="center" scope="col">
                                  LateMark Out
                                </th>
                                <th align="center" scope="col">
                                  HalfDay Out
                                </th>
                                <th align="center" scope="col">
                                  Absent Out
                                </th>
                                <th align="center" scope="col">
                                  Late Count
                                </th>
                                <th scope="col">&nbsp;</th>
                              </tr>
                              <tr>
                                <td align="center">
                                  <span id="ContentPlaceHolder1_gvStudents_lblRowNumber_0">
                                    1
                                  </span>
                                </td>
                                <td align="center">
                                  <input
                                    name="ctl00$ContentPlaceHolder1$gvStudents$ctl02$txtLateMark"
                                    type="text"
                                    value={data.Late_Mark_In}
                                    required
                                    id="ContentPlaceHolder1_gvStudents_txtLateMark_0"
                                    className="form-control"
                                    disabled={!isEditing}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      setData({
                                        ...data,
                                        Late_Mark_In: numericInput,
                                      });
                                    }}
                                  />
                                  <span
                                    id="ContentPlaceHolder1_gvStudents_RequiredFieldValidator9_0"
                                    style={{ display: "none" }}
                                  ></span>
                                  <div
                                    id="ContentPlaceHolder1_gvStudents_ValidationSummary2_0"
                                    style={{ display: "none" }}
                                  ></div>
                                </td>
                                <td align="center">
                                  <input
                                    name="ctl00$ContentPlaceHolder1$gvStudents$ctl02$txtHalfDay"
                                    type="text"
                                    value={data.HalfDay_Mark_In}
                                    required
                                    id="ContentPlaceHolder1_gvStudents_txtHalfDay_0"
                                    className="form-control"
                                    disabled={!isEditing}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      setData({
                                        ...data,
                                        HalfDay_Mark_In: numericInput,
                                      });
                                    }}
                                  />
                                  <span
                                    id="ContentPlaceHolder1_gvStudents_RequiredFieldValidator_0"
                                    style={{ display: "none" }}
                                  ></span>
                                  <div
                                    id="ContentPlaceHolder1_gvStudents_ValidationSummary10_0"
                                    style={{ display: "none" }}
                                  ></div>
                                </td>
                                <td align="center">
                                  <input
                                    name="ctl00$ContentPlaceHolder1$gvStudents$ctl02$txtAbsentism"
                                    type="text"
                                    required
                                    id="ContentPlaceHolder1_gvStudents_txtAbsentism_0"
                                    className="form-control"
                                    disabled={!isEditing}
                                    value={data.Absentism_In}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      setData({
                                        ...data,
                                        Absentism_In: numericInput,
                                      });
                                    }}
                                  />
                                  <span
                                    id="ContentPlaceHolder1_gvStudents_RequiredFieldValidator1_0"
                                    style={{ display: "none" }}
                                  ></span>
                                  <div
                                    id="ContentPlaceHolder1_gvStudents_ValidationSummary4_0"
                                    style={{ display: "none" }}
                                  ></div>
                                </td>
                                <td align="center">
                                  <input
                                    name="ctl00$ContentPlaceHolder1$gvStudents$ctl02$txtLateMarkOut"
                                    type="text"
                                    value={data.Late_Mark_Out}
                                    required
                                    id="ContentPlaceHolder1_gvStudents_txtLateMarkOut_0"
                                    className="form-control"
                                    disabled={!isEditing}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      setData({
                                        ...data,
                                        Late_Mark_Out: numericInput,
                                      });
                                    }}
                                  />
                                  <span
                                    id="ContentPlaceHolder1_gvStudents_RequiredFieldValidatorsadsa9_0"
                                    style={{ display: "none" }}
                                  ></span>
                                  <div
                                    id="ContentPlaceHolder1_gvStudents_ValidationSummardsdsay2_0"
                                    style={{ display: "none" }}
                                  ></div>
                                </td>
                                <td align="center">
                                  <input
                                    name="txtHalfDayOut"
                                    type="text"
                                    value={data.HalfDay_Mark_Out}
                                    required
                                    id="txtHalfDayOut"
                                    className="form-control"
                                    disabled={!isEditing}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      setData({
                                        ...data,
                                        HalfDay_Mark_Out: numericInput,
                                      });
                                    }}
                                  />
                                  <span
                                    id="ContentPlaceHolder1_gvStudents_RequiredFieldValidatorsdcs_0"
                                    style={{ display: "none" }}
                                  ></span>
                                  <div
                                    id="ContentPlaceHolder1_gvStudents_ValidationSummarysadsad10_0"
                                    style={{ display: "none" }}
                                  ></div>
                                </td>
                                <td align="center">
                                  <input
                                    name="ctl00$ContentPlaceHolder1$gvStudents$ctl02$txtAbsentismOut"
                                    type="text"
                                    value={data.Absentism_Out}
                                    required
                                    id="ContentPlaceHolder1_gvStudents_txtAbsentismOut_0"
                                    className="form-control"
                                    disabled={!isEditing}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      setData({
                                        ...data,
                                        Absentism_Out: numericInput,
                                      });
                                    }}
                                  />
                                  <span
                                    id="ContentPlaceHolder1_gvStudents_RequiredFieldValidatordsadsad1_0"
                                    style={{ display: "none" }}
                                  ></span>
                                  <div
                                    id="ContentPlaceHolder1_gvStudents_ValidationSummarysdsdsdsds4_0"
                                    style={{ display: "none" }}
                                  ></div>
                                </td>
                                <td align="center">
                                  <input
                                    name="ctl00$ContentPlaceHolder1$gvStudents$ctl02$txtLateAmt"
                                    type="text"
                                    value={data.Late_Amt}
                                    required
                                    id="ContentPlaceHolder1_gvStudents_txtLateAmt_0"
                                    className="form-control"
                                    disabled={!isEditing}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      setData({
                                        ...data,
                                        Late_Amt: numericInput,
                                      });
                                    }}
                                  />
                                  <span
                                    id="ContentPlaceHolder1_gvStudents_RequiredFieldValidatordsadsadsdsfd1_0"
                                    style={{ display: "none" }}
                                  ></span>
                                  <div
                                    id="ContentPlaceHolder1_gvStudents_ValidationSummarysds4_0"
                                    style={{ display: "none" }}
                                  ></div>
                                </td>
                                <td align="center">
                                  {isEditing && (
                                    <Link
                                      type="submit"
                                      onClick={handleSubmit}
                                      cursor="pointer"
                                      style={{
                                        display: "inlineBlock",
                                        width: "50px",
                                      }}
                                    >
                                      Update
                                    </Link>
                                  )}
                                  &nbsp;
                                  {isEditing && (
                                    <Link
                                      onClick={handleCancelEdit}
                                      style={{
                                        display: "inlineBlock",
                                        width: "50px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                  )}
                                  &nbsp;
                                  {!isEditing && (
                                    <Link
                                      onClick={handleEditClick}
                                      style={{
                                        display: "inlineBlock",
                                        width: "50px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};

export default LateHalfDay;
