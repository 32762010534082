import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { Box, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import { apiUrl } from "./apiConfig";
import VisibilityOff from "@mui/icons-material/VisibilityOff";


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" >
        Masys
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function validateForm(values) {
  const errors = {};

  if (!values.Username) {
    errors.Username = "Enter Username";
  }

  if (!values.Password) {
    errors.Password = "Enter Password";
  }

  return errors;
}

const defaultTheme = createTheme();

export default function Login() {
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useState({
    Username: "",
    Password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = `${apiUrl}/`;
  const [errors, setErrors] = useState({
    Username: "",
    Password: "",
  });
  const [loginError, setLoginError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const formErrors = validateForm(values);
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      setLoading(true);
      axios
        .post(`${apiUrl}/login`, values)
        .then((res) => {
          if (res.data.Status === "Success") {
            navigate("/");
            setLoading(false);
          } else {
            setLoginError("Login Failed. Please check your Credentials.");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoginError("An error occurred during login.");
        });
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(/assets/images/digiColors.png)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: 321,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {loginError && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {loginError}
              </Typography>
            )}
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={(e) =>
                  setValues({ ...values, Username: e.target.value })
                }
                error={Boolean(errors.Username)}
                helperText={errors.Username}
                sx={{ width: "100%" }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={(e) =>
                  setValues({ ...values, Password: e.target.value })
                }
                error={Boolean(errors.Password)}
                helperText={errors.Password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "100%" }}
              />
              {!isLoading && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, width: "100%" }}
                >
                  Sign In
                </Button>
              )}
              {isLoading && (
                <Button
                  type="submit"
                  fullWidth
                  disabled
                  variant="contained"
                  sx={{ mt: 3, mb: 2, width: "100%" }}
                >
                  <CircularProgress size={21} color="inherit" />
                   {"  "} Sign In...
                </Button>
              )}

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
