import * as React from "react";
import * as XLSX from "xlsx";
import { apiUrl } from "../../componets/apiConfig";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Menu, MenuItem } from "@mui/material";
import ChangePasswordModal from "./MakePayment";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import useFullPageLoader from "../../mui/useFullPageLoader";

export default function ViewClient() {
  const [page, setPage] = useState(0);
  const [uniqueCategory, setUniqueCategory] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [selectedClientCat, setSelectedClientCat] = useState("-1");
  const [records, setRecords] = useState([]);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowSrNo, setSelectedRowSrNo] = useState(null);

  const handleMenuClick = (event, Sr_No) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowSrNo(Sr_No);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    Load();
  };

  useEffect(() => {
    (async () => {
      Load();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      handleFilterButton();
    })();
  }, [selectedClientCat]);

  const Load = async () => {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getClientByOrgCategory?ClientCat=${selectedClientCat}`
    );
    const res = await axios.get(`${apiUrl}/getClient`);
    const category = [...new Set(res.data.map((item) => item.Client_Cat))];
    const sortedData = result.data.sort((a, b) => b.Sr_No - a.Sr_No);
    setUniqueCategory(category);
    setRecords(sortedData);
    setData(sortedData);
    hideLoader();
    return result;
  };

  const handleFilterButton = async () => {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getClientByOrgCategory?ClientCat=${selectedClientCat}`
    );
    const sortedData = result.data.sort((a, b) => b.Sr_No - a.Sr_No);
    setRecords(sortedData);
    setData(sortedData);
    hideLoader();
  };

  const deleteUser = (Sr_No) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No);
      }
    });
  };

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleOpenChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
    handleMenuClose();
  };

  async function handleDelete(Sr_No) {
    await axios.delete(`${apiUrl}/deleteClient/${Sr_No}`);

    Swal.fire("Deleted!", "Your file has been deleted.", "success");
    Load();
  }

  const handleChangePage = (event, newPage) => {
    console.log("New Page:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("New Rows Per Page:", +event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Client Details", 20, 10);

    const columns = [
      "Client ID",
      "Org Category",
      " Company Name",
      "Owner Name",
      "Mobile",
      "Pin Code",
      "Address",
    ];

    const rows = records.map((row) => [
      row.Client_Id,
      row.Client_Cat,
      row.Company_Name,
      row.Owner_Name,
      row.Mobile_No,
      row.PinCode,
      row.Address,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Client-Table.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Client ID",
      "Org Category",
      " Company Name",
      "Owner Name",
      "Mobile",
      "Pin Code",
      "Address",
    ];
    const rows = [columns];
    records.forEach((row) => {
      rows.push([
        row.Client_Id,
        row.Client_Cat,
        row.Company_Name,
        row.Owner_Name,
        row.Mobile_No,
        row.PinCode,
        row.Address,
      ]);
    });
    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Client-Table");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Client-Table.xlsx");
  };

  const filterData = (selectedRole) => {
    setSelectedClientCat(selectedRole);
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();

    if (filterValue === "") {
      handleFilterButton();
      return;
    }
    const filteredData = data.filter((row) => {
      return (
        (row.PinCode && row.PinCode.toLowerCase().includes(filterValue)) ||
        (row.Address && row.Address.toLowerCase().includes(filterValue)) ||
        (row.Client_Id && row.Client_Id.toLowerCase().includes(filterValue)) ||
        (row.Client_Cat &&
          row.Client_Cat.toLowerCase().includes(filterValue)) ||
        (row.Company_Name &&
          row.Company_Name.toLowerCase().includes(filterValue)) ||
        (row.Owner_Name &&
          row.Owner_Name.toLowerCase().includes(filterValue)) ||
        (row.Mobile_No && row.Mobile_No.toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
    <div>
      <div className="row d-flex justify-content-end mb-2 ml-1">
        <div className="col-2 ">
          <label className="form-label">Filter By Org Category:</label>

          <select
            className="form-select"
            id="example-select"
            onChange={(e) => filterData(e.target.value)}
          >
            <option value={"-1"}>All</option>
            {uniqueCategory.map((role, index) => (
              <option key={index} value={role}>
                {role}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
        <TextField onChange={handleFilter} size="small" label="Search..." />
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        <Tooltip title="Export to PDF">
          <IconButton
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              DownloadPDF();
            }}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Export to Excel">
          <IconButton
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              DownloadExcel();
            }}
          >
            {" "}
            <Icon path={mdiFileExcel} size={1} />
          </IconButton>
        </Tooltip>
        <Link to={`/addClient`}>
          <Button variant="contained" endIcon={<AddCircleIcon />}>
            Add
          </Button>
        </Link>
      </Stack>
      <Box height={10} />
      <TableContainer
        className=""
        sx={{
          maxHeight: "100%",
          minHeight: "170px",
          zIndex: 0,
          position: "relative",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Client ID
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Org Category
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Company Name
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Owner Name
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Mobile
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Pin Code
              </TableCell>
              <TableCell align="left" style={{ minWidth: "100px" }}>
                Address
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records && records.length > 0 ? (
              records
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      style={{ height: "30px" }}
                    >
                      <TableCell align="left" style={{ padding: "5px" }}>
                        {row.Client_Id}
                      </TableCell>
                      <TableCell align="left" style={{ padding: "5px" }}>
                        {row.Client_Cat}
                      </TableCell>
                      <TableCell align="left" style={{ padding: "5px" }}>
                        <Link to={`/ClientsOrder/${row.Sr_No}`}>
                          <span>{row.Company_Name}</span>
                        </Link>
                      </TableCell>
                      <TableCell align="left" style={{ padding: "5px" }}>
                        {row.Owner_Name}
                      </TableCell>
                      <TableCell align="left" style={{ padding: "5px" }}>
                        {row.Mobile_No}
                      </TableCell>
                      <TableCell align="left" style={{ padding: "5px" }}>
                        {row.PinCode}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          maxWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          padding: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <LightTooltip title={row.Address}>
                          {row.Address}
                        </LightTooltip>
                      </TableCell>
                      <TableCell
                        className="text-cneter"
                        style={{ padding: "5px" }}
                      >
                        <IconButton
                          onClick={(event) => handleMenuClick(event, row.Sr_No)}
                          aria-controls="row-menu"
                          aria-haspopup="true"
                        >
                          <i
                            className="ri-more-2-fill"
                            style={{ fontSize: "16px" }}
                          ></i>
                        </IconButton>
                        <Menu
                          id="row-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={() => {
                              handleOpenChangePasswordModal(selectedRowSrNo);
                            }}
                          >
                            Summary
                          </MenuItem>
                          <ChangePasswordModal
                            open={isChangePasswordModalOpen}
                            onClose={handleCloseChangePasswordModal}
                            Sr_No={selectedRowSrNo}
                          />
                          <MenuItem>
                            <Link
                              to={`/updateClient/${selectedRowSrNo}`}
                              className="dropdown-item"
                            >
                            
                              <span> Update</span>
                            </Link>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleMenuClose();
                              deleteUser(selectedRowSrNo);
                            }}
                            style={{ color: "red" }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="mt-3"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={records.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {loader}
    </div>
  );
}
