import React from "react";
import { Link } from "react-router-dom";

const OrderTable = (props) => {
  const handleRemove = () => {
    props.onRemove(props.productId);
  };
  return (
    <tr>
      <td>{props.Product_Name}</td>
      <td>{props.Description ? props.Description : "--"}</td>
      <td>{props.Price}</td>
      <td>{props.Quantity}</td>
      <td>{props.Product_Total}</td>
      <td>
        <Link
          style={{
            cursor: "pointer",
          }}
          onClick={handleRemove}
        >
          <i
            className="ri-delete-bin-3-line"
            style={{
              color: "red",
              fontSize: "12px",
            }}
          ></i>
        </Link>
      </td>
    </tr>
  );
};
export default OrderTable;
