import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import Select from "react-select";
import Swal from "sweetalert2";

const Status = [
  { label: "Present", value: "Present" },
  { label: "Absent", value: "Absent" },
  { label: "Late", value: "Late" },
  { label: "Half Day", value: "Half Day" },
  { label: "Week off", value: "Week off" },
  { label: "Holiday", value: "Holiday" },
  { label: "Leave", value: "Leave" },
];

const Modal = ({ id, date, onClose, showModal }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [selectedStatus, setselectedStatus] = useState([]);

  const [StatusName, setStatusName] = useState("");

  const [srId, setId] = useState(null);

  const [dataDoc, setDataDoc] = useState({
    Sr_No: "",
    Faculty_Id: "",
    Date: "",
    Adjustment: "",
    Remark: "",
    Prew_Status: "",
    Added_By_Id: "",
    Added_By: "",
  });

  const [isDataAvailable, setIsDataAvailable] = useState(false);

  useEffect(() => {
    showLoader();
    axios
      .get(`${apiUrl}/getAdjustmentss?Faculty_Id=${id}&Date=${date}`)
      .then((res) => {
        if (res.data.length > 0) {
          setDataDoc(res.data[0]);
          setselectedStatus({
            label: res.data[0].Adjustment,
            value: res.data[0].Adjustment,
          });
          setIsDataAvailable(true);
        } else {
          setIsDataAvailable(false);
        }

        console.log(res.data);
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
      });
  }, []);

  const handleStatusChange = (selectedOption) => {
    if (selectedOption) {
      setStatusName(selectedOption.label);
    } else {
      setStatusName("");
    }
    setselectedStatus(selectedOption);
  };

  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    const [day, month, year] = date.split("/");
    const formattedDate = new Date(`${year}-${month}-${day}`);
    const updatedDataDoc = {
      ...dataDoc,
      Faculty_Id: id,
      Date: formattedDate,
      Adjustment: StatusName,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      const endpoint = isDataAvailable
        ? `${apiUrl}/updateAdjustment/${dataDoc.Sr_No}`
        : `${apiUrl}/addAdjustment`;

      const requestMethod = isDataAvailable ? axios.put : axios.post;

      requestMethod(endpoint, updatedDataDoc)
        .then((res) => {
          onClose();
          const message = isDataAvailable
            ? "Punch Status has been Updated."
            : "Punch Status has been Added.";

          Swal.fire("Success!", message, "success");
        })
        .catch((err) => console.log(err));
    } else {
      setErrors(errors);
    }
  };

  let show = showModal;

  function close() {
    onClose();
  }
  const modalClasses = `${show ? "show" : "hide"}`;
  const modalDisplay = show ? "block" : "none";
  // console.log(showModal, id, date);

  const deleteData = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        setId(dataDoc.Sr_No);
        handleDelete(dataDoc.Sr_No);
      }
    });
  };

  async function handleDelete(srId) {
    await axios.delete(`${apiUrl}/deleteAdjustment/${srId}`);
    Swal.fire("Deleted!", "Your file has been deleted.", "success");
    onClose();
  }

  return (
    <>
      <div
        class={`modal fade ${modalClasses}`}
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
        style={{ display: modalDisplay, backgroundColor: "#040404a1" }}
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{ boxShadow: "none" }}
        >
          <div
            class="modal-content"
            style={{ boxShadow: "0 2px 5px 0 rgba(27, 23, 30, 0.3)" }}
          >
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalToggleLabel">
                Adjust Punch Status
              </h5>
              <button type="button" class="btn-close" onClick={close}></button>
            </div>
            <div class="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="Status" className="form-label">
                    Status*
                  </label>
                  <Select
                    type="text"
                    name="Status"
                    required
                    placeholder="Select Punch Status"
                    id="Status"
                    value={selectedStatus}
                    options={Status}
                    onChange={handleStatusChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="FeedBack" className="form-label">
                    Remark*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="FeedBack"
                    required
                    value={dataDoc.Remark}
                    placeholder="Adjustments Remark"
                    id="FeedBack"
                    onChange={(e) =>
                      setDataDoc({
                        ...dataDoc,
                        Remark: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="row">
                  <div className="col-md-12 d-flex justify-content-center">
                    {!isDataAvailable ? (
                      <Button type="submit" variant="contained">
                        Submit
                      </Button>
                    ) : (
                      <>
                        <Button type="submit" variant="contained">
                          Update
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={deleteData} variant="contained">
                          Delete
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          {loader}
        </div>
      </div>
    </>
  );
};
export default Modal;
