import * as React from "react";
import * as XLSX from "xlsx";
import { apiUrl } from "../../componets/apiConfig";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../mui/useFullPageLoader";

export default function Collection() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const displayData = filteredRecords.length > 0 ? filteredRecords : records;

  useEffect(() => {
    (async () => {
      Load();
    })();
  }, []);
  const monthChanged = (event) => {
    const month = parseInt(event.target.value);
    setCurrentMonth(month);
  }
  const yearChanged = (event) => {
    const year = parseInt(event.target.value);
    setCurrentYear(year);
  }
  const Filter = async () => {
    (async () => {
      Load();
    })();
  };
  async function Load() {
    showLoader();
    const result = await axios.get(`${apiUrl}/getCollectiveReports?month=${currentMonth}&year=${currentYear}`);

    const sortedData = result.data.sort((a, b) => b.Sr_No - a.Sr_No);
    setData(sortedData);
    setRecords(sortedData);
    hideLoader();
    return result;
  }

  const deleteUser = (Sr_No) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No);
      }
    });
  };

  async function handleDelete(Sr_No) {
    await axios.delete(`${apiUrl}/deleteCollectiveReport/${Sr_No}`);

    Swal.fire("Deleted!", "Your file has been deleted.", "success");
    Load();
  }

  const handleChangePage = (event, newPage) => {
    console.log("New Page:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("New Rows Per Page:", +event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF('landscape');
    doc.text("CollectiveReport", 20, 10);

    const columns = [
      "Name", "Payment Type", "Week Off", "Total Days", "Total Working Day", "Holiday Count", "Weekoff Count",
      "Adjustment Count", "Present Count", "Absent Count", "Latemark Count", "Halfday Count", "Leave Count",
      "Monthly Salary", "Salary Perday", "Latemark Deduction", "Halfday Deduction", "Absen tDeduction",
      "Total Deduction", "Net Salary"
    ];

    const rows = displayData.map((row) => [
      row.Name, row.PayType, row.WeekOff, row.Total_Days, row.WorkingdayCount, row.HolidayCount, row.WeekoffCount, row.AdjustmentCount, row.PresentdayCount, row.AbsentdayCount,
      row.LatemarkdayCount, row.HalfdayCount, row.LeavedayCount, row.MonthlySalary, row.Perday_hourSalary, row.LatemarkDeduction, row.HalfdayDeduction,
      row.AbsentDeduction, row.TotalDeduction, row.NetSalary
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("CollectiveReport.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Name", "Payment Type", "Week Off", "Total Days", "Total Working Day", "Holiday Count", "Weekoff Count",
      "Adjustment Count", "Present Count", "Absent Count", "Latemark Count", "Halfday Count", "Leave Count",
      "Monthly Salary", "Salary Perday", "Latemark Deduction", "Halfday Deduction", "Absen tDeduction",
      "Total Deduction", "Net Salary"
    ];
    const rows = [columns];
    displayData.forEach((row) => {
      rows.push([
        row.Name, row.PayType, row.WeekOff, row.Total_Days, row.WorkingdayCount, row.HolidayCount, row.WeekoffCount, row.AdjustmentCount, row.PresentdayCount, row.AbsentdayCount,
        row.LatemarkdayCount, row.HalfdayCount, row.LeavedayCount, row.MonthlySalary, row.Perday_hourSalary, row.LatemarkDeduction, row.HalfdayDeduction,
        row.AbsentDeduction, row.TotalDeduction, row.NetSalary
      ]);
    });
    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "CollectiveReport");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "CollectiveReport.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (row.Name && row.Name.toLowerCase().includes(filterValue)) ||
        (row.Total_Days) || (row.WorkingdayCount) || (row.HolidayCount) ||
        (row.WeekoffCount) || (row.AdjustmentCount) || (row.PresentdayCount) ||
        (row.AbsentdayCount) || (row.LatemarkdayCount) || (row.HalfdayCount) ||
        (row.LeavedayCount) || (row.MonthlySalary) || (row.LatemarkDeduction) ||
        (row.HalfdayDeduction) || (row.AbsentDeduction) || (row.TotalDeduction) || (row.NetSalary))
    });
    setRecords(filteredData);
  }





  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Staff Collective Report</h4>
        </div>
      </div>
      <div className="col-12">
        <div className="card">
          {/* <h4 className="card-title" style={{ margin: '10px 0 0 10px' }}>Filters</h4> */}
          <div className="card-body" style={{ paddingTop: '5px' }}>
            <div className="row justify-content-center mt-2">
              <div className="col-4">
                <label htmlFor="example-select" className="form-label">Month</label>
                <select className="form-select" id="example-select" onChange={monthChanged} defaultValue={currentMonth}>
                  <option value={1}>January</option>
                  <option value={2}>February</option>
                  <option value={3}>March</option>
                  <option value={4}>April</option>
                  <option value={5}>May</option>
                  <option value={6}>June</option>
                  <option value={7}>July</option>
                  <option value={8}>August</option>
                  <option value={9}>September</option>
                  <option value={10}>October</option>
                  <option value={11}>November</option>
                  <option value={12}>December</option>
                </select>
              </div>
              <div className="col-4">
                <label htmlFor="example-select" className="form-label">Year</label>
                <select className="form-select" id="example-select" onChange={yearChanged} defaultValue={currentYear}>
                  <option value={2022}>2022</option>
                  <option value={2023}>2023</option>
                  <option value={2024}>2024</option>
                  <option value={2025}>2025</option>
                </select>
              </div>
              <div className="col-2">
                <button className="btn btn-primary" onClick={Filter} style={{ width: "100%",marginTop: "28px" }}>Filter</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <Box height={10} />
            <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
              <TextField onChange={handleFilter} size="small" label="Search..." />
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              ></Typography>
              <Tooltip title="Export to PDF">
                <IconButton
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    DownloadPDF();
                  }}
                >
                  <PictureAsPdfIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Export to Excel">
                <IconButton
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    DownloadExcel();
                  }}
                >
                  {" "}
                  <Icon path={mdiFileExcel} size={1} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box height={10} />
            <TableContainer
              className=""
              sx={{
                maxHeight: "100%",
                minHeight: "170px",
                zIndex: 0,
                position: "relative",
              }}
            >
              <Table stickyHeader aria-label="sticky table" >
                <TableHead style={{ borderTop: "1px solid #e0e0e0" }}>
                  <TableRow style={{ textAlign: "center" }}>
                    <TableCell style={{ minWidth: "10px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", borderLeft: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Sr. No
                    </TableCell>
                    <TableCell style={{ minWidth: "100px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Name
                    </TableCell>
                    <TableCell style={{ minWidth: "41px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Total Days
                    </TableCell>
                    <TableCell style={{ minWidth: "65px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Holidays
                    </TableCell>
                    <TableCell style={{ minWidth: "62px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Working Days
                    </TableCell>
                    <TableCell style={{ minWidth: "52px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Adjust -ments
                    </TableCell>
                    <TableCell style={{ minWidth: "37px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Presents
                    </TableCell>
                    <TableCell style={{ minWidth: "41px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Absents
                    </TableCell>
                    <TableCell style={{ minWidth: "37px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Late
                    </TableCell>
                    <TableCell style={{ minWidth: "41px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Half Days
                    </TableCell>
                    <TableCell style={{ minWidth: "37px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Leaves
                    </TableCell>
                    <TableCell style={{ minWidth: "59px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Late Deduct.
                    </TableCell>
                    <TableCell style={{ minWidth: "65px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Half Day Deduct.
                    </TableCell>
                    <TableCell style={{ minWidth: "59px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Absent Deduct.
                    </TableCell>
                    <TableCell style={{ minWidth: "71px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Salary
                    </TableCell>
                    <TableCell style={{ minWidth: "59px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Total Deduct.
                    </TableCell>
                    <TableCell style={{ minWidth: "51px", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", textAlign: "center", padding: "16px 5px", verticalAlign: "Top" }}>
                      Net Salary
                    </TableCell>
                    <TableCell style={{ textAlign: "center", borderRight: "1px solid #e0e0e0", borderTop: "1px solid #e0e0e0", padding: "16px 5px" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayData && displayData.length > 0 ? (
                    displayData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            hover
                            role="checkbox"
                            style={{ height: "30px", textAlign: "center" }}
                          >
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", borderLeft: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {index + 1}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.Name}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.Total_Days}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.HolidayCount}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.WorkingdayCount}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.AdjustmentCount}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.PresentdayCount}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.AbsentdayCount}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.LatemarkdayCount}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.HalfdayCount}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.LeavedayCount}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.LatemarkDeduction}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.HalfdayDeduction}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.AbsentDeduction}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.MonthlySalary}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.TotalDeduction}
                            </TableCell>
                            <TableCell style={{ borderRight: "1px solid #e0e0e0", padding: "5px", textAlign: "center" }}>
                              {row.NetSalary}
                            </TableCell>
                            <TableCell
                              className="text-cneter"
                              style={{ borderRight: "1px solid #e0e0e0", padding: "5px" }}
                            >
                              <IconButton
                                type="button"
                                className="btn shadow-none"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ padding: "0px", border: 0 }}
                              >
                                <i
                                  className="ri-more-2-fill"
                                  style={{ fontSize: "16px" }}
                                ></i>
                              </IconButton>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link
                                    onClick={() => deleteUser(row.Sr_No)}
                                    className="dropdown-item cursor-pointer"
                                  >
                                    <i
                                      className="ri-delete-bin-3-line"
                                      style={{
                                        color: "red",
                                        fontSize: "16px",
                                      }}
                                    ></i>
                                    <span> Delete</span>
                                  </Link>
                                </li>
                              </ul>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={18} align="center" style={{borderRight: "1px solid #e0e0e0",borderLeft: "1px solid #e0e0e0", color:"Red"}} >
                        No Records Found!!
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="mt-3"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={displayData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {loader}
          </div>
        </div>
      </div>
    </div>
  );
}
