import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "react-data-table-component";
import SummaryModal from "./Modal";
import { Menu, MenuItem } from "@mui/material";
import "../../CSS/datatable.css";
import "datatables.net";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Success, Confirmation } from "../Alerts/alert";
import * as XLSX from "xlsx";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Tooltip, Button } from "@mui/material";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import IconButton from "@mui/material/IconButton";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";

const ViewProforma = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setPorfomaData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState({});
  const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
  const [toDate, setToDate] = useState(dayjs().endOf("month"));
  const [uniquePStatus, setUniquePStatus] = useState([]);
  const [uniqueOStatus, setUniqueOStatus] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOstatus, setSelectedOstatus] = useState("-1");
  const [selectedPstatus, setSelectedPstatsu] = useState("-1");
  const [records, setRecords] = useState([]);
  const [selectedRowSrNo, setSelectedRowSrNo] = useState(null);
  const [pstatus, setPstatus] = useState(null);

  const handleMenuClick = (event, id , Pstatus) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowSrNo(id);
    setPstatus(Pstatus);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    Load();
  };

  useEffect(() => {
    (async () => {
      const result = await Load();
      setPorfomaData(result.data);
      setIsDataLoaded(true);
    })();
  }, []);

  useEffect(() => {
    if (isDataLoaded) {
      debugger;
      $("#basic-datatable").DataTable({
        columnDefs: [
          {
            targets: -1, // -1 refers to the last column
            orderable: false, // Disable sorting
          },
        ],
      }); // Initialize DataTables
    }
  }, [isDataLoaded]);

  async function Load() {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getOrdersbyStatus?Pstatus=${selectedPstatus}&Ostatus=${selectedOstatus}&fromDate=${fromDate.format(
        "YYYY-MM-DD"
      )}&ToDate=${toDate.format("YYYY-MM-DD")}`
    );
    const res = await axios.get(`${apiUrl}/getOrders`);
    const Pstatus = [...new Set(res.data.map((item) => item.Payment_Status))];
    const Ostatus = [...new Set(res.data.map((item) => item.Order_Status))];
    const sortedData = result.data.sort((a, b) => b.Sr_No - a.Sr_No);
    setUniquePStatus(Pstatus);
    setUniqueOStatus(Ostatus);
    setPorfomaData(sortedData);
    setRecords(sortedData);
    hideLoader();
    return result;
  }

  const handleDelete = async (Id, pstatus) => {
    handleMenuClose();
    const confirmed = await Confirmation("You Want to Delete this Record?");

    if (confirmed) {
      try {
        await axios.delete(`${apiUrl}/deleteOrder/${Id}/${pstatus}`);
        Success("Order record deleted successfully.");
        Load();
        setIsDataLoaded(true);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    }
  };

  const handleDateRangeFilter = async () => {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getOrdersbyStatus?Pstatus=${selectedPstatus}&Ostatus=${selectedOstatus}&fromDate=${fromDate.format(
        "YYYY-MM-DD"
      )}&ToDate=${toDate.format("YYYY-MM-DD")}`
    );
    const sortedData = result.data.sort((a, b) => b.Sr_No - a.Sr_No);
    setPorfomaData(sortedData);
    setRecords(sortedData);
    hideLoader();
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();

    const filteredData = records.filter((row) => {
      return (
        (row.Order_No && row.Order_No.toLowerCase().includes(filterValue)) ||
        (row.Client && row.Client.toLowerCase().includes(filterValue)) ||
        (formatDate(row.Order_Date) &&
          formatDate(row.Order_Date).toLowerCase().includes(filterValue)) ||
        (row.Payment_Status &&
          row.Payment_Status.toLowerCase().includes(filterValue)) ||
        (row.Order_Status &&
          row.Order_Status.toLowerCase().includes(filterValue))
      );
    });
    setPorfomaData(filteredData);
  }

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Order Invoice Records", 20, 10);

    const columns = [
      "Order No",
      " Order Date",
      " Payment Status",
      " Client",
      " Net Total",
      " Paid",
      " Balance",
      " Order Status",
    ];

    const rows = data.map((row) => [
      row.Order_No,
      formatDate(row.Order_Date),
      row.Payment_Status,
      row.Client,
      row.Net_Total,
      row.Paid,
      row.Balance,
      row.Order_Status,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Order-Invoice-Data.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Order No",
      " Order Date",
      " Payment Status",
      " Client",
      " Net Total",
      " Paid",
      " Balance",
      " Order Status",
    ];

    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        row.Order_No,
        formatDate(row.Order_Date),
        row.Payment_Status,
        row.Client,
        row.Net_Total,
        row.Paid,
        row.Balance,
        row.Order_Status,
      ]);
    });
    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Order-Invoice-Data");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Order-Invoice-Data.xlsx");
  };

  const handleOpenSummaryModal = (Sr_No) => {
    const newModalState = { ...isSummaryModalOpen };
    newModalState[Sr_No] = true;
    setIsSummaryModalOpen(newModalState);
  };

  const handleCloseSummaryModal = (Sr_No) => {
    const newModalState = { ...isSummaryModalOpen };
    newModalState[Sr_No] = false;
    setIsSummaryModalOpen(newModalState);
    handleMenuClose();
  };

  const columns = [
    {
      name: "Order No",
      selector: (row) => row.Order_No,
      width: "120px",
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row) => formatDate(row.Order_Date),
      width: "120px",
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) => row.Client,
      width: "200px",
      sortable: true,
    },

    {
      name: "P-Status",
      selector: (row) => row.Payment_Status,

      sortable: true,
      width: "100px",
    },
    {
      name: "Net Total",
      selector: (row) => row.Net_Total,

      sortable: true,
      width: "100px",
    },
    {
      name: "Paid",
      selector: (row) => row.Paid,
      sortable: true,
      width: "90px",
    },
    {
      name: "Bal",
      selector: (row) => row.Balance,
      sortable: true,
      width: "90px",
    },
    {
      name: "O-Status",
      selector: (row) => row.Order_Status,
      sortable: true,
      width: "100px",
    },
    {
      cell: (row) => (
        <div className="text-center">
          <IconButton
            onClick={(event) => handleMenuClick(event, row.Sr_No, row.Payment_Status)}
            aria-controls="row-menu"
            aria-haspopup="true"
            cursor="pointer"
            style={{ cursor: "pointer", color: "grey" }}
          >
            <i className="ri-more-2-fill" style={{ fontSize: "16px" }}></i>
          </IconButton>
          <Menu
            id="row-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <Link
                onClick={() => {
                  handleOpenSummaryModal(selectedRowSrNo);
                }}
                style={{ cursor: "pointer", color: "grey" }}
              >
                <i
                  className="ri-file-chart-fill"
                  style={{
                    color: "blue",
                    fontSize: "12px",
                  }}
                ></i>
                <span> Summary</span>
              </Link>
            </MenuItem>
            <SummaryModal
              open={isSummaryModalOpen[selectedRowSrNo] || false}
              onClose={() => {
                handleCloseSummaryModal(selectedRowSrNo);
              }}
              Sr_No={selectedRowSrNo}
            />

            <MenuItem>
              <Link
                to={`/invoice/${selectedRowSrNo}`}
                style={{ cursor: "pointer", color: "grey" }}
              >
                <i
                  className="ri-printer-fill"
                  style={{
                    color: "orange",
                    fontSize: "12px",
                  }}
                ></i>
                <span> Print Invoice</span>
              </Link>
            </MenuItem>

            {pstatus === "Unpaid" && (
              <MenuItem>
                <Link
                  to={`/updateOrder/${selectedRowSrNo}`}
                  style={{ cursor: "pointer", color: "grey" }}
                >
                  <i
                    className="ri-edit-2-fill"
                    style={{
                      color: "green",
                      fontSize: "12px",
                    }}
                  ></i>
                  <span> Update</span>
                </Link>
              </MenuItem>
            )}
            <MenuItem>
              <Link
                onClick={() => handleDelete(selectedRowSrNo, pstatus)}
                style={{ cursor: "pointer", color: "grey" }}
              >
                <i
                  className="ri-delete-bin-3-line"
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                ></i>
                <span> Delete</span>
              </Link>
            </MenuItem>
          </Menu>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const filterPData = (selectedMonth) => {
    setSelectedPstatsu(selectedMonth);
  };

  const filterOData = (selectedMonth) => {
    setSelectedOstatus(selectedMonth);
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box justify-content-between d-flex align-items-md-center flex-md-row flex-column">
              <h4 className="page-title">View Order Invoice</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <h4 className="card-title" style={{ margin: "10px 0 0 10px" }}>
                Filter
              </h4>
              <div className="card-body">
                <div className="row mt-2">
                  <div className="col-3">
                    {/* Your Filter By P-Status Autocomplete */}
                    <label className="form-label mb-2">
                      Filter By Payment Status:
                    </label>
                    <select
                      className="form-select"
                      id="example-select"
                      onChange={(e) => filterPData(e.target.value)}
                    >
                      <option value={"-1"}>All</option>
                      {uniquePStatus.map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3">
                    {/* Your Filter By O-Status Autocomplete */}
                    <label className="form-label mb-2">
                      Filter By Order Status:
                    </label>
                    <select
                      className="form-select"
                      id="example-select"
                      onChange={(e) => filterOData(e.target.value)}
                    >
                      <option value={"-1"}>All</option>
                      {uniqueOStatus.map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3">
                    {/* Your From Date DatePicker */}
                    <label className="form-label mb-2">From Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fromDate.format("YYYY-MM-DD")}
                      onChange={(date) => setFromDate(dayjs(date.target.value))}
                    />
                  </div>
                  <div className="col-3">
                    {/* Your To Date DatePicker */}
                    <label className="form-label mb-2">To Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      value={toDate.format("YYYY-MM-DD")}
                      onChange={(date) => setToDate(dayjs(date.target.value))}
                    />
                  </div>
                </div>
                <div className="row mt-3 justify-content-center">
                  <div className="col-2">
                    <Button variant="contained" onClick={handleDateRangeFilter}>
                      Filter
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-end mb-3">
                  <TextField
                    onChange={handleFilter}
                    size="small"
                    label="Search..."
                  />

                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  ></Typography>
                  <Tooltip title="Export to PDF">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </IconButton>
                  </Tooltip>
                  <Link to={`/order`}>
                    <Button variant="contained" endIcon={<AddCircleIcon />}>
                      Add
                    </Button>
                  </Link>
                </div>

                <div
                  className="table-responsive"
                  style={{ zIndex: 0, position: "relative" }}
                >
                  <DataTable
                    columns={columns}
                    data={data}
                    defaultSortField="Order_Date"
                    customStyles={customStyles}
                    fixedHeader
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
          {loader}
        </div>
      </div>
      {loader}
    </div>
  );
};

export default ViewProforma;
