import React from "react";
import OrderTableTd from "./OrderTable";
const AddorderProduct = (props) => {
  const handleRemove = (productId) => {
    props.onRemove(productId);
  };

  return (
    <tbody>
      {props.productList.map((productDetail, index) => (
        <OrderTableTd
          key={productDetail.id}
          Product_Name={productDetail.Product_Name}
          Description={productDetail.Description}
          Price={productDetail.Price}
          Quantity={productDetail.Quantity}
          Product_Total={productDetail.Product_Total}
          productId={productDetail.id}
          onRemove={() => handleRemove(index)}
        />
      ))}
    </tbody>
  );
};

export default AddorderProduct;
