import * as React from "react";
import * as XLSX from "xlsx";
import Table from "@mui/material/Table";
import { Link } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";
import useFullPageLoader from "../../mui/useFullPageLoader";

const Holiday = () => {
  const [page, setPage] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [Id, setId] = useState(null);
  const [Holiday_Date, setProductPrice] = useState("");
  const [Description, setDescription] = useState("");
  const [data, setData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [error, setError] = useState("");
  const { userData } = useUser();

  useEffect(() => {
    (async () => {
      const result = await Load();
      setData(result.data);
    })();
  }, []);

  async function Load() {
    showLoader();
    const result = await axios.get(`${apiUrl}/getHolidaysss`);
    const sortedData = result.data.sort((a, b) => b.Id - a.Id);
    setData(sortedData);
    setRecords(sortedData);
    hideLoader();
    return result;
  }

  async function save(event) {
    event.preventDefault();

    const error = {};

    if (Object.keys(error).length === 0) {
      try {
        showLoader();
        await axios.post(`${apiUrl}/addHolidays`, {
          Holiday_Date: Holiday_Date,
          Description: Description,
          Added_By_Id: userData.userId,
          Added_By: `${userData.firstName} ${userData.lastName}`,
        });
        Swal.fire("Success!", "Your Holiday has been Added.", "success");
        setError("");
        setDescription("");
        setProductPrice("");
        setId(null);
        Load();
        hideLoader();
      } catch (err) {
        Swal.fire({
          title: "Error",
          text: "Failed to add holiday. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        hideLoader();
      }
    } else {
      setError(error);
    }
  }

  async function editExpenses(data) {
    showLoader();
    setDescription(data.Description);
    setProductPrice(data.Holiday_Date);
    setId(data.Id);
    setEditingItem(data);
    hideLoader();
  }

  async function update(event) {
    event.preventDefault();

    try {
      showLoader();
      const updatedData = {
        Id: Id,
        Holiday_Date: Holiday_Date,
        Description: Description,
        Added_By_Id: userData.userId,
        Added_By: `${userData.firstName} ${userData.lastName}`,
      };

      await axios.put(`${apiUrl}/updateHolidays/` + Id, updatedData);
      setData((prevData) =>
        prevData.map((data) =>
          data.Id === Id ? { ...data, ...updatedData } : data
        )
      );
      setDescription("");
      setProductPrice("");
      setId(null);
      setEditingItem(null);
      Load();
      hideLoader();
      Swal.fire("Success!", "Holiday updated successfully.", "success");
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Failed to Update. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
      hideLoader();
    }
  }

  const deleteUser = (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id);
      }
    });
  };

  async function handleDelete(Id) {
    showLoader();
    await axios.delete(`${apiUrl}/deleteHolidays/${Id}`);
    Swal.fire("Deleted!", "Your record has been Deleted.", "success");
    Load();
    hideLoader();
  }

  const handleChangePage = (event, newPage) => {
    console.log("New Page:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("New Rows Per Page:", +event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Holidays", 20, 10);

    const columns = ["Holiday Date", "Description"];

    const rows = records.map((row) => [formatDate(row.Holiday_Date), row.Description]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Holiday.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Holiday Date", "Description"];
    const rows = [columns];
    records.forEach((row) => {
      rows.push([formatDate(row.Holiday_Date), row.Description]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Holiday");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Holiday.xlsx");
  };

  const [records, setRecords] = useState(data);

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (row.Holiday_Date &&
          row.Holiday_Date.toLowerCase().includes(filterValue)) ||
        (row.Description && row.Description.toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Add Holiday</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <Box height={10} />
            <Stack>
              <form
                className="form-horizontal"
                onSubmit={editingItem ? update : save}
              >
                <div className="row">
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="Holiday_Date">
                        Holiday Date*
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="Holiday_Date"
                        required
                        value={Holiday_Date ? Holiday_Date.slice(0, 10) : ''}
                        onChange={(event) => {
                          setProductPrice(event.target.value); 
                          setError("");
                        }}
                        id="Holiday_Date"
                      />
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="Description">
                        Description*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Description"
                        placeholder="Holiday Description"
                        value={Description}
                        onChange={(event) => {
                          setDescription(event.target.value);
                          setError("");
                        }}
                        id="Description"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <div>
                      {editingItem ? (
                        <Button
                          variant="contained"
                          endIcon={<AddCircleIcon />}
                          type="submit"
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          endIcon={<AddCircleIcon />}
                          type="submit"
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </Stack>
            <hr style={{ borderWidth: "2px" }} />
            <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
              <TextField
                onChange={handleFilter}
                size="small"
                label="Search..."
              />
              <div>
                <Tooltip title="Export to PDF">
                  <IconButton
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      DownloadPDF();
                    }}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Export to Excel">
                  <IconButton
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      DownloadExcel();
                    }}
                  >
                    {" "}
                    <Icon path={mdiFileExcel} size={1} />
                  </IconButton>
                </Tooltip>
              </div>
            </Stack>
            <Box height={14} />

            <TableContainer className="" sx={{ maxHeight: "100%", minHeight: "170px", zIndex:0, position:"relative" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Holiday Date
                    </TableCell>

                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Description
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          style={{ height: "30px" }}
                        >
                          <TableCell align="left" style={{ padding: "5px"}}>
                            {formatDate(row.Holiday_Date)}
                          </TableCell>

                          <TableCell align="left" style={{ padding: "5px"}}>
                            {row.Description ? row.Description : "--"}
                          </TableCell>

                          <TableCell className="text-cneter" style={{ padding: "5px"}}>
                            <IconButton
                              type="button"
                              className="btn shadow-none"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ padding: "0px", border: 0 }}
                            >
                              <i
                                className="ri-more-2-fill"
                                style={{ fontSize: "16px" }}
                              ></i>
                            </IconButton>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={() => editExpenses(row)}
                                >
                                  <i
                                    className="ri-edit-2-line"
                                    style={{
                                      color: "blue",
                                      fontSize: "16px",
                                    }}
                                  ></i>
                                  <span> Update</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => {
                                    deleteUser(row.Id);
                                  }}
                                  className="dropdown-item cursor-pointer"
                                >
                                  <i
                                    className="ri-delete-bin-3-line"
                                    style={{
                                      color: "red",
                                      fontSize: "16px",
                                    }}
                                  ></i>
                                  <span> Delete</span>
                                </Link>
                              </li>
                            </ul>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="mt-3"
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={records.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};

export default Holiday;
