import React, { useState, useEffect, useRef } from "react";
import AddOrderProduct from "./AddOrderProduct";
import axios from "axios";
import Select from "react-select";
import { useUser } from "../../componets/UserContext";
import Swal from "sweetalert2";
import { apiUrl } from "../../componets/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import useFullPageLoader from "../../mui/useFullPageLoader";
import dayjs from "dayjs";

const Status = [
  { label: "Paid", value: "Paid" },
  { label: "Partial Paid", value: "Partial Paid" },
  { label: "Unpaid", value: "Unpaid" },
];

const Payment_Mode = [
  { label: "Cash", value: "Cash" },
  { label: "Cheque", value: "Cheque" },
  { label: "Online", value: "Online" },
];

const Bank = [
  { label: "SBI BANK", value: "SBI BANK" },
  { label: "HDFC BANK", value: "HDFC BANK" },
  { label: "KOTAK BANK", value: "KOTAK BANK" },
  { label: "INDUS BANK", value: "INDUS BANK" },
  { label: "MAHARASHTRA BANK", value: "MAHARASHTRA BANK" },
  { label: "DNS BANK", value: "DNS BANK" },
  { label: "ICICI BANK", value: "ICICI BANK" },
];

const GST = [
  { label: "0%", value: "0" },
  { label: "5%", value: "5" },
  { label: "12%", value: "12" },
  { label: "18%", value: "18" },
  { label: "28%", value: "28" },
];

const UpdateOrder = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { Sr_No } = useParams();
  const { userData } = useUser();
  const [prodcts, setProdcts] = useState([]);
  const [pId, setpId] = useState(null);
  const [pName, setpName] = useState("");
  const [pNameOption, setpNameOption] = useState([]);
  const [selectedpName, setSelectedpName] = useState([]);
  const [pPreice, setpPreice] = useState(0);
  const [pDescription, setpDescription] = useState("");
  const [pQty, setpQty] = useState("");
  const [pTotal, setpTotal] = useState(0);
  const [grossTotal, setgrossTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [formError, setFormError] = useState({});
  const [pError, setproductError] = useState({});

  const buttonElement = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedStatusValue, setSelectedStatusValue] = useState("");
  const [selectedPayMode, setSelectedPayMode] = useState([]);
  const [oldNettotalAmount, setOldNetTotalAmount] = useState(0);
  const [nettotalAmount, setNetTotalAmount] = useState(null);
  const [paidValue, setPaidValue] = useState(0);
  const [balanceValue, setBalanceValue] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [selectedBank, setSelectedBank] = useState([]);

  const [mobileNo, setMobileNo] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [ClientNameOptions, setClientNameOptions] = useState([]);
  const [selectedClientName, setSelectedClientName] = useState([]);
  const [selectedGST, setSelectedGST] = useState([{ label: "0%", value: "0" }]);
  const [selectedGSTValue, setSelectedGSTValue] = useState(0);
  const [selectedCGST, setSelectedCGST] = useState(0);
  const [selectedSGST, setSelectedSGST] = useState(0);
  const [gstAmountValue, setGstAmountValue] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      if (pId) {
        try {
          showLoader();
          const response = await axios.get(`${apiUrl}/getProduct/${pId}`);
          const data = response.data;
          setpPreice(data.Product_Price);
        } catch (error) {
          console.error("Error loading expenses data:", error);
        } finally {
          hideLoader();
        }
      }
    };
    loadData();
  }, [pId]);

  useEffect(() => {
    const fetchData = async () => {
      if (Sr_No) {
        try {
          showLoader();
          const prod = await axios.get(`${apiUrl}/getOrderProduct/${Sr_No}`);
          const res = await axios.get(`${apiUrl}/getOrderBySrNo/${Sr_No}`);
          //console.log(res.data);
          setOrder(res.data);
          setProdcts(prod.data);
          setSelectedClientName({
            label: res.data.Client,
            value: res.data.Client_Id,
          });
          setMobileNo(res.data.Mobile_No);
          setBillingAddress(res.data.Billing_Address);
          setgrossTotal(res.data.Gross_Total);
          setGstAmountValue(res.data.GST_Amount);
          setSelectedGST({
            label: `${res.data.GST_Per}%`,
            value: res.data.GST_Per.toString(),
          });
          setDiscount(res.data.Discount);
          setSelectedStatus({
            label: res.data.Payment_Status,
            value: res.data.Payment_Status,
          });
          setNetTotalAmount(res.data.Net_Total);
          setOldNetTotalAmount(res.data.Net_Total);
          setPaidValue(res.data.Paid);
          setBalanceValue(res.data.Balance);
        } catch (error) {
          console.error("Error loading expenses data:", error);
        } finally {
          hideLoader();
        }
      }
    };
    if (Sr_No) {
      fetchData();
    }
  }, [Sr_No]);

  useEffect(() => {
    const loadData = async () => {
      try {
        showLoader();
        const response = await axios.get(
          `${apiUrl}/getClient/${selectedClientName.value}`
        );
        const data = response.data;
        setMobileNo(data.Mobile_No);
        setBillingAddress(data.Address);
      } catch (error) {
        console.error("Error loading expenses data:", error);
      } finally {
        hideLoader();
      }
    };

    if (selectedClientName.value) {
      loadData();
    }
  }, [selectedClientName.value]);

  useEffect(() => {
    (async () => {
      showLoader();
      const result1 = await axios.get(`${apiUrl}/getProducts`);
      const res1 = result1.data;
      const options1 = res1.map((res1) => ({
        label: res1.Product_Name,
        value: res1.Id,
      }));
      setpNameOption(options1);

      const result2 = await axios.get(`${apiUrl}/getClient`);
      const res2 = result2.data;
      const options2 = res2.map((res2) => ({
        label: res2.Company_Name,
        value: res2.Sr_No,
      }));
      setClientNameOptions(options2);

      hideLoader();
    })();
  }, []);

  const pnameChange = (selectedOption) => {
    if (selectedOption) {
      setpName(selectedOption.label);
      setpId(selectedOption.value);
    } else {
      setpId(null);
    }
    setSelectedpName(selectedOption);
    // setSelectedGST([null]);
    setDiscount(0);
    setGstAmountValue(0);
    setNetTotalAmount(0);
    setSelectedStatus([null]);
    setPaidValue(0);
    setBalanceValue(0);
    setSelectedBank([null]);
    setSelectedPayMode([null]);
    setOrder({
      ...Order,
      Cheque_No: "",
    });
    setOrder({ ...Order, Reference_No: "" });
    setproductError("");
  };

  const pPreiceChange = (e) => {
    const input = e.target.value;
    const numericInput = input.match(/^\d*\.?\d*/)[0];

    setpPreice(numericInput);
    updateTotal(numericInput, pQty);
  };

  const pQtyChange = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/[^0-9]/g, "");
    setpQty(numericInput);
    updateTotal(pPreice, numericInput);
  };

  const pDescriptionChange = (e) => {
    const input = e.target.value;
    setpDescription(input);
  };

  const updateTotal = (price, quantity) => {
    let total = price * quantity;
    setpTotal(total);
  };

  const [Order, setOrder] = useState({
    Order_No: "",
    Client_Id: "",
    Client: "",
    Mobile_No: "",
    Order_Date: "",
    Billing_Address: "",
    Total_Amount: "",
    GST_Per: "",
    CGST: "",
    SGST: "",
    Products: [],
    GST_Amount: "",
    Discount: "",
    Gross_Total: "",
    Net_Total: "",
    Payment_Status: "",
    Paid: "",
    Balance: "",
    Order_Status: "",
    Amount: "",
    Balance: "",
    Payment_Mode: "",
    Bank: "",
    Cheque_No: "",
    Cheque_Date: "",
    Reference_No: "",
    Payment_Date: "",
    Remarks: "",
    Added_By_Id: "",
    Added_By: "",
    Added_On: ""
  });
  //console.log(Order.Added_On);

  const handleGSTChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedGST(selectedOption);
      setSelectedGSTValue(selectedOption.value);
      const grossTotalValue = parseFloat(grossTotal);
      const gstRate = parseFloat(selectedOption.value);

      if (!isNaN(grossTotalValue) && !isNaN(gstRate)) {
        const gstAmount = (grossTotalValue * gstRate) / 100;
        setGstAmountValue(gstAmount);
        const halfOfGSTAmount = gstAmount / 2;
        setSelectedSGST(halfOfGSTAmount);
        setSelectedCGST(halfOfGSTAmount);
        TotalNetAmount(grossTotalValue, discount, gstAmount);
      } else {
        setGstAmountValue(0);
      }
    } else {
      setSelectedGST(null);
      setGstAmountValue(0);
      TotalNetAmount(grossTotal, discount, 0);
      setPaidValue(0);
      setSelectedPayMode([null]);
    }
  };

  const TotalNetAmount = (grossTotal, discount, gstAmount) => {
    const grossTotalValue = parseFloat(grossTotal);
    const discountValue = parseFloat(discount);
    const gstAmountValue = parseFloat(gstAmount);

    if (
      !isNaN(grossTotalValue) &&
      !isNaN(discountValue) &&
      !isNaN(gstAmountValue)
    ) {
      const netTotal = grossTotalValue + gstAmountValue - discountValue;
      setNetTotalAmount(netTotal);
    } else {
      setNetTotalAmount(0);
    }
  };

  const discountAmount = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/[^0-9]/g, "");
    setDiscount(numericInput);
    TotalNetAmount(grossTotal, numericInput, gstAmountValue);
    setPaidValue(0);
    setSelectedPayMode([null]);
  };

  const OrderSubmit = async(event) => {
    event.preventDefault();

    const updatedOrder = {
      ...Order,

      Client_Id: selectedClientName.value,
      Client: selectedClientName.label,
      Mobile_No: mobileNo,
      Billing_Address: billingAddress,
      CGST: selectedCGST,
      SGST: selectedSGST,
      GST_Per: selectedGSTValue? selectedGSTValue:selectedGST.value,
      GST_Amount: gstAmountValue,
      Discount: discount,
      Net_Total: nettotalAmount,
      OldNetTotal:oldNettotalAmount,
      Products: prodcts,
      Gross_Total: grossTotal,
      Payment_Status: selectedStatusValue? selectedStatusValue: selectedStatus.value,
      Paid: paidValue,
      Balance: balanceValue,
      Order_Status: getOrderStatus(selectedStatusValue),
      Payment_Mode: selectedPayMode.value,
      Bank: selectedBank.value,
      Remarks: "",
      Added_By_Id: Order.Added_By_Id,
      Added_By: Order.Added_By,
      Added_On: dayjs(Order.Added_On).format("YYYY-MM-DD"),
      Added_By_Role: Order.Added_By_Role,
    };

    function getOrderStatus(paymentStatus) {
      if (paymentStatus === "Unpaid") {
        return "Pending";
      } else if (paymentStatus === "Partial Paid") {
        return "Pending";
      } else if (paymentStatus === "Paid") {
        return "Pending";
      }
    }

    const errors = {};

    const mobileRegx = /^([6-9][0-9]{9,11})$/;

    if (!selectedClientName.label) {
      errors.Client = "Select client";
    }
    
    if (!Order.Order_Date) {
      errors.Order_Date = "Select Order Date";
    }

    if (!mobileNo) {
      errors.Mobile_No = "Enter Mobile Number";
    } else if (!mobileRegx.test(mobileNo)) {
      errors.Mobile_No = "Invalid Mobile Number!";
    }

    if (!billingAddress) {
      errors.Billing_Address = "Enter Billing Address";
    }
    if (selectedPayMode && selectedPayMode.value === "Online") {
      if (!Order.Reference_No) {
        errors.Reference_No = "Enter Billing Address";
      }
    }

    if (selectedPayMode && selectedPayMode.value === "Cheque") {
      if (!Order.Cheque_Date) {
        errors.Cheque_Date = "Select Cheque Date";
      }

      if (!Order.Cheque_No) {
        errors.Cheque_No = "Enter Cheque No";
      }

      if (!selectedBank.value) {
        errors.Bank = "Select Bank";
      }
    }

    if (
      selectedStatusValue === "Paid" ||
      selectedStatusValue === "Partial Paid"
    ) {
      if (!Order.Payment_Date) {
        errors.Payment_Date = "Select Payment Date";
      }

      if (!selectedPayMode.value) {
        errors.Payment_Mode = "Select Payment Mode";
      }
    }

    if (selectedStatusValue === "Partial Paid") {
      if (!paidValue) {
        errors.Paid = "Enter Paid Amount";
      }
    }

    // if (!selectedGSTValue) {
    //   errors.GST_Per = "Select GST %";
    // }

    if (discount === "") {
      errors.Discount = "Enter Discount Amount";
    }

    if (!selectedStatus.value) {
      errors.Payment_Status = "Select Payment Status";
    }

    if (Object.keys(errors).length === 0) {
      buttonElement.current.disabled = true;
      try {
        const response = await axios.put(`${apiUrl}/UpdateOrder/${Sr_No}`, updatedOrder);
        Swal.fire("Success!", "Order has been Updated.", "success");
        buttonElement.current.disabled = false;
        navigate("/viewOrder");
        setProdcts([]);
      } catch (err) {
        console.error(err);
        buttonElement.current.disabled = false;
        Swal.fire({
          title: "Error",
          text: "Failed to Update Order. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      setFormError(errors);
    }
  };

  const handleStatusChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedStatusValue(selectedOption.value);
      const paymentAmount = parseFloat(nettotalAmount);

      if (selectedOption.value === "Paid") {
        setPaidValue(paymentAmount);
        setBalanceValue(0);
      } else if (selectedOption.value === "Unpaid") {
        setPaidValue(0);
        setBalanceValue(paymentAmount);
      } else if (selectedOption.value === "Partial Paid") {
        setPaidValue("");
        setBalanceValue(0);
      }
    } else {
      setSelectedStatusValue("");
    }
    setSelectedStatus(selectedOption);
  };



  const handleInput = (event) => {
    const value = event.target.value.toUpperCase();
    setInputValue(value);
    setOrder({ ...Order, Reference_No: value });
  };


  const addProductClick = (event) => {
    event.preventDefault();
    const prodctsObj = {
      id: Math.random().toString(),
      Product_Id: pId,
      Product_Name: pName,
      Description: pDescription,
      Price: pPreice,
      Quantity: pQty,
      Product_Total: pTotal,
    };

    const producterror = {};
    const existingProduct = prodcts.find(
      (product) =>
        product.Product_Id === pId &&
        product.Description.trim().toLowerCase() ===
          pDescription.trim().toLowerCase()
    );

    if (!prodctsObj.Product_Name) {
      producterror.Product_Name = "Enter Product  Name";
    }
    if (!prodctsObj.Price) {
      producterror.Price = "Enter Price";
    }
    if (!prodctsObj.Quantity) {
      producterror.Quantity = "Enter Quantity ";
    }

    setproductError(producterror);
    if (Object.keys(producterror).length === 0) {
      if (existingProduct) {
        const updatedQty = parseInt(existingProduct.Quantity) + parseInt(pQty);
        const updatedTotal =
          parseFloat(existingProduct.Product_Total) + parseFloat(pTotal);
        const updatedPrice = updatedTotal / updatedQty;
        existingProduct.Quantity = updatedQty.toString();
        existingProduct.Product_Total = updatedTotal.toString();
        setpPreice(updatedPrice);
      } else {
        prodcts.push(prodctsObj);
      }
      const totalAmount = prodcts.reduce(
        (acc, product) => acc + parseFloat(product.Product_Total),
        0
      );
      setgrossTotal(totalAmount);
      setNetTotalAmount(totalAmount);
      setpName("");
      setpPreice(0);
      setpDescription("");
      setSelectedpName([]);
      setpQty("");
      setpTotal(0);
      setpId(null);
    }
  };

  const removeProductByIndex = (index) => {
    const updatedProducts = [...prodcts];
    updatedProducts.splice(index, 1);
  
   
    const totalAmount = updatedProducts.reduce(
      (acc, product) => acc + parseFloat(product.Product_Total),
      0
    );
    setProdcts(updatedProducts); 
    setgrossTotal(totalAmount);
    setNetTotalAmount(totalAmount); 
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-center flex-md-row flex-column">
            <h4 className="page-title">Create Order Invoice</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <form onSubmit={OrderSubmit}>
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label">Order Number*</label>
                  <input
                    type="text"
                    id="invoiceNumber"
                    name="invoiceNumber"
                    disabled
                    className="form-control"
                    value={Order.Order_No}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">Client Name*</label>
                  <Select
                    type="text"
                    //required
                    isDisabled
                    name="ClientNameOptions"
                    options={ClientNameOptions}
                    id="ClientNameOptions"
                    value={selectedClientName}
                    onChange={setSelectedClientName}
                  />

                  <p style={{ color: "red" }}>{formError.Client}</p>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Order Date*</label>
                  <input
                    type="date"
                    id="Order_Date"
                    name="Order_Date"
                    //required
                    value={dayjs(Order.Order_Date).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      setOrder({
                        ...Order,
                        Order_Date: e.target.value,
                      })
                    }
                    className={"form-control"}
                  />

                  <p style={{ color: "red" }}>{formError.Order_Date}</p>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Mobile Number*</label>
                  <input
                    type="text"
                    id="Mobile_No"
                    name="Mobile_No"
                    maxLength={10}
                    value={mobileNo}
                    //required
                    onChange={(e) => {
                      const input = e.target.value;
                      const numericInput = input.replace(/[^0-9]/g, "");
                      setMobileNo(numericInput);
                    }}
                    className="form-control"
                    placeholder="Mobile Number"
                  />

                  <p style={{ color: "red" }}>{formError.Mobile_No}</p>
                </div>
              </div>

              <div className="row mt-1 mb-3">
                <div className="col-md-12">
                  <label className="form-label">Billing Address*</label>
                  <textarea
                    type="text"
                    id="Billing_Address"
                    name="Billing_Address"
                    //required
                    value={billingAddress}
                    onChange={(e) => setBillingAddress(e.target.value)}
                    className="form-control"
                    placeholder="Enter Billing Address"
                  />

                  <p style={{ color: "red" }}>{formError.Billing_Address}</p>
                </div>
              </div>
              <hr style={{ borderWidth: "2px" }} />
              <div className="row mt-2">
                <h4>Product Detail</h4>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label className="form-label">Product Name*</label>
                  <Select
                    id="txtProductName"
                    name="txtProductName"
                    onChange={pnameChange}
                    value={selectedpName}
                    options={pNameOption}
                    placeholder="Product Name"
                  />
                  <p style={{ color: "red" }}>{pError.Product_Name}</p>
                </div>

                <div className="col-md-3">
                  <label className="form-label">Price*</label>
                  <input
                    type="text"
                    id="txtPrice"
                    name="txtPrice"
                    onChange={pPreiceChange}
                    value={pPreice}
                    className="form-control"
                    placeholder="Price"
                  />
                  <p style={{ color: "red" }}>{pError.Price}</p>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Quantity*</label>
                  <input
                    type="text"
                    id="txtQuantity"
                    name="txtQuantity"
                    onChange={pQtyChange}
                    value={pQty}
                    autoComplete="off"
                    className="form-control"
                    placeholder="Quantity"
                  />
                  <p style={{ color: "red" }}>{pError.Quantity}</p>
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <label className="form-label">Total</label>
                  <input
                    type="text"
                    id="txtTotal"
                    name="txtTotal"
                    disabled
                    className="form-control"
                    value={pTotal}
                    defaultValue={0}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <label className="form-label">Description</label>
                <textarea
                  type="text"
                  id="txtDescription"
                  name="txtDescription"
                  onChange={pDescriptionChange}
                  value={pDescription}
                  className="form-control"
                  placeholder="Description"
                />
              </div>
              <div className="row justify-content-center mt-3 mb-3">
                <div className="col-md-2 text-center">
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="btnAddProduct"
                    name="Add Product"
                    onClick={addProductClick}
                  >
                    Add Product
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {prodcts.length > 0 && (
                    <table
                      id="basic-datatable"
                      className="table table-striped dt-responsive nowrap w-100 basic-datatable"
                      style={{ display: "table" }}
                    >
                      <thead style={{ width: "100% !important" }}>
                        <tr>
                          <th>Product Name</th>
                          <th>Description</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <AddOrderProduct
                        productList={prodcts}
                        onRemove={removeProductByIndex}
                      />
                    </table>
                  )}
                </div>
              </div>
              <hr style={{ borderWidth: "2px" }} />
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label">Gross Total*</label>
                  <input
                    type="text"
                    id="txtGrossTotal"
                    disabled
                    //required
                    value={grossTotal}
                    className="form-control"
                    placeholder="Gross Total"
                    defaultValue={0}
                  />
                  <p style={{ color: "red" }}>{formError.Gross_Total}</p>
                </div>
                <div className="col-md-3">
                  <label className="form-label">GST%</label>
                  <Select
                    id="txtGrossTotal"
                    options={GST}
                    //required
                    value={selectedGST}
                    onChange={handleGSTChange}
                    placeholder="Select Gst Rate"
                  />
                  <p style={{ color: "red" }}>{formError.GST_Per}</p>
                </div>
                <div className="col-md-3">
                  <label className="form-label">GST Amount*</label>
                  <input
                    type="text"
                    // required
                    id="txtgstTotal"
                    value={gstAmountValue}
                    className="form-control"
                    defaultValue={0}
                    disabled
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">Discount*</label>
                  <input
                    type="text"
                    id="txtDiscount"
                    value={discount}
                    onChange={discountAmount}
                    className="form-control"
                    placeholder="Discount"
                    //defaultValue={0}
                    //required
                  />
                  <p style={{ color: "red" }}>{formError.Discount}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="Payment_Status" className="form-label">
                      Status*
                    </label>
                    <Select
                      type="text"
                      name="Payment_Status"
                      id="Payment_Status"
                      options={Status}
                      //required
                      value={selectedStatus}
                      onChange={handleStatusChange}
                    />
                    <p style={{ color: "red" }}>{formError.Payment_Status}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Net Total*</label>
                  <input
                    type="text"
                    id="txtNetTotal"
                    value={nettotalAmount}
                    //required
                    onChange={TotalNetAmount}
                    className="form-control"
                    defaultValue={0}
                    disabled
                  />
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="Paid" className="form-label">
                      Paid Amount*
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="Paid"
                      name="Paid"
                      value={paidValue}
                      placeholder="Enter Paid Amount"
                      //defaultValue={0}
                      onChange={(e) => {
                        const input = e.target.value;
                        const numericInput = input.match(/^\d*\.?\d*/)[0];
                        const updatedPaid = parseFloat(numericInput);
                        const updatedBalance = nettotalAmount - updatedPaid;
                        setPaidValue(numericInput);
                        setBalanceValue(updatedBalance);
                      }}
                      //readOnly={selectedStatusValue !== "Partial Paid"}
                      disabled={selectedStatusValue !== "Partial Paid"}
                      required={selectedStatusValue === "Partial Paid"}
                    />
                    <p style={{ color: "red" }}>{formError.Paid}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="Balance" className="form-label">
                      Balance Amount*
                    </label>
                    <input
                      type="text"
                      name="Balance"
                      className="form-control"
                      id="Balance"
                      readOnly
                      disabled
                      value={balanceValue}
                      defaultValue={0}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {(selectedStatusValue === "Paid" ||
                  selectedStatusValue === "Partial Paid") && (
                  <>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="Payment_Mode" className="form-label">
                          Payment Mode*
                        </label>
                        <Select
                          type="text"
                          name="Payment_Mode"
                          id="Payment_Mode"
                          options={Payment_Mode}
                          value={selectedPayMode}
                          onChange={setSelectedPayMode}
                          // required={
                          //   selectedStatusValue === "Paid" ||
                          //   selectedStatusValue === "Partial Paid"
                          // }
                        />
                        <p style={{ color: "red" }}>{formError.Payment_Mode}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="Payment_Date" className="form-label">
                          Payment Date*
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          id="Payment_Date"
                          name="Payment_Date"
                          // required={
                          //   selectedStatusValue === "Paid" ||
                          //   selectedStatusValue === "Partial Paid"
                          // }
                          onChange={(e) =>
                            setOrder({
                              ...Order,
                              Payment_Date: e.target.value,
                            })
                          }
                        />
                        <p style={{ color: "red" }}>{formError.Payment_Date}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                {selectedPayMode && selectedPayMode.value === "Cheque" && (
                  <>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Bank" className="form-label">
                          Bank*
                        </label>
                        <Select
                          type="text"
                          name="Bank"
                          id="Bank"
                          options={Bank}
                          value={selectedBank}
                          onChange={setSelectedBank}
                          //required={selectedPayMode.value === "Cheque"}
                        />
                        <p style={{ color: "red" }}>{formError.Bank}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Cheque_No" className="form-label">
                          Cheque No*
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="Cheque_No"
                          name="Cheque_No"
                          placeholder="Enter Cheque No"
                          value={Order.Cheque_No}
                          // required={selectedPayMode.value === "Cheque"}
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericInput = input.replace(/[^0-9]/g, "");
                            setOrder({
                              ...Order,
                              Cheque_No: numericInput,
                            });
                          }}
                        />
                        <p style={{ color: "red" }}>{formError.Cheque_No}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Cheque_Date" className="form-label">
                          Cheque Date*
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          id="Cheque_Date"
                          name="Cheque_Date"
                          //required={selectedPayMode.value === "Cheque"}
                          onChange={(e) =>
                            setOrder({
                              ...Order,
                              Cheque_Date: e.target.value,
                            })
                          }
                        />
                        <p style={{ color: "red" }}>{formError.Cheque_Date}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                {selectedPayMode && selectedPayMode.value === "Online" && (
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="Reference_No" className="form-label">
                        Transaction ID*
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Reference_No"
                        name="Reference_No"
                        //required={selectedPayMode.value === "Online"}
                        placeholder="Enter Transaction ID"
                        value={inputValue}
                        onInput={handleInput}
                      />
                      <p style={{ color: "red" }}>{formError.Reference_No}</p>
                    </div>
                  </div>
                )}
              </div>

              <div className="row justify-content-center mt-3">
                <div className="col-md-2 text-center">
                  {prodcts.length > 0 && (
                    <button
                      className="btn btn-primary"
                      ref={buttonElement}
                      type="submit"
                      style={{ display: "block" }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};
export default UpdateOrder;
