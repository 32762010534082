import { Button, Modal, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { apiUrl } from "../../componets/apiConfig";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";

const Status = [
  { label: "Inprocess", value: "Inprocess" },
  { label: "Completed", value: "Completed" },
];

const ChangePasswordModal = ({ open, onClose, id }) => {
  const { userData } = useUser();
  const [isLoading, setLoading] = useState(false);
  const [selectedStatus, setselectedStatus] = useState([]);
  const [StatusName, setStatusName] = useState("");

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [dataDoc, setDataDoc] = useState({
    Status: "",
    Selected_Remark: "",
    Selected_Remark_Id: "",
    FeedBack: "",
    Added_By_Id:"",
    Added_By_Name: "",
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const updatedDataDoc = {
      ...dataDoc,
      Status: StatusName,
      Selected_Remark: RemarksName,
      Selected_Remark_Id: RemarksId,
      Added_By_Id: userData.userId,
      Added_By_Name: `${userData.firstName} ${userData.lastName}`,
    };

    const errors = {};

    if (Object.keys(errors).length === 0) {
      axios
        .put(`${apiUrl}/updateVisit/${id}`, updatedDataDoc)
        .then((res) => {
          onClose();
          setLoading(false);
          Swal.fire("Success!", "Your Status has been Updated.", "success");
      
        })
        .catch((err) => console.log(err));
    } else {
      setErrors(errors);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const [selectedRemarks, setselectedRemarks] = useState([]);
  const [RemarksOption, setRemarksOption] = useState([]);
  const [RemarksName, setRemarksName] = useState("");
  const [RemarksId, setRemarksId] = useState(null);

  useEffect(() => {
    loadRemark();
  }, []);

  const loadRemark = async () => {
    try {
      showLoader();
      const response = await axios.get(`${apiUrl}/getRemarksss`);
      const visitDate = response.data;
      const visits = visitDate.map((visit) => ({
        label: visit.Remarks_Value,
        value: visit.Sr_No,
      }));
      setRemarksOption(visits);
    } catch (error) {
      console.error("Error loading visit data:", error);
    } finally {
      hideLoader();
    }
  };

  const handleRemarksChange = (selectedOption) => {
    if (selectedOption) {
      setRemarksName(selectedOption.label);
      setRemarksId(selectedOption.value);
    } else {
      setRemarksName("");
      setRemarksId(null);
    }
    setselectedRemarks(selectedOption);
  };

  const handleStatusChange = (selectedOption) => {
    if (selectedOption) {
      setStatusName(selectedOption.label);
    } else {
      setStatusName("");
    }
    setselectedStatus(selectedOption);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          component: "form",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <div class="modal-header">
          <h3 class="modal-title"> Update Status</h3>
        </div>
        <hr />
        <div className="modal-content">
          <div className="modal-body">
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="mb-3">
                    <label htmlFor="Selected_Remark" className="form-label">
                      Remark*
                    </label>
                    <Select
                      type="text"
                      name="Selected_Remark"
                      required
                      id="Selected_Remark"
                      value={selectedRemarks}
                      options={RemarksOption}
                      onChange={handleRemarksChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Status" className="form-label">
                      Status*
                    </label>
                    <Select
                      type="text"
                      name="Status"
                      required
                      id="Status"
                      value={selectedStatus}
                      options={Status}
                      onChange={handleStatusChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="FeedBack" className="form-label">
                      Feedback*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="FeedBack"
                      required
                      id="FeedBack"
                      onChange={(e) =>
                        setDataDoc({
                          ...dataDoc,
                          FeedBack: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-center">
                    {!isLoading && (
                      <Button type="submit" variant="contained">
                        Update Status
                      </Button>
                    )}
                    {isLoading && (
                      <Button type="submit" disabled variant="contained">
                        <CircularProgress size={21} color="inherit" />
                        {"  "} Update Status...
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>

          {loader}
        </div>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
