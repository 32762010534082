import React from "react";
import "flatpickr/dist/themes/material_green.css";
import Select from "react-select";
import { useUser } from "../../componets/UserContext";
import axios from "axios";
import { useNavigate} from "react-router-dom";
import { apiUrl } from '../../componets/apiConfig';
import { useState, useEffect } from "react";
import useButtonLoader from "../../mui/useButtonLoader";

const Status = [
  { label: "Paid", value: "Paid" },
  { label: "Partial Paid", value: "Partial Paid" },
  { label: "Unpaid", value: "Unpaid" },
];

const Payment_Mode = [
  { label: "Cash", value: "Cash" },
  { label: "Cheque", value: "Cheque" },
  { label: "Online", value: "Online" },
];

const Bank = [
  { label: "SBI", value: "SBI" },
  { label: "HDFC", value: "HDFC" },
  { label: "KOTAK", value: "KOTAK" },
  { label: "INDUS BANK", value: "INDUS BANK" },
  { label: "MAHARASHTRA BANK", value: "MAHARASHTRA BANK" },
  { label: "DNS BANK", value: "DNS BANK" },
  { label: "ICICI BANK", value: "ICICI BANK" },
];
const CreateBill = () => {
  const navigate = useNavigate();
  const [buttonElement, setLoading] = useButtonLoader(
    "Create Bill",
    "Create Bill..."
  );
  const { userData } = useUser();
  const [errors, setErrors] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedStatusValue, setSelectedStatusValue] = useState("");
  const [selectedPayMode, setSelectedPayMode] = useState([]);
  const [selectedPayModeValue, setSelectedPayModeValue] = useState("");
  const [selectedBank, setSelectedBank] = useState([]);
  const [selectedBankValue, setSelectedBankValue] = useState("");
  const [CategoryOptions, setCategoryOptions] = useState([]);
  const [SupplierOptions, setSupplierOptions] = useState([]);
  const [CategoryName, setCategoryName] = useState("");
  const [CategoryId, setCategoryId] = useState(null);
  const [SupplierName, setSupplierName] = useState("");
  const [SupplierId, setSupplierId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [paidValue, setPaidValue] = useState(0);
  const [balanceValue, setBalanceValue] = useState(0);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    (async () => {
      LoadCategory();
    })();
  }, []);

  async function LoadCategory() {
    const result = await axios.get(
      `${apiUrl}/getExpenseCategories`
    );
    const categoryData = result.data;

    // Assuming categoryData is an array of objects with Sr_No and Category_Name
    const options = categoryData.map((category) => ({
      label: category.Category_Name,
      value: category.Sr_No,
    }));

    setCategoryOptions(options);
  }

  useEffect(() => {
    const LoadSupplier = async () => {
      try{
      const result = await axios.get(`${apiUrl}/suppliers`);
      const supplierData = result.data;
      const options = supplierData.map((supplier) => ({
        label: supplier.Supplier_Name,
        value: supplier.Sr_No,
      }));
  
      setSupplierOptions(options);
    } catch (error) {
      console.error(error);
    }
  };
      LoadSupplier();
   
  }, []);


  const handleCategoryChange = (selectedOption) => {
    if (selectedOption) {
      setCategoryName(selectedOption.label);
      setCategoryId(selectedOption.value);
    } else {
      setCategoryName("");
      setCategoryId(null);
    }
    setSelectedCategory(selectedOption);
  };

  // Handle supplier selection change
  const handleSupplierChange = (selectedOption) => {
    if (selectedOption) {
      setSupplierName(selectedOption.label);
      setSupplierId(selectedOption.value);
    } else {
      setSupplierName("");
      setSupplierId(null);
    }
    setSelectedSupplier(selectedOption);
  };

  const handleStatusChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedStatusValue(selectedOption.value);
    } else {
      setSelectedStatusValue("");
    }
    setSelectedStatus(selectedOption);
    setTotalAmount("");
    setPaidValue(0);
    setBalanceValue(0);
  };

  const handlePaymentModeChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedPayModeValue(selectedOption.value);
    } else {
      setSelectedPayModeValue("");
    }
    setSelectedPayMode(selectedOption);
  };
  const handleBankChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedBankValue(selectedOption.value);
    } else {
      setSelectedBankValue("");
    }
    setSelectedBank(selectedOption);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date.target.value);
  };

  const handlePaymentAmountChange = (e) => {
    const input = e.target.value;
    const numericInput = input.match(/^\d*\.?\d*/)[0];
    const paymentAmount = parseFloat(numericInput);

    if (selectedStatusValue === "Paid") {
      setPaidValue(paymentAmount);
      setBalanceValue(0);
    } else if (selectedStatusValue === "Unpaid") {
      setPaidValue(0);
      setBalanceValue(paymentAmount);
    } else if (selectedStatusValue === "Partial Paid") {
      setPaidValue("");
      setBalanceValue(0);
    }
    setTotalAmount(numericInput);
  };

  const [billEntity, setBillEntity] = useState({
    Bill_No: "",
    Bill_Date: "",
    Category_Id: null,
    Category: "",
    Supplier_Id: null,
    Supplier: "",
    Title: "",
    Attatchment: "",
    Desciption: "",
    Remarks: "",
    Payment_Status: "",
    Amount: null,
    Paid: null,
    Balance: null,
    Payment_Date: "",
    Payment_Mode: "",
    Bank: "",
    Cheque_No: "",
    Cheque_Date: "",
    Reference_No: "",
    Added_By_Id: null,
    Added_By: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();


    const updatedBillEntity = {
      ...billEntity,
      Bill_Date: selectedDate,
      Category_Id: CategoryId,
      Category: CategoryName,
      Supplier_Id: SupplierId,
      Supplier: SupplierName,
      Payment_Status: selectedStatusValue,
      Payment_Mode: selectedPayModeValue,
      Bank: selectedBankValue,
      Paid: paidValue,
      Amount: totalAmount,
      Balance: balanceValue,
      Remarks: billEntity.Desciption,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    const formdata = new FormData();
    formdata.append("Bill_No", billEntity.Bill_No);
    formdata.append("Bill_Date", updatedBillEntity.Bill_Date);
    formdata.append("Category_Id", updatedBillEntity.Category_Id);
    formdata.append("Category", updatedBillEntity.Category);
    formdata.append("Supplier_Id", updatedBillEntity.Supplier_Id);
    formdata.append("Supplier", updatedBillEntity.Supplier);
    formdata.append("Title", billEntity.Title);
    formdata.append("Attatchment", billEntity.Attatchment);
    formdata.append("Desciption", billEntity.Desciption);
    formdata.append("Remarks", updatedBillEntity.Remarks);
    formdata.append("Payment_Status", updatedBillEntity.Payment_Status);
    formdata.append("Amount", updatedBillEntity.Amount);
    formdata.append("Paid", updatedBillEntity.Paid);
    formdata.append("Balance", updatedBillEntity.Balance);
    formdata.append("Payment_Date", billEntity.Payment_Date);
    formdata.append("Payment_Mode", updatedBillEntity.Payment_Mode);
    formdata.append("Bank", updatedBillEntity.Bank);
    formdata.append("Cheque_No", billEntity.Cheque_No);
    formdata.append("Cheque_Date", billEntity.Cheque_Date);
    formdata.append("Reference_No", billEntity.Reference_No);
    formdata.append("Added_By_Id", updatedBillEntity.Added_By_Id);
    formdata.append("Added_By", updatedBillEntity.Added_By);

    const error = {};

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      axios
        .post(`${apiUrl}/addExpense7`, formdata)
        .then((res) => {
          navigate("/viewBill");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      setErrors(error);
    }
  };

  const handleInput = (event) => {
    const value = event.target.value.toUpperCase();
    setInputValue(value);
    setBillEntity({ ...billEntity, Reference_No: value });
  };

  return (
    <div className="row">
      <div className="col-12 ">
        <div class="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 class="page-title">Bill Entry</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <form
              className="form-horizontal"
        
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Bill_No" className="form-label">
                        Bill/Reference No
                      </label>
                      <input
                        type="text"
                        name="Bill_No"
                        className="form-control"
                        id="Bill_No"
                        placeholder="Enter Bill/Reference No"
                        onChange={(e) =>
                          setBillEntity({
                            ...billEntity,
                            Bill_No: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Bill_Date" className="form-label">
                        Bill Date*
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="Bill_Date"
                        name="Bill_Date"
                        required
                        //value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Title" className="form-label">
                        Title*
                      </label>
                      <input
                        type="text"
                        name="Title"
                        className="form-control"
                        id="Title"
                        required
                        placeholder="Enter Title"
                        onChange={(e) =>
                          setBillEntity({
                            ...billEntity,
                            Title: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="First_Name" className="form-label">
                        Category*
                      </label>
                      <Select
                        type="text"
                        name="First_Name"
                        // className="form-control"
                        options={CategoryOptions}
                        id="First_Name"
                        required
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        {CategoryOptions.map((option) => (
                          <div
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled}
                          >
                            {option.label}
                          </div>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Last_Name" className="form-label">
                        Supplier*
                      </label>
                      <Select
                        type="text"
                        // className="form-control"
                        id="Last_Name"
                        name="Last_Name"
                        required
                        options={SupplierOptions}
                        value={selectedSupplier}
                        onChange={handleSupplierChange}
                      >
                        {SupplierOptions.map((option) => (
                          <div
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled}
                          >
                            {option.label}
                          </div>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Attatchment" className="form-label">
                        Attachment
                      </label>
                      <input
                        type="file"
                        name="Attatchment"
                        className="form-control"
                        id="Attatchment"
                        onChange={(e) =>
                          setBillEntity({
                            ...billEntity,
                            Attatchment: e.target.files[0],
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="Description" className="form-label">
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        id="Description"
                        name="Description"
                        placeholder="Description..."
                        onChange={(e) =>
                          setBillEntity({
                            ...billEntity,
                            Desciption: e.target.value,
                          })
                        }
                        multiline="true"
                        rows={2}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="Payment_Status" className="form-label">
                        Status*
                      </label>
                      <Select
                        type="text"
                        name="Payment_Status"
                        id="Payment_Status"
                        options={Status}
                        required
                        value={selectedStatus}
                        onChange={handleStatusChange}
                      >
                        {Status.map((option) => (
                          <div
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled}
                          >
                            {option.label}
                          </div>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="Amount" className="form-label">
                        Payment Amount*
                      </label>
                      <input
                        type="text"
                        name="Amount"
                        className="form-control"
                        id="Amount"
                        required
                        placeholder="Enter Payment Amount"
                        value={totalAmount}
                        onChange={handlePaymentAmountChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="Paid" className="form-label">
                        Paid Amount*
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Paid"
                        name="Paid"
                        value={paidValue}
                        placeholder="Enter Paid Amount"
                        defaultValue={0}
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.match(/^\d*\.?\d*/)[0];
                          const updatedPaid = parseFloat(numericInput);
                          const updatedBalance = totalAmount - updatedPaid;
                          setPaidValue(numericInput);
                          setBalanceValue(updatedBalance);
                        }}
                        //readOnly={selectedStatusValue !== "Partial Paid"}
                        disabled={selectedStatusValue !== "Partial Paid"}
                        required={selectedStatusValue === "Partial Paid"}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="Balance" className="form-label">
                        Balance Amount*
                      </label>
                      <input
                        type="number"
                        name="Balance"
                        className="form-control"
                        id="Balance"
                        readOnly
                        disabled
                        placeholder="Balance Amount"
                        value={balanceValue}
                        defaultValue={0}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  {(selectedStatusValue === "Paid" ||
                    selectedStatusValue === "Partial Paid") && (
                    <>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="Payment_Mode" className="form-label">
                            Payment Mode*
                          </label>
                          <Select
                            type="text"
                            name="Payment_Mode"
                            id="Payment_Mode"
                            options={Payment_Mode}
                            value={selectedPayMode}
                            onChange={handlePaymentModeChange}
                            required={
                              selectedStatusValue === "Paid" ||
                              selectedStatusValue === "Partial Paid"
                            }
                          >
                            {Payment_Mode.map((option) => (
                              <div
                                key={option.value}
                                value={option.value}
                                disabled={option.disabled}
                              >
                                {option.label}
                              </div>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="Payment_Date" className="form-label">
                            Payment Date*
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            id="Payment_Date"
                            name="Payment_Date"
                            required={
                              selectedStatusValue === "Paid" ||
                              selectedStatusValue === "Partial Paid"
                            }
                            onChange={(e) =>
                              setBillEntity({
                                ...billEntity,
                                Payment_Date: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="row">
                  {selectedPayModeValue === "Cheque" && (
                    <>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="Bank" className="form-label">
                            Bank*
                          </label>
                          <Select
                            type="text"
                            name="Bank"
                            id="Bank"
                            options={Bank}
                            value={selectedBank}
                            onChange={handleBankChange}
                            required={selectedPayModeValue === "Cheque"}
                          >
                            {Bank.map((option) => (
                              <div
                                key={option.value}
                                value={option.value}
                                disabled={option.disabled}
                              >
                                {option.label}
                              </div>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="Cheque_No" className="form-label">
                            Cheque No*
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="Cheque_No"
                            name="Cheque_No"
                            placeholder="Enter Cheque No"
                            value={billEntity.Cheque_No}
                            required={selectedPayModeValue === "Cheque"}
                            onChange={(e) => {
                              const input = e.target.value;
                              const numericInput = input.replace(/[^0-9]/g, "");
                              setBillEntity({
                                ...billEntity,
                                Cheque_No: numericInput,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="Cheque_Date" className="form-label">
                            Cheque Date*
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            id="Cheque_Date"
                            name="Cheque_Date"
                            required={selectedPayModeValue === "Cheque"}
                            onChange={(e) =>
                              setBillEntity({
                                ...billEntity,
                                Cheque_Date: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="row">
                  {selectedPayModeValue === "Online" && (
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="Reference_No" className="form-label">
                          Transaction ID*
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="Reference_No"
                          name="Reference_No"
                          required={selectedPayModeValue === "Online"}
                          placeholder="Enter Transaction ID"
                          value={inputValue}
                          onInput={handleInput}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-primary "  ref={buttonElement}>
                      Create Bill
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBill;
