import React, { useState, useEffect } from "react";
import axios from "axios";
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import {useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { apiUrl } from "../../componets/apiConfig";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import MySelect from "../../mui/MySelect";
import MultiSelect from "../../mui/MultiSelect";
import Swal from "sweetalert2";
import DownloadIcon from "@mui/icons-material/Download";
import { useUser } from "../../componets/UserContext";
import RadioButton from "../../mui/Radio.js";
import { TextareaAutosize, TextField, Button } from "@mui/material";
import useFullPageLoader from "../../mui/useFullPageLoader";

const options = [
  { label: "--Select--", value: null, disabled: true },
  { label: "Sunday", value: "Sunday" },
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
];

const Punches = [
  { label: "--Select--", value: null, disabled: true },
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

const YesNO = [
  { label: "--Select--", value: null, disabled: true },
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const LateHalfDay = [
  { label: "--Select--", value: null, disabled: true },
  { label: "Total Working Hours", value: "Total Working Hours" },
  { label: "Shift Timing", value: "Shift Timing" },
];

const PayType = [
  { label: "--Select--", value: null, disabled: true },
  { label: "Fixed", value: "Fixed" },
  { label: "Hourly", value: "Hourly" },
];

const roleOptions = [
  { label: "Back Office", value: "Back Office" },
  { label: "Sales Person", value: "Sales Person" },
];

const Settings = () => {
  const [isPayrollApplicable, setIsPayrollApplicable] = useState(true);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [selectedRole, setSelectedRole] = useState(["Back Office"]);
  const [SecondShiftDay, setSecondShiftValues] = useState([]);
  const [SinglePunches, setSinglePunchValues] = useState(false);
  const [selectedNames, setSelectedNames] = useState(["Sunday"]);
  const [selectedLateHalf, setLateHalf] = useState(["Total Working Hours"]);
  const [selectPayType, setSelectPayType] = useState(["Hourly"]);
  const [selectedOvertime, setOvertime] = useState(["No"]);
  const [selectedDeductions, setDeductions] = useState(["No"]);
  const [shift1InTime, setshift1InTime] = useState(null);
  const [shift1OutTime, setshift1OutTime] = useState(null);
  const [shift2InTime, setshift2InTime] = useState(null);
  const [shift2OutTime, setshift2OutTime] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { userData } = useUser();

  const [panDoc, setPanDoc] = useState("");
  const [adharDoc, setAdharDoc] = useState("");

  const handleInput = (event) => {
    const value = event.target.value.toUpperCase();
    setInputValue(value);

    setEmployee({ ...employee, Pan_No: value });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleshift1InTime = (newTime) => {
    setshift1InTime(newTime);
  };

  const handleshift1OutTime = (newTime) => {
    setshift1OutTime(newTime);
  };
  const handleshift2InTime = (newTime) => {
    setshift2InTime(newTime);
  };
  const handleshift2OutTime = (newTime) => {
    setshift2OutTime(newTime);
  };

  const handleRoleChange = (event) => {
    const newSelectedRole = event.target.value;
    setSelectedRole(newSelectedRole);
    if (newSelectedRole === "Back Office") {
      setIsPayrollApplicable(true);
    } else if (newSelectedRole === "Sales Person") {
      setIsPayrollApplicable(false);
    }
  };

  const { Id } = useParams();

  const [employee, setEmployee] = useState({
    UUID: "",
    Staff_Id: "",
    First_Name: "",
    Last_Name: "",
    Mobile_No: "",
    Pan_No: "",
    Pan_Doc: "",
    Aadhar_No: "",
    Aadhar_Doc: "",
    Address: "",
    Payroll: "",
    SecondShiftDay: "",
    WeekOff: "",
    SinglePunch: "",
    LateHalf: "",
    PayType: "",
    Role: "",
    Overtime_Consideration: "",
    Deductions: "",
    MF_InTime: null,
    MF_OutTime: null,
    Sat_InTime: null,
    Sat_OutTime: null,
    Username: "",
    Password: "",
    Status: "",
    Amount: "",
    Added_By: "",
    Added_By_Id: null,
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsEditing(false);

    const updatedEmployee = {
      ...employee,
      Payroll: isPayrollApplicable,
      SecondShiftDay: SecondShiftDay,
      WeekOff: selectedNames,
      SinglePunch: SinglePunches,
      LateHalf: selectedLateHalf,
      PayType: selectPayType,
      Overtime_Consideration: selectedOvertime,
      Deductions: selectedDeductions,
      MF_InTime: shift1InTime,
      MF_OutTime: shift1OutTime,
      Sat_InTime: shift2InTime,
      Sat_OutTime: shift2OutTime,
      Role: selectedRole,
      Username: employee.Mobile_No,
      Password: "staff@123",
      Status: 1,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    const formdata = new FormData();
    formdata.append("UUID", employee.UUID);
    formdata.append("Staff_Id", employee.Staff_Id);
    formdata.append("First_Name", employee.First_Name);
    formdata.append("Last_Name", employee.Last_Name);
    formdata.append("Mobile_No", employee.Mobile_No);
    formdata.append("Address", employee.Address);
    formdata.append("Amount", employee.Amount);
    formdata.append("Payroll", updatedEmployee.Payroll);
    formdata.append("Role", updatedEmployee.Role);
    formdata.append("Status", updatedEmployee.Status);
    formdata.append("Username", updatedEmployee.Username);
    formdata.append("Password", updatedEmployee.Password);
    formdata.append("SecondShiftDay", updatedEmployee.SecondShiftDay);
    formdata.append("WeekOff", updatedEmployee.WeekOff);
    formdata.append("SinglePunch", updatedEmployee.SinglePunch);
    formdata.append("LateHalf", updatedEmployee.LateHalf);
    formdata.append("PayType", updatedEmployee.PayType);
    formdata.append(
      "Overtime_Consideration",
      updatedEmployee.Overtime_Consideration
    );
    formdata.append("Deductions", updatedEmployee.Deductions);
    formdata.append("MF_InTime", updatedEmployee.MF_InTime);
    formdata.append("MF_OutTime", updatedEmployee.MF_OutTime);
    formdata.append("Sat_InTime", updatedEmployee.Sat_InTime);
    formdata.append("Sat_OutTime", updatedEmployee.Sat_OutTime);
    formdata.append("Pan_No", employee.Pan_No);
    formdata.append("Pan_Doc", employee.Pan_Doc? employee.Pan_Doc: panDoc);
    formdata.append("Aadhar_No", employee.Aadhar_No);
    formdata.append("Aadhar_Doc", employee.Aadhar_Doc? employee.Aadhar_Doc:adharDoc);
    formdata.append("Added_By_Id", updatedEmployee.Added_By_Id);
    formdata.append("Added_By", updatedEmployee.Added_By);

    const errors = {};
    if (!/^[a-zA-Z]+$/.test(employee.First_Name)) {
      errors.First_Name = "Invalid Input";
    }

    if (!/^[a-zA-Z]+$/.test(employee.Last_Name)) {
      errors.Last_Name = "Invalid Input";
    }

    if (!/^([6-9][0-9]{9,11})$/.test(employee.Mobile_No)) {
      errors.Mobile_No = "Invalid Mobile Number";
    }

    if (isPayrollApplicable) {
      if (!employee.Amount) {
        errors.Amount = "Enter Salary Amount";
      }
    }

    if (employee.Aadhar_No && employee.Aadhar_No.length !== 12) {
      errors.Aadhar_No = "Aadhar No must be 12 characters";
    }

    if (employee.Pan_No && employee.Pan_No.length !== 10) {
      errors.Pan_No = "Pan No must be 10 characters";
    }

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/updateStaff/${Id}`, formdata)
        .then((res) => {
          window.location.reload();
          Swal.fire(
            "Success!",
            "Your Client Record has been Updated.",
            "success"
          );

          Load();
          hideLoader();
        })
        .catch((err) => console.log(err));
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    (async () => {
      Load();
    })();
  }, []);

  async function Load() {
    const result = await axios.get(`${apiUrl}/getStaff/${Id}`);
    const employeeData = result.data;

  

    const formattedEmployee = {
      ...employeeData,
      UUID: employeeData.UUID,
      Staff_Id: employeeData.Staff_Id,
      First_Name: employeeData.First_Name,
      Last_Name: employeeData.Last_Name,
      Mobile_No: employeeData.Mobile_No,
      Pan_No: employeeData.Pan_No,
      Pan_Doc: employeeData.Pan_Doc,
      Aadhar_No: employeeData.Aadhar_No,
      Aadhar_Doc: employeeData.Aadhar_Doc,
      Address: employeeData.Address,
      Payroll: employeeData.Payroll,
      SecondShiftDay: employeeData.SecondShiftDay,
      WeekOff: employeeData.WeekOff,
      SinglePunch: employeeData.SinglePunch,
      LateHalf: employeeData.LateHalf,
      PayType: employeeData.PayType,
      Role: employeeData.Role,
      Overtime_Consideration: employeeData.Overtime_Consideration,
      Deductions: employeeData.Deductions,
      MF_InTime: employeeData.MF_InTime,
      MF_OutTime: employeeData.MF_OutTime,
      Sat_InTime: employeeData.Sat_InTime,
      Sat_OutTime: employeeData.Sat_OutTime,
      Username: employeeData.Username,
      Password: employeeData.Password,
      Status: employeeData.Status,
      Amount: employeeData.Amount,
      Added_By: employeeData.Added_By,
      Added_By_Id: employeeData.Added_By_Id,
    };

    const booleanValue = formattedEmployee.Payroll === "true";

    setAdharDoc(`${apiUrl}/images/` + formattedEmployee.Aadhar_Doc);
    setPanDoc(`${apiUrl}/images/` + formattedEmployee.Pan_Doc);
    setIsPayrollApplicable(booleanValue);
    setSelectedRole(formattedEmployee.Role);
    setSinglePunchValues(formattedEmployee.SinglePunch);
    setLateHalf(formattedEmployee.LateHalf);
    setSelectPayType(formattedEmployee.PayType);
    setOvertime(formattedEmployee.Overtime_Consideration);
    setDeductions(formattedEmployee.Deductions);
    setInputValue(formattedEmployee.Pan_No);
    setEmployee(formattedEmployee);

    const weekOff = formattedEmployee.WeekOff;
    const secondShiftDay = formattedEmployee.SecondShiftDay;

    const weekOffArray = weekOff.split(",").map((day) => day.trim());
    const secondShiftDayArray = secondShiftDay
      .split(",")
      .map((day) => day.trim());

    setSelectedNames(weekOffArray);
    setSecondShiftValues(secondShiftDayArray);

  
    const shift1In = dayjs(formattedEmployee.MF_InTime);
    const shift1Out = dayjs(formattedEmployee.MF_OutTime);
    setshift1InTime(shift1In);
    setshift1OutTime(shift1Out);
    //setshift2InTime(dayjs(formattedEmployee.Sat_InTime));
    // setshift2OutTime(dayjs(formattedEmployee.Sat_OutTime));
    if (formattedEmployee.Sat_InTime === "null") {
      const s1 =formattedEmployee.Sat_InTime = null;
      setshift2InTime(dayjs(s1));
    } else {
      setshift2InTime(dayjs(formattedEmployee.Sat_InTime));
    }
    if (formattedEmployee.Sat_OutTime === "null") {
      const s2= formattedEmployee.Sat_OutTime = null;
      setshift2InTime(s2);
    } else {
      setshift2OutTime(dayjs(formattedEmployee.Sat_OutTime));
    }

    return result;
  }

  useEffect(() => {}, [isPayrollApplicable]);

  return (
    <div>
      <br />
      <div className="tab-pane" id="settings" role="tabpanel">
        <form>
          <div id="ContentPlaceHolder1_pnl">
            <h5 className="mb-3 text-uppercase bg-light p-2">
              <i className="ri-building-line me-1"></i>Personal Details
            </h5>

            <div className="form">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="UUID" className="form-label">
                      UUID*
                    </label>
                    <TextField
                      type="text"
                      InputProps={{ style: { height: "38px" } }}
                      className="form-control"
                      id="UUID"
                      placeholder="Enter UUID"
                      value={employee.UUID}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="Staff_Id" className="form-label">
                      Staff ID*
                    </label>
                    <TextField
                      type="text"
                      className="form-control"
                      id="Staff_Id"
                      InputProps={{ style: { height: "38px" } }}
                      placeholder="Enter Staff ID"
                      autoComplete="off"
                      disabled
                      value={employee.Staff_Id}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="First_Name" className="form-label">
                      First Name*
                    </label>
                    <TextField
                      className="form-control"
                      InputProps={{ style: { height: "38px" } }}
                      type="text"
                      id="First_Name"
                      required
                      disabled={!isEditing}
                      //autoComplete="off"
                      placeholder="Enter First Name"
                      value={employee.First_Name}
                      onChange={(e) =>
                        setEmployee({
                          ...employee,
                          First_Name: e.target.value,
                        })
                      }
                      error={Boolean(errors.First_Name)}
                      helperText={errors.First_Name}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="Last_Name" className="form-label">
                      Last Name*
                    </label>
                    <TextField
                      type="text"
                      className="form-control"
                      id="Last_Name"
                      disabled={!isEditing}
                      placeholder="Enter Last Name"
                      value={employee.Last_Name}
                      required
                      InputProps={{ style: { height: "38px" } }}
                      onChange={(e) =>
                        setEmployee({
                          ...employee,
                          Last_Name: e.target.value,
                        })
                      }
                      error={Boolean(errors.Last_Name)}
                      helperText={errors.Last_Name}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="Mobile_No" className="form-label">
                      Mobile*
                    </label>
                    <TextField
                      className="form-control"
                      InputProps={{ style: { height: "38px" } }}
                      type="text"
                      disabled={!isEditing}
                      id="Mobile_No"
                      required
                      value={employee.Mobile_No}
                      placeholder="Enter Mobile Number"
                      inputProps={{ maxLength: 10 }}
                      onChange={(e) => {
                        const input = e.target.value;
                        const numericInput = input.replace(/[^0-9]/g, "");
                        setEmployee({
                          ...employee,
                          Mobile_No: numericInput,
                        });
                      }}
                      error={Boolean(errors.Mobile_No)}
                      helperText={errors.Mobile_No}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="Address" className="form-label">
                      Address*
                    </label>
                    <TextareaAutosize
                      className="form-control"
                      id="Address"
                      placeholder="Address"
                      value={employee.Address}
                      sx={{ height: "60px" }}
                      multiline="true"
                      rows={4}
                      disabled={!isEditing}
                      onChange={(e) =>
                        setEmployee({
                          ...employee,
                          Address: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Payroll Info */}
          <h5 className="mb-3 text-uppercase bg-light p-2">
            <i className="ri-building-line me-1"></i>Payroll Info
          </h5>
          <div className="row">
            <div className="col-6">
              <div className="mb-3">
                <h5 htmlFor="Role" className="form-label">
                  {" "}
                  Select Role:
                </h5>
                <RadioButton
                  id="Role"
                  disabled={!isEditing}
                  options={roleOptions}
                  selectedValue={selectedRole}
                  onChange={handleRoleChange}
                />
              </div>
            </div>

            {isPayrollApplicable && (
              <div className="row">
                <div className="col-3">
                  <div className="mb-3">
                    <label htmlFor="simpleinput" className="form-label">
                      Shift1 InTime
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        slotProps={{ textField: { size: "small" } }}
                        value={shift1InTime}
                        disabled={!isEditing}
                        onChange={handleshift1InTime}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="col-3">
                  <div className="mb-3">
                    <label htmlFor="simpleinput" className="form-label">
                      Shift1 OutTime
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        slotProps={{ textField: { size: "small" } }}
                        value={shift1OutTime}
                        disabled={!isEditing}
                        onChange={handleshift1OutTime}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="col-3">
                  <div className="mb-3">
                    <label htmlFor="simpleinput" className="form-label">
                      Shift2 InTime
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        slotProps={{ textField: { size: "small" } }}
                        value={shift2InTime}
                        disabled={!isEditing}
                        onChange={handleshift2InTime}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="col-3">
                  <div className="mb-3">
                    <label htmlFor="simpleinput" className="form-label">
                      Shift2 OutTime
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        slotProps={{ textField: { size: "small" } }}
                        value={shift2OutTime}
                        disabled={!isEditing}
                        onChange={handleshift2OutTime}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="SecondShiftDay" className="form-label">
                        Second Shift Day
                      </label>

                      <MultiSelect
                        id="SecondShiftDay"
                        placeholder="--Select--"
                        options={options}
                        disabled={!isEditing}
                        value={SecondShiftDay}
                        onChange={(e) => setSecondShiftValues(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="simpleinput" className="form-label">
                        WeekOff
                      </label>
                      <MultiSelect
                        options={options}
                        placeholder="--Select--"
                        value={selectedNames}
                        disabled={!isEditing}
                        onChange={(e) => setSelectedNames(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="SinglePunches" className="form-label">
                        Single Punches For The Day*
                      </label>

                      <MySelect
                        options={Punches}
                        value={SinglePunches}
                        disabled={!isEditing}
                        onChange={(e) => setSinglePunchValues(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="simpleinput" className="form-label ">
                        Late/HalfDay*
                      </label>

                      <MySelect
                        options={LateHalfDay}
                        value={selectedLateHalf}
                        disabled={!isEditing}
                        onChange={(e) => setLateHalf(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="selectPayType" className="form-label">
                        Pay Type*
                      </label>

                      <MySelect
                        options={PayType}
                        value={selectPayType}
                        disabled={!isEditing}
                        onChange={(e) => setSelectPayType(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="Amount">
                      {selectPayType==="Fixed" ? ("Salary*"):("Salary Per Hour*")}
                      </label>
                      <TextField
                        type="text"
                        name="Amount"
                        InputProps={{ style: { height: "38px" } }}
                        className="form-control"
                        id="Amount"
                        disabled={!isEditing}
                        placeholder="Enter Salary Amount"
                        value={employee.Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.match(/^\d*\.?\d*/)[0];
                          setEmployee({
                            ...employee,
                            Amount: numericInput,
                          });
                        }}
                        error={Boolean(errors.Amount)}
                        helperText={errors.Amount}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className="mb-3">
                        <label
                          htmlFor="selectedOvertime"
                          className="form-label"
                        >
                          Overtime Consideration
                        </label>

                        <MySelect
                          options={YesNO}
                          disabled={!isEditing}
                          value={selectedOvertime}
                          onChange={(e) => setOvertime(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                        <label
                          htmlFor="selectedDeductions"
                          className="form-label"
                        >
                          Deductions
                        </label>

                        <MySelect
                          name="selectedDeductions"
                          id="selectedDeductions"
                          options={YesNO}
                          disabled={!isEditing}
                          value={selectedDeductions}
                          onChange={(e) => setDeductions(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Documents */}
          <h5 className="mb-3 text-uppercase bg-light p-2">
            <i className="ri-global-line me-1"></i>Documents
          </h5>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label" htmlFor="Aadhar_No">
                  AdharCard No
                </label>
                <TextField
                  InputProps={{ style: { height: "38px" } }}
                  type="text"
                  name="Aadhar_No"
                  className="form-control"
                  id="Aadhar_No"
                  placeholder="Enter Aadhar No"
                  disabled={!isEditing}
                  value={employee.Aadhar_No}
                  inputProps={{ maxLength: 12 }}
                  onChange={(e) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9]/g, "");
                    setEmployee({
                      ...employee,
                      Aadhar_No: numericInput,
                    });
                  }}
                  error={Boolean(errors.Aadhar_No)}
                  helperText={errors.Aadhar_No}
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="mb-3">
                <label className="form-label" htmlFor="Aadhar_Doc">
                  Select AdharCard
                </label>
                <input
                  type="file"
                  name="Aadhar_Doc"
                  className="form-control"
                  id="Aadhar_Doc"
                  disabled={!isEditing}
                  onChange={(e) =>
                    setEmployee({
                      ...employee,
                      Aadhar_Doc: e.target.files[0],
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-1 mt-4">
              <div className="mb-3">
                {employee.Aadhar_Doc ? (
                  <a
                    href={`${apiUrl}/download/` + employee.Aadhar_Doc}
                    download
                    className="cursor-pointer"
                  >
                    <DownloadIcon />
                  </a>
                ) : (
                  <div><FileDownloadOffIcon/></div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-">
                <label className="form-label" htmlFor="Pan_No">
                  PanCard No
                </label>
                <TextField
                  InputProps={{ style: { height: "38px" } }}
                  type="text"
                  name="Pan_No"
                  disabled={!isEditing}
                  className="form-control"
                  id="Pan_No"
                  placeholder="Enter Pan No"
                  value={inputValue}
                  onInput={handleInput}
                  inputProps={{ maxLength: 10 }}
                  error={Boolean(errors.Pan_No)}
                  helperText={errors.Pan_No}
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="mb-3">
                <label className="form-label" htmlFor="Pan_Doc">
                  Select PanCard
                </label>
                <input
                  type="file"
                  name="Pan_Doc"
                  
                  disabled={!isEditing}
                  className="form-control"
                  id="Pan_Doc"
                  onChange={(e) =>
                    setEmployee({
                      ...employee,
                      Pan_Doc: e.target.files[0],
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-1 mt-4">
              <div className="mb-3">
                {employee.Pan_Doc ? (
                  <a
                    href={`${apiUrl}/download/` + employee.Pan_Doc}
                    download
                    className="cursor-pointer"
                  >
                    <DownloadIcon />
                  </a>
                ) : (
                  <div><FileDownloadOffIcon/></div>
                )}
              </div>
            </div>
          </div>

          <div className=" text-start">
            {/* Conditionally render Edit and Cancel buttons */}
            {!isEditing && (
              <Button
                variant="contained"
                className="btn btn-primary mt-2"
                onClick={handleEditClick}
              >
                Edit
              </Button>
            )}
            {isEditing && (
              <Button
                variant="contained"
                className="btn btn-primary mt-2"
                onClick={handleCancelEdit}
              >
                Close Edit
              </Button>
            )}
          </div>
          <div className="text-end">
            {isEditing && (
              <Button
                variant="contained"
                type="submit"
                className="btn btn-primary mt-2"
                onClick={handleSubmit}
                startIcon={<i className="ri-save-line"></i>}
              >
                Save Changes
              </Button>
            )}
          </div>
        </form>
      </div>
      {loader}
    </div>
  );
};

export default Settings;
