import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../componets/UserContext";
import Swal from "sweetalert2";
import { apiUrl } from "../../componets/apiConfig";
import useButtonLoader from "../../mui/useButtonLoader";
import useFullPageLoader from "../../mui/useFullPageLoader";

const EditClent = () => {
  const [buttonElement, setLoading] = useButtonLoader(
    " Update Client",
    " Update Client..."
  );
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { Sr_No } = useParams();
  const { userData } = useUser();

  const handleInput = (event) => {
    const value = event.target.value.toUpperCase();
    setClient({ ...client, GST_NO: value });
  };

  const [client, setClient] = useState({
    Client_Id: "",
    Client_Cat_Id: "",
    Client_Cat: "",
    Company_Name: "",
    Owner_Name: "",
    Mobile_No: "",
    Alt_Mobile_No: "",
    Email: "",
    GST_NO: "",
    PinCode: "",
    Address: "",
    Added_By_Id: "",
    Added_By: "",
  });

 

  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;
    const updatedClient = {
      ...client,

      Client_Cat_Id: CategoryId || client.Client_Cat_Id,
      Client_Cat: CategoryName || client.Client_Cat,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    // Perform validation here
    const validationErrors = {};

    if (!/^[a-zA-Z\s]+$/.test(client.Owner_Name)) {
      validationErrors.Owner_Name = "Invalid Input";
    }

    if (!/^([6-9][0-9]{9,11})$/.test(client.Mobile_No)) {
      validationErrors.Mobile_No = "Invalid Mobile Number";
    }

    if (
      client.Alt_Mobile_No &&
      !/^([6-9][0-9]{9,11})$/.test(client.Alt_Mobile_No)
    ) {
      validationErrors.Alt_Mobile_No = "Invalid Alternate Mobile Number ";
    }
    if (client.GST_NO && client.GST_NO.length !== 15) {
      validationErrors.GST_NO = "GST No must be 15 characters";
    }
    if (client.PinCode && !/^\d{6}$/.test(client.PinCode)) {
      validationErrors.PinCode = "Pin Code must be 6 digits";
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);

      axios
        .put(`${apiUrl}/updateClient/${Sr_No}`, updatedClient)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Your Client Record has been Updated.",
            "success"
          );
          navigate("/viewClient");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      setErrors(validationErrors);
    }
  };

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [CategoryName, setCategoryName] = useState("");
  const [CategoryId, setCategoryId] = useState(null);

  useEffect(() => {
    loadCategory();
  }, []);

  const loadCategory = async () => {
    try {
      const response = await axios.get(`${apiUrl}/getOrgCategories`);
      const category = response.data;
      const Category = category.map((cat) => ({
        label: cat.Category_Name,
        value: cat.Sr_No,
      }));
      setCategoryOption(Category);
    } catch (error) {
      console.error("Error loading expenses data:", error);
    }
  };

  useEffect(() => {
    if (Sr_No) {
      showLoader();
      axios.get(`${apiUrl}/getClient/${Sr_No}`).then((res) => {
        setClient(res.data);
        setSelectedCategory({
          label: res.data.Client_Cat,
          value: res.data.Client_Cat_Id,
        });
        hideLoader();
      });
    }
  }, [Sr_No]);

  const handleCategoryChange = (selectedOption) => {
    if (selectedOption) {
      setCategoryName(selectedOption.label);
      setCategoryId(selectedOption.value);
    } else {
      setCategoryName(client.Client_Cat );
      setCategoryId(client.Client_Cat_Id);
    }
    setSelectedCategory(selectedOption);
  };


  return (
    <div className="row">
      <div className="col-12 ">
        <div class="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 class="page-title"> Update Client</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="Client_Id" className="form-label">
                      Client ID*
                    </label>
                    <input
                      type="text"
                      name="Client_Id"
                      className="form-control"
                      id="Client_Id"
                      placeholder="Enter Client ID"
                      required
                      value={client.Client_Id}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="Client_Cat" className="form-label">
                      Org Category*
                    </label>
                    <Select
                      type="text"
                      name="Client_Cat"
                      required
                      id="Client_Cat"
                      value={selectedCategory}
                      options={categoryOption}
                      onChange={handleCategoryChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="Company_Name" className="form-label">
                      Company Name*
                    </label>
                    <input
                      type="text"
                      name="Company_Name"
                      className={`form-control ${
                        errors.Company_Name ? "is-invalid" : ""
                      }`}
                      id="Company_Name"
                      required
                      value={client.Company_Name}
                      placeholder="Enter Company Name"
                      onChange={(e) =>
                        setClient({ ...client, Company_Name: e.target.value })
                      }
                    />
                    {errors.Company_Name && (
                      <div className="invalid-feedback">
                        {errors.Company_Name}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Owner_Name" className="form-label">
                        Owner Name*
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.Owner_Name ? "is-invalid" : ""
                        }`}
                        id="Owner_Name"
                        name="Owner_Name"
                        value={client.Owner_Name}
                        placeholder="Enter Owner Name"
                        required
                        onChange={(e) =>
                          setClient({ ...client, Owner_Name: e.target.value })
                        }
                      />
                      {errors.Owner_Name && (
                        <div className="invalid-feedback">
                          {errors.Owner_Name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Mobile_No" className="form-label">
                        Mobile*
                      </label>
                      <input
                        type="text"
                        name="Mobile_No"
                        className={`form-control ${
                          errors.Mobile_No ? "is-invalid" : ""
                        }`}
                        id="Mobile_No"
                        required
                        value={client.Mobile_No}
                        maxLength="10"
                        onChange={(e) =>
                          setClient({ ...client, Mobile_No: e.target.value })
                        }
                        placeholder="Enter Mobile Number"
                      />
                      {errors.Mobile_No && (
                        <div className="invalid-feedback">
                          {errors.Mobile_No}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Alt_Mobile_No" className="form-label">
                        Alternate Mobile
                      </label>
                      <input
                        type="text"
                        name="Alt_Mobile_No"
                        maxLength="10"
                        className={`form-control ${
                          errors.Alt_Mobile_No ? "is-invalid" : ""
                        }`}
                        id="Alt_Mobile_No"
                        value={client.Alt_Mobile_No}
                        onChange={(e) =>
                          setClient({
                            ...client,
                            Alt_Mobile_No: e.target.value,
                          })
                        }
                        placeholder="Enter Alt Mobile No"
                      />
                      {errors.Alt_Mobile_No && (
                        <div className="invalid-feedback">
                          {errors.Alt_Mobile_No}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="inputEmail4" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        name="Email"
                        value={client.Email}
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Enter Email"
                        onChange={(e) =>
                          setClient({ ...client, Email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="GSTNo" className="form-label">
                        GST No
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.GST_NO ? "is-invalid" : ""
                        }`}
                        id="GSTNo"
                        name="GST_NO"
                        placeholder="Enter GST No"
                        autoComplete="off"
                        value={client.GST_NO}
                        maxLength="15"
                        onInput={handleInput}
                      />
                      {errors.GST_NO && (
                        <div className="invalid-feedback">{errors.GST_NO}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="PinCode" className="form-label">
                        Pin Code*
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.PinCode ? "is-invalid" : ""
                        }`}
                        name="PinCode"
                        id="PinCode"
                        required
                        value={client.PinCode}
                        placeholder="Enter Pin Code"
                        autoComplete="off"
                        maxLength="6"
                        onChange={(e) =>
                          setClient({ ...client, PinCode: e.target.value })
                        }
                      />
                      {errors.PinCode && (
                        <div className="invalid-feedback">{errors.PinCode}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="Address" className="form-label">
                        Address*
                      </label>
                      <textarea
                        className="form-control"
                        id="Address"
                        name="Address"
                        value={client.Address}
                        placeholder="Address"
                        required
                        onChange={(e) =>
                          setClient({ ...client, Address: e.target.value })
                        }
                        multiline="true"
                        rows={4}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary "
                      ref={buttonElement}
                    >
                      Update Client
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};

export default EditClent;
