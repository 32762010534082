import { Button, Modal, Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { apiUrl } from "../../componets/apiConfig";
import Swal from "sweetalert2";
import useFullPageLoader from "../../mui/useFullPageLoader";

import { useUser } from "../../componets/UserContext";

const SummaryModal = ({ open, onClose, Sr_No }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [logs, setLogs] = useState([]);
  const [invoice, setInvoice] = useState({});

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (Sr_No) {
      showLoader();
      axios.get(`${apiUrl}/getInvoiceById/${Sr_No}`).then((res) => {
        setInvoice(res.data);
        hideLoader();
      });
    }
  }, [Sr_No]);

  useEffect(() => {
    if (Sr_No) {
      showLoader();
      axios.get(`${apiUrl}/getProductsByPI/${Sr_No}`).then((res) => {
        setLogs(res.data);
        hideLoader();
      });
    }
  }, [Sr_No]);

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1111,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <div>
          <div>
            <h3 className="modal-title">Summary</h3>
          </div>
          <hr />
          <div className="modal-content">
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3">
                              <label className="control-label ">
                                Org Name :
                              </label>
                            </div>
                            <div className="col-md-9 mb-10">
                              <span>{invoice.Org_Name}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3">
                              <label className="control-label ">
                                Owner Name :
                              </label>
                            </div>
                            <div className="col-md-9 mb-10">
                              <span>{invoice.Owner_Name}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3">
                              <label className="control-label ">
                                Mobile No. :
                              </label>
                            </div>
                            <div className="col-md-9 mb-10">
                              <span>{invoice.Mobile_No}</span>
                            </div>
                          </div>
                        </div>
                        {invoice.Address && (
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-3">
                                <label className="control-label ">
                                  Address :
                                </label>
                              </div>
                              <div className="col-md-9 mb-10">
                                <span>{invoice.Address}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3">
                              <label className="control-label mb-10">
                                Invoice No. :
                              </label>
                            </div>
                            <div className="col-md-9">
                              <span>{invoice.Invoice_No}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3">
                              <label className="control-label mb-10">
                                Invoice Date :
                              </label>
                            </div>
                            <div className="col-md-9">
                              <span>{formatDate(invoice.Invoice_Date)}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3">
                              <label className="control-label mb-10">
                                Due Date :
                              </label>
                            </div>
                            <div className="col-md-9">
                              <span>{formatDate(invoice.Due_Date)}</span>
                            </div>
                          </div>
                        </div>
                        {invoice.GST_NO && (
                          <div className="col-md-12">
                            <div className="row ">
                              <div className="col-md-3">
                                <label className="control-label ">
                                  GST No. :
                                </label>
                              </div>
                              <div className="col-md-9">
                                <span>{invoice.GST_NO}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer mt-4">
              <div className="container-fluid">
                <div className="col-md-12 mt-4">
                  <div>
                    <table
                      className="tables productTable display dataTable w-100 table-bordered "
                      cellspacing="0"
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid rgba(33, 33, 33, 0.1)",
                      }}
                    >
                      <thead>
                        <tr>
                          <th className="text-center" scope="col">
                            Product
                          </th>
                          <th className="text-center" scope="col">
                            Quantity
                          </th>
                          <th className="text-center" scope="col">
                            Price
                          </th>

                          <th className="text-center" scope="col">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {logs ? (
                          logs.map((row, index) => (
                            <tr key={index}>
                              <td align="center">
                                <span className="f-12">{row.Product_Name}</span>
                              </td>
                              <td align="center">
                                <span>{row.Quantity}</span>
                              </td>
                              <td align="center">
                                <span>{row.Price}</span>
                              </td>
                              <td align="center">
                                <span>{row.Total}</span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loader}
        </div>
      </Box>
    </Modal>
  );
};

export default SummaryModal;
