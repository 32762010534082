import { Button, Modal, Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { apiUrl } from "../../componets/apiConfig";
import Swal from "sweetalert2";
import { useUser } from "../../componets/UserContext";
import { Link } from "react-router-dom";
import useFullPageLoader from "../../mui/useFullPageLoader";

const Payment_Mode = [
  { label: "Cash", value: "Cash" },
  { label: "Cheque", value: "Cheque" },
  { label: "Online", value: "Online" },
];

const Order_Status = [
  { label: "Pending", value: "Pending" },
  { label: "InProcess", value: "InProcess" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Completed", value: "Completed" },
];

const Bank = [
  { label: "SBI BANK", value: "SBI BANK" },
  { label: "HDFC BANK", value: "HDFC BANK" },
  { label: "KOTAK BANK", value: "KOTAK BANK" },
  { label: "INDUS BANK", value: "INDUS BANK" },
  { label: "MAHARASHTRA BANK", value: "MAHARASHTRA BANK" },
];

const ChangePasswordModal = ({ open, onClose, Sr_No }) => {
  const { userData } = useUser();
  const [data, setData] = useState([]);
  const [logs, setLogs] = useState([]);
  const [selectedPayMode, setSelectedPayMode] = useState([]);
  const [selectedPayModeValue, setSelectedPayModeValue] = useState("");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState([]);
  const [selectedOrderStatusValue, setSelectedOrderStatusValue] = useState("");
  const [selectedBank, setSelectedBank] = useState([]);
  const [selectedBankValue, setSelectedBankValue] = useState("");
  const [balance, setBalance] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [Paid, setPaid] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const isPaymentAmountZero = totalAmount === 0;
  const [productItem, setProductItem] = useState([]);

  const [dataDoc, setDataDoc] = useState({
    Balance: "",
    Paid: "",
    Payment_Status: "",
    Payment_Mode: "",
    Order_Status: "",
    Bank: "",
    Amount: "",
    Cheque_No: "",
    Cheque_Date: "",
    Reference_No: "",
    Payment_Date: "",
    Remarks: "",
    Added_By_Id: "",
    Added_By: "",
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedDataDoc = {
      ...dataDoc,
      Paid: Paid,
      Payment_Status: paymentStatus,
      Balance: balance,
      Amount: totalAmount,
      Payment_Mode: selectedPayModeValue,
      Order_Status: selectedOrderStatusValue || data.Order_Status,
      Bank: selectedBankValue,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    const errors = {};

    if (isPaymentAmountZero) {
      if (!updatedDataDoc.Order_Status) {
        errors.Order_Status = "Select Order Status";
      }
    } else {
      if (!updatedDataDoc.Amount) {
        errors.Amount = "Enter Payment Amount";
      }
      if (!updatedDataDoc.Payment_Mode) {
        errors.Payment_Mode = "Select Payment Mode";
      }
      //   if (!updatedDataDoc.Order_Status) {
      //     errors.Order_Status = "Select Order Status";
      //   }
      if (!updatedDataDoc.Payment_Date) {
        errors.Payment_Date = "Select Payment Date";
      }
      if (selectedPayModeValue === "Cheque") {
        if (!updatedDataDoc.Bank) {
          errors.Bank = "Select Bank";
        }
        if (!updatedDataDoc.Cheque_No) {
          errors.Cheque_No = "Enter Cheque No";
        }
        if (!updatedDataDoc.Cheque_Date) {
          errors.Cheque_Date = "Select Cheque Date";
        }
      }
      if (selectedPayModeValue === "Online") {
        if (!updatedDataDoc.Reference_No) {
          errors.Reference_No = "Enter Transaction ID";
        }
      }
    }

    if (Object.keys(errors).length === 0) {
      showLoader();
      axios
        .put(`${apiUrl}/makePayment/${Sr_No}`, updatedDataDoc)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Payment history  has been Updated.",
            "success"
          );
          handleClose();
          hideLoader();
        })
        .catch((err) => console.log(err));
      hideLoader();
    } else {
      setErrors(errors);
    }
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (Sr_No) {


      fetchData();
    }
  }, [Sr_No]);

  const fetchData = async () => {
    showLoader();
    try {
      const response = await axios.get(
        `${apiUrl}/getClientPayment/${Sr_No}`
      );
      const clientResponse = await axios.get(
        `${apiUrl}/getClient/${Sr_No}`
      );

      setData(clientResponse.data);
      setDataDoc(clientResponse.data);
      setLogs(response.data);

      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      hideLoader();
    }
  };

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }

  const handlePaymentModeChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedPayModeValue(selectedOption.value);
    } else {
      setSelectedPayModeValue("");
    }
    setSelectedPayMode(selectedOption);
  };

  const handleOrderStatusChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedOrderStatusValue(selectedOption.value);
    } else {
      setSelectedOrderStatusValue(data.Order_Status);
    }
    setSelectedOrderStatus(selectedOption);
  };

  const handleBankChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedBankValue(selectedOption.value);
    } else {
      setSelectedBankValue("");
    }
    setSelectedBank(selectedOption);
  };

  const handlePaymentChange = (event) => {
    const input = event.target.value;
    const numericInput = input.match(/^\d*\.?\d*/)[0];
    const paymentValue = numericInput;
    if (paymentValue <= data.Balance) {
      const calculatedBalance = data.Balance - parseFloat(paymentValue);
      const PaidAmount = data.Paid + parseFloat(paymentValue);
      setBalance(calculatedBalance);
      setPaid(PaidAmount);
      setTotalAmount(paymentValue);
      let updatedPaymentStatus;

      if (calculatedBalance === 0) {
        updatedPaymentStatus = "Paid";
      } else if (calculatedBalance !== data.Amount) {
        updatedPaymentStatus = "Partial Paid";
      } else {
        updatedPaymentStatus = data.Payment_Status;
      }

      setPaymentStatus(updatedPaymentStatus);
      setErrors({});
    } else {
      setErrors({ Amount: "Payment amount exceeds balance" });
    }
  };

  const handleInput = (event) => {
    const value = event.target.value.toUpperCase();
    setInputValue(value);
    setDataDoc({ ...dataDoc, Reference_No: value });
  };

  // console.log(dataDoc.Order_Status);


  const deleteUser = (Sr_No, Client_Id, Order_Id) => {
    const userConfirmed = window.confirm("Are you sure? You won't be able to revert this!");
  
    if (userConfirmed) {
      handleDelete(Sr_No, Client_Id, Order_Id);
    }
  };
  
  async function handleDelete(Sr_No, Client_Id, Order_Id) {
    try {
      await axios.delete(`${apiUrl}/deleteOrderPayment/${Sr_No}/${Client_Id}/${Order_Id}`);
      alert("Deleted! Record has been deleted.");
      fetchData();
    } catch (error) {
      console.error("Error deleting record:", error);
      alert("Error deleting record. Please try again.");
    }
  }
  
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1111,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          maxHeight: "95vh",
          overflowY: "auto",
          paddingTop: "10px",
        }}
      >
        <h3>Make Payment</h3>

        <hr />
        <div className="modal-content">
          <div className="modal-footer mt-4">
            <div className="container-fluid">
              {/* {dataDoc.Payment_Status === "Paid" &&
                dataDoc.Order_Status === "Completed" && ( */}
              <>
                <div className="row justify-content-center">
                  <form>
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label htmlFor="Balance" className="control-label ">
                            Total Balance :
                          </label>
                          <input
                            type="text"
                            name="Balance"
                            id="Balance"
                            className="form-control"
                            value={dataDoc.Balance}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label htmlFor="Amount" className="control-label ">
                            Payment Amount* :
                          </label>
                          <input
                            type="text"
                            name="Amount"
                            id="Amount"
                            disabled={dataDoc.Payment_Status === "Paid"}
                            value={totalAmount}
                            defaultValue={0}
                            className={`form-control ${errors.Amount ? "is-invalid" : ""
                              }`}
                            placeholder="Enter Payment Amount"
                            onChange={handlePaymentChange}
                          />
                          {errors.Amount && (
                            <div className="invalid-feedback">
                              {errors.Amount}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label htmlFor="Amount" className="control-label ">
                            Balance :
                          </label>
                          <input
                            type="text"
                            name="Amount"
                            id="Amount"
                            className="form-control"
                            disabled
                            value={balance}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="Payment_Mode"
                              className="form-label"
                            >
                              Payment Mode*
                            </label>
                            <Select
                              type="text"
                              name="Payment_Mode"
                              id="Payment_Mode"
                              isDisabled={dataDoc.Payment_Status === "Paid"}
                              options={Payment_Mode}
                              value={selectedPayMode}
                              onChange={handlePaymentModeChange}
                              className={` ${errors.Payment_Mode ? "is-invalid" : ""
                                }`}
                            />
                            {errors.Payment_Mode && (
                              <div className="invalid-feedback">
                                {errors.Payment_Mode}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="Payment_Date"
                              className="form-label"
                            >
                              Payment Date*
                            </label>
                            <input
                              type="date"
                              id="Payment_Date"
                              name="Payment_Date"
                              required
                              disabled={dataDoc.Payment_Status === "Paid"}
                              className={`form-control ${errors.Payment_Date ? "is-invalid" : ""
                                }`}
                              onChange={(e) =>
                                setDataDoc({
                                  ...dataDoc,
                                  Payment_Date: e.target.value,
                                })
                              }
                            />
                            {errors.Payment_Date && (
                              <div className="invalid-feedback">
                                {errors.Payment_Date}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {selectedPayModeValue === "Cheque" && (
                          <>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label htmlFor="Bank" className="form-label">
                                  Bank Name*
                                </label>
                                <Select
                                  type="text"
                                  name="Bank"
                                  id="Bank"
                                  options={Bank}
                                  value={selectedBank}
                                  onChange={handleBankChange}
                                  className={` ${errors.Bank ? "is-invalid" : ""
                                    }`}
                                />
                                {errors.Bank && (
                                  <div className="invalid-feedback">
                                    {errors.Bank}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label
                                  htmlFor="Cheque_No"
                                  className="form-label"
                                >
                                  Cheque No*
                                </label>
                                <input
                                  className={`form-control ${errors.Cheque_No ? "is-invalid" : ""
                                    }`}
                                  type="text"
                                  id="Cheque_No"
                                  name="Cheque_No"
                                  placeholder="Enter Cheque No"
                                  onChange={(e) =>
                                    setDataDoc({
                                      ...dataDoc,
                                      Cheque_No: e.target.value,
                                    })
                                  }
                                />
                                {errors.Cheque_No && (
                                  <div className="invalid-feedback">
                                    {errors.Cheque_No}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label
                                  htmlFor="Cheque_Date"
                                  className="form-label"
                                >
                                  Cheque Date*
                                </label>
                                <input
                                  className={`form-control ${errors.Cheque_Date ? "is-invalid" : ""
                                    }`}
                                  type="date"
                                  id="Cheque_Date"
                                  name="Cheque_Date"
                                  onChange={(e) =>
                                    setDataDoc({
                                      ...dataDoc,
                                      Cheque_Date: e.target.value,
                                    })
                                  }
                                />
                                {errors.Cheque_Date && (
                                  <div className="invalid-feedback">
                                    {errors.Cheque_Date}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="row">
                        {selectedPayModeValue === "Online" && (
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="Reference_No"
                                className="form-label"
                              >
                                Transaction ID*
                              </label>
                              <input
                                className={`form-control ${errors.Reference_No ? "is-invalid" : ""
                                  }`}
                                type="text"
                                id="Reference_No"
                                name="Reference_No"
                                placeholder="Enter Transaction ID"
                                value={inputValue}
                                onInput={handleInput}
                              />
                              {errors.Reference_No && (
                                <div className="invalid-feedback">
                                  {errors.Reference_No}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="control-label ">Remarks :</label>
                          <input
                            name="Remark"
                            type="text"
                            disabled={dataDoc.Payment_Status === "Paid"}
                            id="Remark"
                            className="form-control"
                            onChange={(e) =>
                              setDataDoc({
                                ...dataDoc,
                                Remarks: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12 ">
                        {dataDoc.Balance !== 0 && (
                          <Button
                            onClick={handleSubmit}
                            className="btn btn-primary"
                            variant="contained"
                            type="submit"
                            style={{
                              marginTop: "20px",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            Make Payment
                          </Button>)}
                      </div>
                    </div>
                  </form>
                </div>
              </>

              <div className="col-md-12 mt-4">
                <div>
                  <h4>Payment History</h4>
                  <table
                    className="tables productTable display dataTable w-100 table-bordered "
                    cellspacing="0"
                    id="ContentPlaceHolder1_gvProductsOrdered"
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid rgba(33, 33, 33, 0.1)",
                    }}
                  >
                    <thead>
                      <tr>
                        <th align="center" scope="col">
                          Payment Date
                        </th>
                        <th align="center" scope="col">
                          Paid Amount
                        </th>
                        <th align="center" scope="col">
                          Payment Mode
                        </th>

                        <th align="center" scope="col">
                          Bank
                        </th>
                        <th align="center" scope="col">
                          Cheque No
                        </th>
                        <th align="center" scope="col">
                          Cheque Date
                        </th>
                        <th align="center" scope="col">
                          Transaction ID
                        </th>
                        <th align="center" scope="col">
                          Remarks
                        </th>
                        <th align="center" scope="col">

                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs ? (
                        logs.map((row, index) => (
                          <tr key={index}>
                            <td align="center">
                              <span
                                id="ContentPlaceHolder1_gvProductsOrdered_lblName_0"
                                className="f-12"
                              >
                                {row.Payment_Date
                                  ? formatDate(row.Payment_Date)
                                  : "--"}
                              </span>
                            </td>
                            <td align="center">
                              <span id="ContentPlaceHolder1_gvProductsOrdered_lblPrice_0">
                                {row.Amount ? row.Amount : "--"}
                              </span>
                            </td>
                            <td align="center">
                              <span id="ContentPlaceHolder1_gvProductsOrdered_lblTotalPrice_0">
                                {row.Payment_Mode ? row.Payment_Mode : "--"}
                              </span>
                            </td>
                            <td align="center">
                              <span id="ContentPlaceHolder1_gvProductsOrdered_lblTotalPrice_0">
                                {row.Bank ? row.Bank : "--"}
                              </span>
                            </td>
                            <td align="center">
                              <span id="ContentPlaceHolder1_gvProductsOrdered_lblTotalPrice_0">
                                {row.Cheque_No ? row.Cheque_No : "--"}
                              </span>
                            </td>
                            <td align="center">
                              <span id="ContentPlaceHolder1_gvProductsOrdered_lblTotalPrice_0">
                                {row.Cheque_Date
                                  ? formatDate(row.Cheque_Date)
                                  : "--"}
                              </span>
                            </td>
                            <td align="center">
                              <span id="ContentPlaceHolder1_gvProductsOrdered_lblTotalPrice_0">
                                {row.Reference_No ? row.Reference_No : "--"}
                              </span>
                            </td>
                            <td align="center">
                              <span id="ContentPlaceHolder1_gvProductsOrdered_lblTotalPrice_0">
                                {row.Remarks ? row.Remarks : "--"}
                              </span>
                            </td>
                            <td align="center">
                              <Link
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {

                                  deleteUser(row.Sr_No, row.Client_Id, row.Order_Id);
                                }}
                              >
                                <i
                                  className="ri-delete-bin-3-line"
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                  }}
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {loader}
        </div>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
