import React, { useState, useEffect } from "react";
import axios from "axios";
import { Paper, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GridViewIcon from "@mui/icons-material/GridView";
import { Link } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import { apiUrl } from "../../componets/apiConfig";
import { useParams } from "react-router-dom";
import useFullPageLoader from "../../mui/useFullPageLoader";

import "./css.css";

const Reporting = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { Sr_No } = useParams();
  const [invoice, setInvoice] = useState({});
  const [product, setProduct] = useState([]);

  useEffect(() => {
    if (Sr_No) {
      showLoader();
      axios.get(`${apiUrl}/getInvoiceById/${Sr_No}`).then((res) => {
        setInvoice(res.data);
        hideLoader();
      });
    }
  }, [Sr_No]);

  useEffect(() => {
    if (Sr_No) {
      showLoader();
      axios.get(`${apiUrl}/getProductsByPI/${Sr_No}`).then((res) => {
        setProduct(res.data);
        hideLoader();
      });
    }
  }, [Sr_No]);

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }

  function number2text(value) {
    var fraction = Math.round(frac(value) * 100);
    var f_text = "";

    if (fraction > 0) {
      f_text = "AND " + convert_number(fraction) + " PAISE";
    }

    return convert_number(value) + " RUPEES " + f_text + " ONLY";
  }

  function frac(f) {
    return f % 1;
  }

  function convert_number(number) {
    if (number < 0 || number > 999999999) {
      return "NUMBER OUT OF RANGE!";
    }
    var Gn = Math.floor(number / 10000000); /* Crore */
    number -= Gn * 10000000;
    var kn = Math.floor(number / 100000); /* lakhs */
    number -= kn * 100000;
    var Hn = Math.floor(number / 1000); /* thousand */
    number -= Hn * 1000;
    var Dn = Math.floor(number / 100); /* Tens (deca) */
    number = number % 100; /* Ones */
    var tn = Math.floor(number / 10);
    var one = Math.floor(number % 10);
    var res = "";

    if (Gn > 0) {
      res += convert_number(Gn) + " CRORE";
    }
    if (kn > 0) {
      res += (res === "" ? "" : " ") + convert_number(kn) + " LAKH";
    }
    if (Hn > 0) {
      res += (res === "" ? "" : " ") + convert_number(Hn) + " THOUSAND";
    }

    if (Dn) {
      res += (res === "" ? "" : " ") + convert_number(Dn) + " HUNDRED";
    }

    var ones = [
      "",
      "ONE",
      "TWO",
      "THREE",
      "FOUR",
      "FIVE",
      "SIX",
      "SEVEN",
      "EIGHT",
      "NINE",
      "TEN",
      "ELEVEN",
      "TWELVE",
      "THIRTEEN",
      "FOURTEEN",
      "FIFTEEN",
      "SIXTEEN",
      "SEVENTEEN",
      "EIGHTEEN",
      "NINETEEN",
    ];
    var tens = [
      "",
      "",
      "TWENTY",
      "THIRTY",
      "FORTY",
      "FIFTY",
      "SIXTY",
      "SEVENTY",
      "EIGHTY",
      "NINETY",
    ];

    if (tn > 0 || one > 0) {
      if (!(res === "")) {
        res += " AND ";
      }
      if (tn < 2) {
        res += ones[tn * 10 + one];
      } else {
        res += tens[tn];
        if (one > 0) {
          res += "-" + ones[one];
        }
      }
    }

    if (res === "") {
      res = "zero";
    }
    return res;
  }

  const createCustomInvoicePDF = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    // Print the document
    window.print();

    // Restore the original contents
    document.body.innerHTML = originalContents;
  };

  return (
    <Paper elevation={3}>
      <>
        <div className="row justify-content-end  ">
          <div className="col-md-6 mt-3">
            <Button
              variant="contained"
              onClick={() => {
                createCustomInvoicePDF();
                window.location.reload();
              }}
              endIcon={<PrintIcon />}
              sx={{ marginLeft: 0, marginRight: 2 }}
            >
              Print
            </Button>

            <Link to={`/viewProforma`}>
              <Button
                variant="contained"
                endIcon={<GridViewIcon />}
                sx={{ marginLeft: 2, marginRight: 2 }}
              >
                View
              </Button>
            </Link>
            <Link to={`/proforma`}>
              <Button
                variant="contained"
                endIcon={<AddCircleIcon />}
                sx={{ marginLeft: 2, marginRight: 2 }}
              >
                Add
              </Button>
            </Link>
          </div>
        </div>
        <div className="container">
          <div className="div" id="printablediv">
            <div className="row  mb-4">
              <div className="col-md-4 col-sm-4">
                <img
                  src="/assets/images/invLogo.png"
                  alt="logo"
                  style={{
                    maxWidth: "230px",
                    height: "auto",
                    width: "100%",
                    paddingLeft: 0,
                  }}
                />
              </div>
              <div className="col-md-8 col-sm-8">
                <h1 className="text-center">DIGI COLORS</h1>
                <hr style={{ borderWidth: "5px" , margin:0}} />
                <p style={{ marginBottom: 0 }}>
                  1st floor Ganesh Apt., Santosh Nagar, Pune Link Road, Kalyan
                  East 421306
                </p>
                <div className="text-center">
                  <strong>Phone:</strong> 9004369859/8668514742
                </div>{" "}
                <div className="text-center">
                  <strong>Email:</strong>{" "}
                  <a href="digicolors15jan20@gmail.com">
                    digicolors15jan20@gmail.com
                  </a>
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-7 col-sm-7">
                <address>
                  <strong>Bill To,</strong>
                  <br />
                  <strong>{invoice.Org_Name}</strong>
                  <br />
                  {invoice.Owner_Name},
                  <br />
                  {invoice.Address && (
                    <div>
                      {invoice.Address}
                      <br />
                    </div>
                  )}
                  {invoice.Mobile_No}
                </address>
              </div>
              <div className="col-md-4 col-sm-4">
                <table className="table-responsive">
                  <tbody>
                    <tr>
                      <td
                        className="pull-right"
                        style={{ paddingRight: "8px", textAlign: "right" }}
                      >
                        <strong>Invoice No. :</strong>
                      </td>
                      <td>{invoice.Invoice_No}</td>
                    </tr>
                    <tr>
                      <td
                        className="pull-right"
                        style={{ paddingRight: "8px", textAlign: "right" }}
                      >
                        <strong>Date :</strong>
                      </td>
                      <td>{formatDate(invoice.Invoice_Date)}</td>
                    </tr>
                    <tr>
                      <td
                        className="pull-right"
                        style={{ paddingRight: "8px", textAlign: "right" }}
                      >
                        <strong>Due Date :</strong>
                      </td>
                      <td>{formatDate(invoice.Due_Date)}</td>
                    </tr>
                    {invoice.GST_NO && (
                      <tr>
                        <td
                          className="pull-right"
                          style={{ paddingRight: "8px", textAlign: "right" }}
                        >
                          <strong>GST No. :</strong>
                        </td>
                        <td>{invoice.GST_NO}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-2 col-sm-4">
              <div className="span8">
                <h2>Invoice</h2>
              </div>
            </div>
            <div className="row mb-2">
              <div className="span8 well invoice-body">
                <table className="table table-responsive table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "400px" }}>
                        Product
                      </th>
                      <th className="text-center">Quantity</th>
                      <th className="text-center">Price</th>
                      <th className="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.map((pro, index) => (
                      <tr key={index}>
                        <td style={{ padding: 5 }}>{pro.Product_Name}</td>
                        <td className="text-center" style={{ padding: 5 }}>
                          {pro.Quantity}
                        </td>
                        <td className="text-center" style={{ padding: 5 }}>
                          {pro.Price}
                        </td>
                        <td className="text-center" style={{ padding: 5 }}>
                          {pro.Total}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td colSpan="4"></td>
                    </tr>
                    <tr>
                      <td style={{ padding: 7 }} colSpan={3} align="right">
                        <strong> Gross Total</strong>
                      </td>
                      <td style={{ padding: 7 }} align="right">
                        <strong>{invoice.Gross_Total}₹</strong>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: 7 }} colSpan={3} align="right">
                        <strong>Discount</strong>
                      </td>
                      <td style={{ padding: 7 }} align="right">
                        <strong>-{invoice.Discount}₹</strong>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: 7 }} colSpan={2}>{`${number2text(
                        parseFloat(invoice.Net_Total)
                      )}`}</td>
                      <td style={{ padding: 7 }} colSpan={1} align="right">
                        <strong>Net Total</strong>
                      </td>
                      <td style={{ padding: 7 }} align="right">
                        <strong>{invoice.Net_Total}₹</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mb-4">
            <div className="col-md-6  col-sm-6">
              <strong>Account Details :</strong>
              <br />
              A/c Name : Digi Colors
              <br />
              A/c No : 012284100000212
              <br />
              IFSC : YESB0000122
              <br />
              A/c Type : Current
              <br />
              Branch : Ulhasnagar
              <br />
            </div>
            <div className="col-md-6   col-sm-6">
              <div align="right" >
                <img
                  src="/assets/images/QQR.png"
                  alt="logo"
                  style={{
                    maxWidth: "150px",
                    height: "auto",
                    width: "100%",
                    paddingLeft: 0,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mb-1">
          <p align="right" style={{ paddingRight: '30px' }}>For Digi Colors</p>
        </div>
        
            <div className="row">
              <div className="text-center">
                <p>*This Is A Computer Generated Invoice*</p>
              </div>
            </div>
          </div>
        </div>
        {loader}
      </>
    </Paper>
  );
};

export default Reporting;
