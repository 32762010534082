import React from "react";
import { MenuItem, Select } from "@mui/material";

const MultiSelect = ({ disabled, options, value, onChange }) => {
  return (
    <Select
      className="form-control"
      multiple={true}
      disabled={disabled}
      tabIndex="-1"
      value={value}
      onChange={onChange}
      size="small"
      sx={{ height: 38 }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultiSelect;
