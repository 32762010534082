import { useState, useEffect } from "react";
import axios from "axios";
import "../../CSS/datatable.css";
import "datatables.net";
import "jspdf-autotable";
import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import { Tooltip, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";

const SalesLedger = () => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [data, setData] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [errors, setErrors] = useState({});
    const [filter, setFilter] = useState({
        Client_Id: "",
        FromDate:"",
        ToDate: ""
    });
    async function Load() {
        showLoader();
        let fromdate="1900-01-01";
        let todate = dayjs().endOf("month").format("YYYY-MM-DD");
        if(filter.FromDate!=="")
        {
            fromdate = filter.FromDate;
        }
        if(filter.ToDate!=="")
        {
            todate = filter.ToDate;
        }
        const result = await axios.get(`${apiUrl}/getSalesLedger?Client_Id=${filter.Client_Id}&fromdate=${fromdate}&todate=${todate}`);
        if (result.data.length === 0) {
            setData([]);
            hideLoader();
        } else {
            setData(result.data);
            hideLoader();
        }
        return result;
    }
    async function ClientLoad() {
        showLoader();
        const result = await axios.get(`${apiUrl}/getClient`);
        if (result.data.length === 0) {
            setClientData([]);
            hideLoader();
        } else {
            setClientData(result.data);
            hideLoader();
        }
        return result;
    }
    useEffect(() => {
        if (userData) {
            ClientLoad();
        }
    }, [userData]);
    const FilterClick = () => {
        const validationErrors = {};
        if (filter.Client_Id === "") {
            validationErrors.Client = "Please Select Client";
        }
        if (Object.keys(validationErrors).length === 0) {
            setErrors({});
            Load();
        }
        else {
            setErrors(validationErrors);
        }
    }
    const DownloadPDF = () => {
        const doc = new jsPDF();
        doc.text("Sales Ledger", 20, 10);

        const columns = [
            "Sr. No.",
            "Date",
            "Summary",
            "Debit",
            "Credit",
            "Balance"
        ];

        const rows = data.map((row, index) => {
            let balanceText;
            if (row.Balance > 0) {
              balanceText = `${row.Balance} Dr`;
            } else if (row.Balance < 0) {
              balanceText = `${-row.Balance} Cr`;
            } else {
              balanceText = '0';
            }
          
            return [
              index + 1,
              new Date(row.Date).toLocaleDateString("en-GB"),
              row.Detail,
              row.Debit,
              row.Credit,
              balanceText, // Modified balance text based on conditions
            ];
          });
          

        doc.autoTable({
            head: [columns],
            body: rows,
        });

        doc.save("Sales_Ledger.pdf");
    };

    const DownloadExcel = () => {
        const columns = [
            "Sr. No.",
            "Date",
            "Summary",
            "Debit",
            "Credit",
            "Balance"
        ];

        const rows = [columns];
        data.forEach((row, index) => {
            let balanceText;
            if (row.Balance > 0) {
              balanceText = `${row.Balance} Dr`;
            } else if (row.Balance < 0) {
              balanceText = `${-row.Balance} Cr`;
            } else {
              balanceText = '0';
            }
          
            rows.push([
              index + 1,
              new Date(row.Date).toLocaleDateString("en-GB"),
              row.Detail,
              row.Debit,
              row.Credit,
              balanceText, // Modified balance text based on conditions
            ]);
          });
          
        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Sales-Ledger");

        // Buffer
        //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

        // Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

        // Download
        XLSX.writeFile(workBook, "Sales_Ledger.xlsx");
    };
    
    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
                    <h4 className="page-title">Sales Ledger</h4>
                    
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row justify-content-center mt-2">
                            <div className="col-4">
                                <label htmlFor="example-select" className="form-label">
                                    Client
                                </label>
                                <select className="form-select"
                                    onChange={(e) => {
                                        setFilter({
                                            ...filter,
                                            Client_Id: e.target.value,
                                        })
                                    }}>
                                    <option label="Select" value={''} ></option>
                                    {clientData && clientData.length > 0 && clientData.map((item, index) => (
                                        <option key={index} value={item.Sr_No}>
                                            {item.Company_Name}
                                        </option>
                                    ))}
                                </select>
                                <p className="text-danger">{errors.Client}</p>
                            </div>
                            <div className="col-4">
                                <label htmlFor="example-select" className="form-label">
                                    From Date
                                </label>
                                <input type="Date" id="txFromtDate" value={filter.FromDate} name="txFromtDate" className="form-control" placeholder="Date"
                                    onChange={(e) => {
                                        setFilter({
                                            ...filter,
                                            FromDate: e.target.value,
                                        })
                                    }} />
                            </div>
                            <div className="col-4">
                                <label htmlFor="example-select" className="form-label">
                                    To Date
                                </label>
                                <input type="Date" id="txtToDate" value={filter.ToDate} name="txtToDate" className="form-control" placeholder="Date"
                                    onChange={(e) => {
                                        setFilter({
                                            ...filter,
                                            ToDate: e.target.value,
                                        })
                                    }} />
                            </div>
                        </div>
                        <div className="row justify-content-center mt-2">
                            <div className="col-2">
                                <button className="btn btn-primary" onClick={FilterClick} style={{ width: "100%" }}> Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row justify-content-right" >
                            <div className="col-md-3 text-right">
                                <Tooltip title="Export to PDF">
                                    <IconButton style={{ cursor: "pointer" }} onClick={() => { DownloadPDF(); }}>
                                        <PictureAsPdfIcon />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Export to Excel">
                                    <IconButton style={{ cursor: "pointer" }} onClick={() => { DownloadExcel(); }}>
                                        {" "}
                                        <Icon path={mdiFileExcel} size={1} />
                                    </IconButton>
                                </Tooltip>
                            </div>                            
                            <div className="col-md-9 ">
                            {data && data.length > 0 ? (<h4 className="page-title" style={{float:"right"}}>Previous Balance: {data[0].previousBalance > 0 ? `${Math.abs(data[0].previousBalance)} Dr` : data[0].previousBalance < 0 ? `${Math.abs(data[0].previousBalance)} Cr` : '0'} </h4>) : (<></>)}
                            </div>
                        </div>
                        <div className=" table-responsive" style={{ minHeight: "220px" }}>
                            <table id="basic-datatable" className="table table-striped dt-responsive nowrap w-100">
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ padding: "10px 5px" }}>Sr. No.</th>
                                        <th className="text-left" style={{ padding: "10px 5px" }}>Date</th>
                                        <th className="text-left" style={{ padding: "10px 5px", paddingLeft: "20px" }}>Summary</th>
                                        <th style={{ padding: " 10px 10px", width: "100px", textAlign: "right" }}>Debit</th>
                                        <th style={{ padding: " 10px 10px", width: "100px", textAlign: "right" }}>Credit</th>
                                        <th style={{ padding: " 10px 10px", width: "100px", textAlign: "right" }}>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.length > 0 ? (
                                        data.map((item, index) => (
                                            <tr key={index}>
                                                <td className="text-center" style={{ padding: "10px 5px" }}>{index + 1}</td>
                                                <td className="text-left" style={{ padding: "10px 5px" }}>{new Date(item.Date).toLocaleDateString("en-GB")}</td>
                                                <td className="text-left" style={{ padding: "10px 5px", paddingLeft: "20px" }}>{item.Detail}</td>
                                                <td style={{ padding: " 10px 10px", width: "100px", textAlign: "right" }}>{item.Debit}</td>
                                                <td style={{ padding: " 10px 10px", width: "100px", textAlign: "right" }}>{item.Credit}</td>
                                                <td style={{ padding: " 10px 10px", width: "100px", textAlign: "right" }}>
                                                    {item.Balance > 0 ? `${Math.abs(item.Balance)} Dr` : item.Balance < 0 ? `${Math.abs(item.Balance)} Cr` : '0'}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr >
                                            <td className="text-center text-danger"
                                                colSpan={6}>No Records Found!!</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table >
                        </div>
                    </div>
                </div>
                {loader}
            </div>
        </div>
    )
}
export default SalesLedger