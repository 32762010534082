import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import DataTable from "react-data-table-component";
import SummaryModal from "./Modal";
import { Menu, MenuItem } from "@mui/material";
import "../../CSS/datatable.css";
import "datatables.net";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Success, Confirmation } from "../Alerts/alert";
import * as XLSX from "xlsx";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Tooltip, Button } from "@mui/material";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import IconButton from "@mui/material/IconButton";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";

const ViewProforma = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setPorfomaData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState({});
  const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
  const [toDate, setToDate] = useState(dayjs().endOf("month"));
  const [uniqueStatus, setUniqueStatus] = useState([]);
  const [dataa, setdataa] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedstatus, setSelectedstatus] = useState("-1");
  const [selectedRowSrNo, setSelectedRowSrNo] = useState(null);

  const handleMenuClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowSrNo(id);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    Load();
  };

  useEffect(() => {
    (async () => {
      const result = await Load();
      setPorfomaData(result.data);
      setIsDataLoaded(true);
    })();
  }, []);

  async function Load() {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getProformabyOrg?status=${selectedstatus}&fromDate=${fromDate.format(
        "YYYY-MM-DD"
      )}&ToDate=${toDate.format("YYYY-MM-DD")}`
    );
    const res = await axios.get(`${apiUrl}/getProforma`);
    const status = [...new Set(res.data.map((item) => item.Org_Name))];
    const sortedData = result.data.sort((a, b) => b.Sr_No - a.Sr_No);
    setUniqueStatus(status);
    setPorfomaData(sortedData);
    setdataa(sortedData);
    hideLoader();
    return result;
  }

  const handleDelete = async (Id) => {
    handleMenuClose();
    const confirmed = await Confirmation("You Want to Delete this Record?");

    if (confirmed) {
      try {
        await axios.delete(`${apiUrl}/deleteProforma/${Id}`);
        Success("Proforma record deleted successfully.");
        Load();
        setIsDataLoaded(true);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    }
  };

  const handleDateRangeFilter = async () => {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getProformabyOrg?status=${selectedstatus}&&fromDate=${fromDate.format(
        "YYYY-MM-DD"
      )}&ToDate=${toDate.format("YYYY-MM-DD")}`
    );
    const sortedData = result.data.sort((a, b) => b.Sr_No - a.Sr_No);
    setPorfomaData(sortedData);
    setdataa(sortedData);
    hideLoader();
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();

    const filteredData = dataa.filter((row) => {
      return (
        (row.Invoice_No &&
          row.Invoice_No.toLowerCase().includes(filterValue)) ||
        (row.Org_Name && row.Org_Name.toLowerCase().includes(filterValue)) ||
        (formatDate(row.Invoice_Date) &&
          formatDate(row.Invoice_Date).toLowerCase().includes(filterValue)) ||
        (formatDate(row.Due_Date) &&
          formatDate(row.Due_Date).toLowerCase().includes(filterValue)) ||
        (typeof row.Gross_Total === "string" &&
          row.Gross_Total.toLowerCase().includes(filterValue)) ||
        (typeof row.Discount === "string" &&
          row.Discount.toLowerCase().includes(filterValue)) ||
        (typeof row.Net_Total === "string" &&
          row.Net_Total.toLowerCase().includes(filterValue))
      );
    });
    setPorfomaData(filteredData);
  }

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Proforma Invoice", 20, 10);

    const columns = [
      " Inv No",
      " Organization",
      " Mobile No",
      " Inv Date",
      " Due Date",
      " Gross Amt",
      " Discount",
      " Net Amt",
    ];

    const rows = data.map((row) => [
      row.Invoice_No,
      row.Org_Name,
      row.Mobile_No,
      formatDate(row.Invoice_Date),
      formatDate(row.Due_Date),
      row.Gross_Total,
      row.Discount,
      row.Net_Total,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Proforma Invoice.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      " Inv No",
      " Organization",
      " Mobile No",
      " Inv Date",
      " Due Date",
      " Gross Amt",
      " Discount",
      " Net Amt",
    ];

    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        row.Invoice_No,
        row.Org_Name,
        row.Mobile_No,
        formatDate(row.Invoice_Date),
        formatDate(row.Due_Date),
        row.Gross_Total,
        row.Discount,
        row.Net_Total,
      ]);
    });
    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Proforma Invoice");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Proforma Invoice.xlsx");
  };

  const handleOpenSummaryModal = (Sr_No) => {
    const newModalState = { ...isSummaryModalOpen };
    newModalState[Sr_No] = true;
    setIsSummaryModalOpen(newModalState);
  };

  const handleCloseSummaryModal = (Sr_No) => {
    const newModalState = { ...isSummaryModalOpen };
    newModalState[Sr_No] = false;
    setIsSummaryModalOpen(newModalState);
    handleMenuClose();
  };

  const columns = [
    {
      name: "Inv No",
      selector: "Invoice_No",
      width: "140px",
      sortable: true,
    },
    {
      name: "OrgName",
      selector: "Org_Name",
      width: "200px",
      sortable: true,
    },
    {
      name: "Inv Date",
      selector: (row) => formatDate(row.Invoice_Date),
      width: "120px",
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => formatDate(row.Due_Date),
      sortable: true,
      width: "120px",
    },
    {
      name: "Gross Amt",
      selector: "Gross_Total",
      sortable: true,
      width: "120px",
    },
    {
      name: "Discount",
      selector: "Discount",
      sortable: true,
      width: "110px",
    },
    {
      name: "Net Amt",
      selector: "Net_Total",
      sortable: true,
      width: "110px",
    },
    {
      cell: (row) => (
        <div className="text-center">
          <IconButton
            onClick={(event) => handleMenuClick(event, row.Sr_No)}
            aria-controls="row-menu"
            aria-haspopup="true"
            style={{ cursor: "pointer", color: "grey" }}
          >
            <i className="ri-more-2-fill" style={{ fontSize: "16px" }}></i>
          </IconButton>
          <Menu
            id="row-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <Link
                onClick={() => {
                  handleOpenSummaryModal(selectedRowSrNo);
                }}
                style={{ cursor: "pointer", color: "grey" }}
              >
                <i
                  className="ri-file-chart-fill"
                  style={{
                    color: "blue",
                    fontSize: "12px",
                  }}
                ></i>
                <span> Summary</span>
              </Link>
            </MenuItem>
            <SummaryModal
              open={isSummaryModalOpen[selectedRowSrNo] || false}
              onClose={() => {
                handleCloseSummaryModal(selectedRowSrNo);
              }}
              Sr_No={selectedRowSrNo}
            />

            <MenuItem>
              <Link
                to={`/reporting/${selectedRowSrNo}`}
                style={{ cursor: "pointer", color: "grey" }}
              >
                <i
                  className="ri-printer-fill"
                  style={{
                    color: "orange",
                    fontSize: "12px",
                  }}
                ></i>
                <span> Print Invoice</span>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                onClick={() => handleDelete(selectedRowSrNo)}
                style={{ cursor: "pointer", color: "grey" }}
              >
                <i
                  className="ri-delete-bin-3-line"
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                ></i>
                <span> Delete</span>
              </Link>
            </MenuItem>
          </Menu>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const filterData = (selectedMonth) => {
    setSelectedstatus(selectedMonth);
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box justify-content-between d-flex align-items-md-center flex-md-row flex-column">
              <h4 className="page-title">View Proforma Invoice</h4>
              <div className="page-title">
                <Link to={`/proforma`}>
                  <Button variant="contained" endIcon={<AddCircleIcon />}>
                    Add
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <h4 className="card-title" style={{ margin: "10px 0 0 10px" }}>
                Filter
              </h4>
              <div className="card-body">
                <div className="row mt-2">
                  <div className="col-4">
                    <label className="form-label mb-2">
                      Filter By Org Name:
                    </label>
                    <select
                      className="form-select"
                      id="example-select"
                      onChange={(e) => filterData(e.target.value)}
                    >
                      <option value={"-1"}>All</option>
                      {uniqueStatus.map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-4">
                    {/* Your From Date DatePicker */}
                    <label className="form-label mb-2">From Date:</label>
                    <input
                    type="date"
                    className="form-control"
                    value={fromDate.format("YYYY-MM-DD")}
                    onChange={(date) =>
                      setFromDate(dayjs(date.target.value))
                    }
                  />
                  </div>
                  <div className="col-4">
                    {/* Your To Date DatePicker */}
                    <label className="form-label mb-2">To Date:</label>
                    <input
                    type="date"
                    className="form-control"
                    value={toDate.format("YYYY-MM-DD")}
                    onChange={(date) =>
                      setToDate(dayjs(date.target.value))
                    }
                  />
                  </div>
                </div>
                <div className="row mt-3 justify-content-center">
                  <div className="col-2">
                    <Button variant="contained" onClick={handleDateRangeFilter}>
                      Filter
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-end mb-3">
                  <TextField
                    onChange={handleFilter}
                    size="small"
                    label="Search..."
                  />

                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  ></Typography>
                  <Tooltip title="Export to PDF">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </IconButton>
                  </Tooltip>
                </div>

                <div
                  className="table-responsive"
                  style={{ zIndex: 0, position: "relative" }}
                >
                  <DataTable
                    columns={columns}
                    data={data}
                    defaultSortField="Invoice_Date"
                    customStyles={customStyles}
                    fixedHeader
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};

export default ViewProforma;
