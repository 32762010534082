import * as React from "react";
import * as XLSX from "xlsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { apiUrl } from "../../componets/apiConfig";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../mui/useFullPageLoader";

export default function ViewClient() {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRoleRoles, setSelectedRoles] = useState("-1");
  const [uniqueRoles, setUniqueRoles] = useState([]);
  const [records, setRecords] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      Load();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      handleFilterButton();
    })();
  }, [selectedRoleRoles]);
  
  async function Load() {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getStaffByRole?role=${selectedRoleRoles}`
    );
    const res = await axios.get(`${apiUrl}/getStaff`);
    const roles = [...new Set(res.data.map((item) => item.Role))];
    const sortedData = result.data.sort((a, b) => b.Id - a.Id);
    setUniqueRoles(roles);
    setData(sortedData);
    setRecords(sortedData);
    hideLoader();
    return result, res;
  }

  const handleFilterButton = async () => {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getStaffByRole?role=${selectedRoleRoles}`
    );
    const sortedData = result.data.sort((a, b) => b.Id - a.Id);
    setData(sortedData);
    setRecords(sortedData);
    hideLoader();
  };

  const deleteUser = (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id);
      }
    });
  };

  async function handleDelete(Id) {
    await axios.delete(`${apiUrl}/deleteStaff/${Id}`);
    Swal.fire("Deleted!", "Your file has been deleted.", "success");
    Load();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Staff Details", 20, 10);

    const columns = ["Staff ID", "Name", "Mobile No", "Role", "Shift Time"];

    const rows = records.map((row) => [
      row.Staff_Id,
      `${row.First_Name} ${row.Last_Name}`,
      row.Mobile_No,
      row.Role,
      row.Role === "Sales Person"
        ? "--"
        : `${new Date(row.MF_InTime).toLocaleTimeString()} - ${new Date(
            row.MF_OutTime
          ).toLocaleTimeString()}`,
    ]);
    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Staff-Data.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Staff ID", "Name", "Mobile No", "Role", "Shift Time"];
    const rows = [columns];
    records.forEach((row) => {
      rows.push([
        row.Staff_Id,
        `${row.First_Name} ${row.Last_Name}`,
        row.Mobile_No,
        row.Role,
        row.Role === "Sales Person"
          ? "--"
          : `${new Date(row.MF_InTime).toLocaleTimeString()} - ${new Date(
              row.MF_OutTime
            ).toLocaleTimeString()}`,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Staff-Data");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Staff-Data.xlsx");
  };

  const filterData =  (selectedRole) => {
    setSelectedRoles(selectedRole);
 
  };

  const handleFilter = (event) => {
    const filterValue = event.target.value.toLowerCase();

    const filteredData = data.filter((row) => {
      const fullName = `${row.First_Name} ${row.Last_Name}`;
      const inTime = new Date(row.MF_InTime).toLocaleTimeString();
      const outTime = new Date(row.MF_OutTime).toLocaleTimeString();

      return (
        fullName.toLowerCase().includes(filterValue) ||
        row.Role.toLowerCase().includes(filterValue) ||
        (row.Staff_Id && row.Staff_Id.toLowerCase().includes(filterValue)) ||
        (row.Mobile_No && row.Mobile_No.toLowerCase().includes(filterValue)) ||
        inTime.includes(filterValue) ||
        outTime.includes(filterValue)
      );
    });

    setRecords(filteredData);
  };

  return (
    <div>
      <div className="row d-flex justify-content-end mb-2 ml-1">
        <div className="col-2 ">
          <label className="form-label">Filter By Role:</label>

          <select
            className="form-select"
            id="example-select"
            onChange={(e) => filterData(e.target.value)}
          >
            <option value={"-1"}>All</option>
            {uniqueRoles.map((role, index) => (
              <option key={index} value={role}>
                {role}
              </option>
            ))}
          </select>
        </div>
      </div>

      <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
        <TextField onChange={handleFilter} size="small" label="Search..." />
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        <Tooltip title="Export to PDF">
          <IconButton
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              DownloadPDF();
            }}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Export to Excel">
          <IconButton
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              DownloadExcel();
            }}
          >
            {" "}
            <Icon path={mdiFileExcel} size={1} />
          </IconButton>
        </Tooltip>
        <Link to={`/addStaff`}>
          <Button variant="contained" endIcon={<AddCircleIcon />}>
            Add
          </Button>
        </Link>
      </Stack>
      <Box height={10} />
      <TableContainer
        className=""
        sx={{
          maxHeight: "100%",
          minHeight: "170px",
          zIndex: 0,
          position: "relative",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ minWidth: "50px" }}>
                Staff ID
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Name
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Mobile No
              </TableCell>
              <TableCell align="center" style={{ minWidth: "69px" }}>
                Role
              </TableCell>
              <TableCell align="center" style={{ minWidth: "130px" }}>
                Shift Time
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records && records.length > 0 ? (
              records
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      style={{ height: "30px" }}
                    >
                      <TableCell align="left" style={{ padding: "5px" }}>
                        {row.Staff_Id}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ padding: "5px" }}
                      >{`${row.First_Name} ${row.Last_Name}`}</TableCell>
                      <TableCell align="center" style={{ padding: "5px" }}>
                        {row.Mobile_No}
                      </TableCell>
                      <TableCell align="center" style={{ padding: "5px" }}>
                        {row.Role}
                      </TableCell>
                      {row.Role === "Sales Person" ? (
                        <TableCell align="center" style={{ padding: "5px" }}>
                          --
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          style={{
                            maxWidth: "130px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            padding: "5px",
                          }}
                        >
                          {`${new Date(
                            row.MF_InTime
                          ).toLocaleTimeString()} - ${new Date(
                            row.MF_OutTime
                          ).toLocaleTimeString()}`}
                        </TableCell>
                      )}

                      <TableCell
                        className="text-cneter"
                        style={{ padding: "5px" }}
                      >
                        <IconButton
                          type="button"
                          className="btn shadow-none"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ padding: "0px", border: 0 }}
                        >
                          <i
                            className="ri-more-2-fill"
                            style={{ fontSize: "16px" }}
                          ></i>
                        </IconButton>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              to={`/updateStaff/${row.Id}`}
                              className="dropdown-item"
                            >
                              <i
                                className="ri-edit-2-line"
                                style={{
                                  color: "blue",
                                  fontSize: "16px",
                                }}
                              ></i>
                              <span> Update</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => deleteUser(row.Id)}
                              className="dropdown-item cursor-pointer"
                            >
                              <i
                                className="ri-delete-bin-3-line"
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                }}
                              ></i>
                              <span> Delete</span>
                            </Link>
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="mt-3"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={records.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {loader}
    </div>
  );
}
