import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Staff.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Settings from "./Settings";
import Payroll from "./Payroll";
import Biometric from "./Biometric";
import { apiUrl } from "../../componets/apiConfig";
import useFullPageLoader from "../../mui/useFullPageLoader";

function EditStaff() {
  const [value, setValue] = useState("one");
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabContent = () => {
    switch (value) {
      case "one":
        return (
          <div>
            {" "}
            <Settings />
            
          </div>
        );
      case "two":
        return (
          <div>
            {" "}
            <Biometric />
          </div>
        );
      case "three":
        return (
          <div>
           <Payroll />
          </div>
        );
      default:
        return null;
    }
  };

  const [data, setData] = useState({
    UUID: "",
    First_Name: "",
    Last_Name: "",
    Mobile_No: "",
    Address: "",
    MF_InTime: "",
    MF_OutTime: "",
  });
  const { Id } = useParams();

  useEffect(() => {
    if (Id) {
      showLoader();
      axios.get(`${apiUrl}/getStaff/${Id}`).then((res) => {
        const result = res.data;
        setData(result);
        hideLoader();
      });
    }
  }, [Id]);

  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-3 col-lg-4">
          <div
            class="card text-center"
            style={{ position: "fixed", width: "19%" }}
          >
            <div class="card-body">
              <img
                id="dummyImage"
                className="rounded-circle avatar-lg img-thumbnail"
                alt="dummyImage"
                src="/assets/images/dummy.png"
              />

              <h4 class="mb-1 mt-2">
                <span id="ContentPlaceHolder1_Staffname">
                  {" "}
                  {data.First_Name} {data.Last_Name}
                </span>
              </h4>
              <p class="text-muted">
                <span id="ContentPlaceHolder1_lblRole">Staff</span>
              </p>

              <div class="text-start mt-3">
                <h4 class="fs-13 text-uppercase">About Me :</h4>

                <p class="text-muted mb-2">
                  <strong>Staff ID :</strong>{" "}
                  <span class="ms-2">
                    <span id="ContentPlaceHolder1_lblstaffEmail"></span>
                    {data.Staff_Id}
                  </span>
                </p>

                <p class="text-muted mb-2">
                  <strong>Name :</strong>{" "}
                  <span class="ms-2">
                    <span id="ContentPlaceHolder1_lblName">
                      {data.First_Name} {data.Last_Name}
                    </span>
                  </span>
                </p>

                <p class="text-muted mb-2">
                  <strong>Mobile :</strong>
                  <span class="ms-2">
                    <span id="ContentPlaceHolder1_lblstaffmobile">
                      {data.Mobile_No}
                    </span>
                  </span>
                </p>

                <p class="text-muted mb-1">
                  <strong>Shift Time :</strong>{" "}
                  <span class="ms-2">
                    <span id="ContentPlaceHolder1_lblDesignation"></span>
                    <br />
                    {`${new Date(
                      data.MF_InTime
                    ).toLocaleTimeString()} - ${new Date(
                      data.MF_OutTime
                    ).toLocaleTimeString()}`}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-lg-8">
          <div class="card">
            <div class="card-body">
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
                >
                  <Tab value="one" label="Details" />
                  <Tab value="two" label="Biometric" />
                  <Tab value="three" label="Payroll" />
                
                </Tabs>
                {renderTabContent()}{" "}
                {/* Display the content based on the selected tab */}
              </Box>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
}

export default EditStaff;
