import React from "react";
import Table from "./ExpensesDataTable.js";

const ViewBill = () => {

  return (
   
            <Table/>
     
  );
};

export default ViewBill;
