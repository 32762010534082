import React from 'react';
import { Link } from "react-router-dom";

const ProformaTableTd = (props) => {
    const handleRemove = () => {
        props.onRemove(props.productId);
      };
    return (
        <tr>
            <td>{props.name}</td>
            <td>{props.price}</td>
            <td>{props.quantity}</td>
            <td>{props.total}</td>
            <td>
        <Link
          style={{
            cursor: "pointer",
          }}
          onClick={handleRemove}
        >
          <i
            className="ri-delete-bin-3-line"
            style={{
              color: "red",
              fontSize: "12px",
            }}
          ></i>
        </Link>
      </td>
        </tr>
    )
}
export default ProformaTableTd;
