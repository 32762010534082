import * as React from "react";
import * as XLSX from "xlsx";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../componets/apiConfig";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChangePasswordModal from "./ProductSummary";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import { useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../mui/useFullPageLoader";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";

const ClientsOrder = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { Sr_No } = useParams();
  const columns = [
    {
      name: "Order No",
      selector: (row) => row.Order_No,
      //width: "120px",
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row) => formatDate(row.Order_Date),
      //width: "120px",
      sortable: true,
    },

    {
      name: "P-Status",
      selector: (row) => row.Payment_Status,

      sortable: true,
      //width: "100px",
    },
    {
      name: "Net Total",
      selector: (row) => row.Net_Total,

      sortable: true,
      //width: "100px",
    },
    {
      name: "Paid",
      selector: (row) => row.Paid,
      sortable: true,
      //width: "90px",
    },
    {
      name: "Balance",
      selector: (row) => row.Balance,
      sortable: true,
      //width: "90px",
    },
    {
      name: "O-Status",
      selector: (row) => row.Order_Status,
      sortable: true,
      //width: "100px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            onClick={(event) => handleMenuClick(event, row.Sr_No)}
            aria-controls="row-menu"
            aria-haspopup="true"
          >
            <i className="ri-more-2-fill" style={{ fontSize: "16px" }}></i>
          </IconButton>
          <Menu
            id="row-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleOpenChangePasswordModal(selectedRowSrNo);
              }}
            >
              Summary
            </MenuItem>
            <ChangePasswordModal
              open={isChangePasswordModalOpen}
              onClose={handleCloseChangePasswordModal}
              Sr_No={selectedRowSrNo}
            />
            <MenuItem
              onClick={() => {
                handleMenuClose();
                deleteUser(selectedRowSrNo);
              }}
              style={{ color: "red" }}
            >
              Delete
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleOpenChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
    handleMenuClose();
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowSrNo, setSelectedRowSrNo] = useState(null);
  const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
  const [toDate, setToDate] = useState(dayjs().endOf("month"));
  const [selectedStatus, setSelectedStatus] = useState("-1");
  const [records, setRecords] = useState([]);
  const [data, setData] = useState([]);
  const [orderCount, setOrderCount] = useState({});
  const [paidBal, setpaidBal] = useState({});

  useEffect(() => {
    (async () => {
      loadExpensesData();
    })();
  }, []);

  const loadExpensesData = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${apiUrl}/getOrdersbyClient/${Sr_No}?&fromDate=${fromDate.format(
          "YYYY-MM-DD"
        )}&toDate=${toDate.format("YYYY-MM-DD")}`
      );
      const result = await axios.get(
        `${apiUrl}/getOrderCountByClient/${Sr_No}`
      );

      const res = await axios.get(`${apiUrl}/getClient/${Sr_No}`);
      setpaidBal(res.data);
     // console.log(res.data);
      const sortedData = response.data;

      setData(sortedData);
      //console.log(result.data.OrderCount);
      setRecords(sortedData);
      setOrderCount(result.data);
    } catch (error) {
      console.error("Error loading expenses data:", error);
    } finally {
      hideLoader();
    }
  };

  const deleteUser = (Sr_No) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No);
      }
    });
  };

  const handleMenuClick = (event, Sr_No) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowSrNo(Sr_No);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    loadExpensesData();
  };

  async function handleDelete(Sr_No) {
    await axios.delete(`${apiUrl}/deleteExpense/${Sr_No}`);
    Swal.fire("Deleted!", "Your file has been deleted.", "success");
    loadExpensesData();
  }

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }

  const handleDateRangeFilter = async () => {
    showLoader();
    const response = await axios.get(
      `${apiUrl}/getOrdersbyClient/${Sr_No}?&fromDate=${fromDate.format(
        "YYYY-MM-DD"
      )}&toDate=${toDate.format("YYYY-MM-DD")}`
    );
    const sortedData = response.data;
    setData(sortedData);
    setRecords(sortedData);
    hideLoader();
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Client Order", 20, 10);

    const columns = [
      "Order No",
      "Order Date",
      "P-Status",
      "Net Total",
      "Paid",
      "Balance",
      "O-Status",
    ];

    const rows = records.map((row) => [
      row.Order_No,
      formatDate(row.Order_Date),
      row.Payment_Status,
      row.Net_Total,
      row.Paid,
      row.Balance,
      row.Order_Status,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Client_Order.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Order No",
      "Order Date",
      "P-Status",
      "Net Total",
      "Paid",
      "Balance",
      "O-Status",
    ];

    const rows = [columns];
    records.forEach((row) => {
      rows.push([
        row.Order_No,
        formatDate(row.Order_Date),
        row.Payment_Status,
        row.Net_Total,
        row.Paid,
        row.Balance,
        row.Order_Status,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Client Order");

    // Buffer
    // let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Client_Order.xlsx");
  };

  const filterData = (selectedMonth) => {
    setSelectedStatus(selectedMonth);
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();

    const filteredData = data.filter((row) => {
      return (
        (row.Order_No && row.Order_No.toLowerCase().includes(filterValue)) ||
        (formatDate(row.Order_Date) &&
          formatDate(row.Order_Date).toLowerCase().includes(filterValue)) ||
        (row.Payment_Status &&
          row.Payment_Status.toLowerCase().includes(filterValue)) ||
        (row.Net_Total && row.Net_Total.toLowerCase().includes(filterValue)) ||
        (row.Paid && row.Paid.toLowerCase().includes(filterValue)) ||
        (row.Balance && row.Balance.toLowerCase().includes(filterValue)) ||
        (row.Order_Status &&
          row.Order_Status.toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  const CountWithCommas = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString();
  };

  return (
    <div>
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
              <h4 className="page-title">
                Orders Report -{" "}
                {paidBal.Company_Name}
              </h4>
            </div>
            <div class="row ">
              <div class=" col-md-3">
                <div class="card widget-flat">
                  <div class="card-body">
                    <h5
                      class="text-muted fw-normal mt-0"
                      title="Number of Customers"
                    >
                      Orders
                    </h5>
                    <h3 class="my-3">
                      {CountWithCommas(orderCount.OrderCount)}
                    </h3>
                  </div>
                </div>
              </div>

              <div class=" col-md-3">
                <div class="card widget-flat">
                  <div class="card-body">
                    <h5
                      class="text-muted fw-normal mt-0"
                      title="Number of Orders"
                    >
                      Net Income
                    </h5>
                    <h3 class="my-3">
                      ₹{CountWithCommas(paidBal.Total)}
                    </h3>
                  </div>
                </div>
              </div>

              <div class=" col-md-3">
                <div class="card widget-flat">
                  <div class="card-body">
                    <h5
                      class="text-muted fw-normal mt-0"
                      title="Average Revenue"
                    >
                      Paid
                    </h5>
                    <h3 class="my-3">₹{CountWithCommas(paidBal.Paid)}</h3>
                  </div>
                </div>
              </div>

              <div class=" col-md-3">
                <div class="card widget-flat">
                  <div class="card-body">
                    <h5 class="text-muted fw-normal mt-0" title="Growth">
                      Balance
                    </h5>
                    <h3 class="my-3">₹{CountWithCommas(paidBal.Balance)}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <h4
                    className="card-title"
                    style={{ margin: "10px 0 0 10px" }}
                  >
                    Filter
                  </h4>
                  <div className="card-body">
                    <div className="row  mt-2  justify-content-center">
                      <div className="col-4">
                        {/* Your From Date DatePicker */}
                        <label className="form-label mb-2">From Date:</label>
                        <input
                          type="date"
                          className="form-control"
                          value={fromDate.format("YYYY-MM-DD")}
                          onChange={(date) =>
                            setFromDate(dayjs(date.target.value))
                          }
                        />
                      </div>
                      <div className="col-4">
                        {/* Your To Date DatePicker */}
                        <label className="form-label mb-2">To Date:</label>
                        <input
                          type="date"
                          className="form-control"
                          value={toDate.format("YYYY-MM-DD")}
                          onChange={(date) =>
                            setToDate(dayjs(date.target.value))
                          }
                        />
                      </div>
                    </div>
                    <div className="row mt-3 justify-content-center">
                      <div className="col-2">
                        <Button
                          variant="contained"
                          onClick={handleDateRangeFilter}
                        >
                          Filter
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <Box height={10} />
                <div className="d-flex justify-content-end mb-2 ml-1"></div>
                <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
                  <TextField
                    onChange={handleFilter}
                    size="small"
                    label="Search..."
                  />

                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  ></Typography>
                  <Tooltip title="Export to PDF">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <IconButton
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Box height={10} />
                <div style={{ zIndex: 0, position: "relative" }}>
                  <DataTable
                    columns={columns}
                    data={records}
                    customStyles={customStyles}
                    fixedHeader
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {loader}
    </div>
  );
};

export default ClientsOrder;
