import { useEffect } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { useNavigate } from 'react-router-dom';

const SuccessNavigation = ({ text, url }) => {    
    debugger;
  const navigate = useNavigate();
  useEffect(() => {
    const showAlert = async () => {
      const result = await Swal.fire({
        title: 'Success',
        text: text,
        icon: 'success',
        confirmButtonColor: '#089d15',
        confirmButtonText: 'Ok',
      });

      if (result.isConfirmed) {
        navigate(url);
      }
    };

    showAlert();
  }, [navigate, text, url]);

  return null; 
};

export default SuccessNavigation;
