import * as React from "react";
import * as XLSX from "xlsx";
import Select from "react-select";
import Table from "@mui/material/Table";
import { Link } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";
import useFullPageLoader from "../../mui/useFullPageLoader";

const AddProduct = () => {
  const [page, setPage] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [Id, setId] = useState(null);
  const [Product_Name, setProductName] = useState("");
  const [Product_Price, setProductPrice] = useState("");
  const [Description, setDescription] = useState("");
  const [data, setData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [error, setError] = useState("");
  const { userData } = useUser();
  const [selectedCategory, setselectedCategory] = useState([]);
  const [CategoryOption, setCategoryOption] = useState([]);
  const [CategoryName, setCategoryName] = useState("");
  const [CategoryId, setCategoryId] = useState(null);

  const [selectedUnits, setselectedUnits] = useState([]);
  const [UnitsOption, setUnitsOption] = useState([]);
  const [UnitsName, setUnitsName] = useState("");
  const [UnitsId, setUnitsId] = useState(null);

  useEffect(() => {
    const loadUnitsData = async () => {
      try {
        showLoader();
        const response = await axios.get(`${apiUrl}/getUnitOfMeasurements`);
        const UnitsDate = response.data;
        const Units = UnitsDate.map((Units) => ({
          label: Units.Units,
          value: Units.Sr_No,
        }));
        setUnitsOption(Units);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Failed to load Unit Of Measurement.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Error loading  data:", error);
      } finally {
        hideLoader();
      }
    };
    loadUnitsData();
  }, []);

  useEffect(() => {
    const loadProdData = async () => {
      try {
        showLoader();
        const response = await axios.get(`${apiUrl}/getProductCategories`);
        const ProdDate = response.data;
        const Category = ProdDate.map((Prod) => ({
          label: Prod.Product_Name,
          value: Prod.Sr_No,
        }));
        setCategoryOption(Category);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Failed to Adding Product category.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Error while load Product data:", error);
      } finally {
        hideLoader();
      }
    };
    loadProdData();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await Load();
      setData(result.data);
    })();
  }, []);

  async function Load() {
    showLoader();
    const result = await axios.get(`${apiUrl}/getProducts`);
    const sortedData = result.data.sort((a, b) => b.Id - a.Id);
    setData(sortedData);
    setRecords(sortedData);
    hideLoader();
    return result;
  }

  async function save(event) {
    event.preventDefault();

    const error = {};

    if (Object.keys(error).length === 0) {
      try {
        await axios.post(`${apiUrl}/addProduct`, {
          Product_Category_Id: CategoryId,
          Product_Category: CategoryName,
          Product_Name: Product_Name,
          Unit_Of_Measurement_Id: UnitsId,
          Unit_Of_Measurement: UnitsName,
          Product_Price: Product_Price,
          Description: Description,
          Added_By_Id: userData.userId,
          Added_By: `${userData.firstName} ${userData.lastName}`,
        });
        Swal.fire("Success!", "Your Product has been Added.", "success");
        setError("");
        setDescription("");
        setProductPrice("");
        setProductName("");
        setselectedUnits(null);
        setselectedCategory(null);
        setId(null);
        Load();
      } catch (err) {
        Swal.fire({
          title: "Error",
          text: "Failed to add Product. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      setError(error);
    }
  }

  async function editExpenses(data) {
    setselectedCategory({
      label: data.Product_Category,
      value: data.Product_Category_Id,
    });
    setselectedUnits({
      label: data.Unit_Of_Measurement,
      value: data.Unit_Of_Measurement_Id,
    });

    setDescription(data.Description);
    setProductPrice(data.Product_Price);
    setProductName(data.Product_Name);
    setId(data.Id);
    setEditingItem(data);
  }

  async function update(event) {
    event.preventDefault();

    try {
      const updatedData = {
        Id: Id,
        Product_Category_Id: CategoryId || data.Product_Category_Id,
        Product_Category: CategoryName || data.Product_Category,
        Product_Name: Product_Name,
        Unit_Of_Measurement_Id: UnitsId || data.Unit_Of_Measurement_Id,
        Unit_Of_Measurement: UnitsName || data.Unit_Of_Measurement,
        Product_Price: Product_Price,
        Description: Description,
        Added_By_Id: userData.userId,
        Added_By: `${userData.firstName} ${userData.lastName}`,
      };

      await axios.put(`${apiUrl}/updateProduct/` + Id, updatedData);
      setData((prevData) =>
        prevData.map((data) =>
          data.Id === Id ? { ...data, ...updatedData } : data
        )
      );
      setDescription("");
      setProductPrice("");
      setProductName("");
      setselectedUnits(null);
      setselectedCategory(null);
      setId(null);
      setEditingItem(null);
      Load();
      Swal.fire("Success!", "Product updated successfully.", "success");
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Failed to Update. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  const deleteUser = (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id);
      }
    });
  };

  async function handleDelete(Id) {
    await axios.delete(`${apiUrl}/deleteProduct/${Id}`);
    Swal.fire("Deleted!", "Your record has been Deleted.", "success");
    Load();
  }

  const handleChangePage = (event, newPage) => {
    console.log("New Page:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("New Rows Per Page:", +event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Products", 20, 10);

    const columns = [
      "Product Category",
      "Product Name",
      "Unit Of Measurement",
      "Product Price",
    ];

    const rows = records.map((row) => [
      row.Product_Category,
      row.Product_Name,
      row.Unit_Of_Measurement,
      row.Product_Price,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Products.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Product Category",
      "Product Name",
      "Unit Of Measurement",
      "Product Price",
    ];
    const rows = [columns];
    records.forEach((row) => {
      rows.push([
        row.Product_Category,
        row.Product_Name,
        row.Unit_Of_Measurement,
        row.Product_Price,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Products");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Products.xlsx");
  };

  const [records, setRecords] = useState(data);

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (row.Product_Category &&
          row.Product_Category.toLowerCase().includes(filterValue)) ||
        (row.Product_Name &&
          row.Product_Name.toLowerCase().includes(filterValue)) ||
        (row.Unit_Of_Measurement &&
          row.Unit_Of_Measurement.toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  const handleCategoryChange = (selectedOption) => {
    if (selectedOption) {
      setCategoryName(selectedOption.label);
      setCategoryId(selectedOption.value);
    } else {
      setCategoryName(data.Product_Category);
      setCategoryId(data.Product_Category_Id);
    }
    setselectedCategory(selectedOption);
  };

  const handleUnitsChange = (selectedOption) => {
    if (selectedOption) {
      setUnitsName(selectedOption.label);
      setUnitsId(selectedOption.value);
    } else {
      setUnitsName(data.Unit_Of_Measurement);
      setUnitsId(data.Unit_Of_Measurement_Id);
    }
    setselectedUnits(selectedOption);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Add Product</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <Box height={10} />
            <Stack>
              <form
                className="form-horizontal"
                onSubmit={editingItem ? update : save}
              >
                <div className="row">
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="Product_Category">
                        Category*
                      </label>
                      <Select
                        type="text"
                        name="Product_Category"
                        required
                        id="Product_Category"
                        value={selectedCategory}
                        options={CategoryOption}
                        onChange={handleCategoryChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Overall_Monthly_Target"
                      >
                        Product Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Product_Name"
                        required
                        value={Product_Name}
                        onChange={(event) => {
                          setProductName(event.target.value);
                          setError("");
                        }}
                        id="Product_Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Unit_Of_Measurement"
                      >
                        Unit Of Measurement*
                      </label>
                      <Select
                        name="Unit_Of_Measurement"
                        value={selectedUnits}
                        onChange={handleUnitsChange}
                        options={UnitsOption}
                        id="Unit_Of_Measurement"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="Product_Price">
                        Product Price*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Product_Price"
                        required
                        value={Product_Price}
                        onChange={(event) => {
                          setProductPrice(event.target.value);
                          setError("");
                        }}
                        id="Product_Price"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="Description">
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="Description"
                        value={Description}
                        onChange={(event) => {
                          setDescription(event.target.value);
                          setError("");
                        }}
                        id="Description"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <div>
                      {editingItem ? (
                        <Button
                          variant="contained"
                          endIcon={<AddCircleIcon />}
                          type="submit"
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          endIcon={<AddCircleIcon />}
                          type="submit"
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </Stack>
            <hr style={{ borderWidth: "2px" }} />
            <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2" sx={{zIndex:0, position:"relative"}}>
              <TextField
                onChange={handleFilter}
                size="small"
                label="Search..."
              />
              <div>
                <Tooltip title="Export to PDF">
                  <IconButton
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      DownloadPDF();
                    }}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Export to Excel">
                  <IconButton
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      DownloadExcel();
                    }}
                  >
                    {" "}
                    <Icon path={mdiFileExcel} size={1} />
                  </IconButton>
                </Tooltip>
              </div>
            </Stack>
            <Box height={14} />

            <TableContainer className="" sx={{ maxHeight: "100%" , minHeight: "170px", zIndex:0, position:"relative" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Product Category
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Product Name
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Unit Of Measurement
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Product Price
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Description
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          style={{ height: "30px" }}
                        >
                          <TableCell align="left" style={{ padding: "5px" }}>
                            {row.Product_Category}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "5px" }}>
                            {row.Product_Name}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "5px" }}>
                            {row.Unit_Of_Measurement}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "5px" }}>
                            ₹{row.Product_Price}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "5px" }}>
                            {row.Description ? row.Description : "--"}
                          </TableCell>

                          <TableCell
                            className="text-cneter"
                            style={{ padding: "5px" }}
                          >
                            <IconButton
                              type="button"
                              className="btn shadow-none"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ padding: "0px", border: 0 }}
                            >
                              <i
                                className="ri-more-2-fill"
                                style={{ fontSize: "16px" }}
                              ></i>
                            </IconButton>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={() => editExpenses(row)}
                                >
                                  <i
                                    className="ri-edit-2-line"
                                    style={{
                                      color: "blue",
                                      fontSize: "16px",
                                    }}
                                  ></i>
                                  <span> Update</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => {
                                    deleteUser(row.Id);
                                  }}
                                  className="dropdown-item cursor-pointer"
                                >
                                  <i
                                    className="ri-delete-bin-3-line"
                                    style={{
                                      color: "red",
                                      fontSize: "16px",
                                    }}
                                  ></i>
                                  <span> Delete</span>
                                </Link>
                              </li>
                            </ul>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="mt-3"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={records.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};

export default AddProduct;
