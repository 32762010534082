import React from "react";
import Table from "./ClientDataTable.js";

const ViewClient = () => {

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">View Client</h4>
        </div>
        <div className="card">
          <div className="card-body">
            
            <Table/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewClient;
