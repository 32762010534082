import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../CSS/datatable.css";
import "datatables.net";
import "jspdf-autotable";
import { useParams } from "react-router-dom";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import "../../CSS/datatable.css";
import "datatables.net";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import Modal from "./adjustmentModal";

const PayRoll = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setAttendanceData] = useState([]);
  const [isDataCreated, setIsDataCreated] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [monthDate, setDate] = useState([]);
  const { Id } = useParams();
  const [holidays, setHolidays] = useState([]);
  const [latePunnches, setlatePunnches] = useState([]);
  const [PayRoll, setPayRoll] = useState([]);
  const [adjustments, setadjustments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [staff_Id, setStaff_Id] = useState();
  const [adjustmetDate, setAdjustmetDate] = useState();

  console.log(PayRoll)

  const [count, setCount] = useState({
    staff_Ids: "",
    Name: "",
    PayType: "",
    WeekOff: "",
    Total_Days: "",
    Less_Holidays: "",
    Less_Week_Offs: "",
    Working_Days: "",
    No_Of_Present_Days: "",
    No_Of_Absent_Days: "",
    No_Of_Late_Marks: "",
    No_Of_Half_Days: "",
    No_Of_Adjustments: "",
    No_Of_Leaves: "",
    Monthly_Fixed_Salary: "",
    Salary_per_Day: "",
    Absent_Salary: "",
    Late_Salary: "",
    Half_Day_Salary: "",
    Total_Deduction: "",
    Net_Salary: "",
    deduction: "",
    OT: "",
    Month: "",
    Year: "",
  });
  let Late_Mark_In = 0,
    Late_Mark_Out = 0,
    HalfDay_Mark_In = 0,
    HalfDay_Mark_Out = 0,
    Absentism_In = 0,
    Absentism_Out = 0,
    Latemark_Count = 0;

  //creating array of all the dates of the month
  function getDaysInMonth(month, year) {
    var date = new Date(year, month - 1, 1);
    var days = [];

    while (date.getMonth() === month - 1) {
      days.push({
        mDate: new Date(date).toLocaleDateString("en-GB"),
        Day: new Date(date).toLocaleDateString("en-GB", { weekday: "long" }),
      });
      date.setDate(date.getDate() + 1);
    }

    return days;
  }
  useEffect(() => {
    (async () => {
      try {
        showLoader();
        const result = await axios.get(
          `${apiUrl}/getAttendance1?id=${Id}&month=${currentMonth}&year=${currentYear}`
        ); //Fetching Biometric data;
        if (result.data === 0) {
          setAttendanceData([]);
        } else {
          setAttendanceData(result.data);
        }

        const holidaysResult = await axios.get(`${apiUrl}/getHolidays`);
        setHolidays(holidaysResult.data);

        const latePunnchesResult = await axios.get(
          `${apiUrl}/getLatemarkDetail`
        );
        setlatePunnches(latePunnchesResult.data);

        const adjustmentsResult = await axios.get(
          `${apiUrl}/getAdjustments?id=${Id}`
        );
        setadjustments(adjustmentsResult.data);

        setDate(getDaysInMonth(currentMonth, currentYear));
        if (!isDataCreated) {
          setIsDataCreated(true);
        }
        hideLoader();
      } catch (error) {
        hideLoader();
        setAttendanceData([]);
      }
    })();
  }, []);

  function convertedInTime(shiftInTime, minutes) {
    let time = new Date("2000-01-01 " + shiftInTime);
    time.setMinutes(time.getMinutes() + parseInt(minutes, 10));
    let updatedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return updatedTime;
  }

  function convertedOutTime(shiftOutTime, minutes) {
    let time = new Date("2000-01-01 " + shiftOutTime);
    time.setMinutes(time.getMinutes() - parseInt(minutes, 10));
    let updatedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return updatedTime;
  }

  function getWorkingHours(workedHours, punchIn, punchOut) {
    debugger 
    let tillNowTotalMinutes = 0;
    let punchInTime = new Date("2000-01-01 " + punchIn);
    let punchOutTime = new Date("2000-01-01 " + punchOut);

    // Calculate the difference in milliseconds
    let difference = punchOutTime.getTime() - punchInTime.getTime();

    // Convert milliseconds to hours and minutes
    let hours = Math.floor(difference / (1000 * 60 * 60));
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    if (workedHours !== 0) {
      let TotalworkedHours = workedHours.split(":");
      let tillNowHours = parseInt(TotalworkedHours[0]);
      let tillNowMinutes = parseInt(TotalworkedHours[1]);
      tillNowTotalMinutes = tillNowHours * 60 + tillNowMinutes;
    }
    let totalWorkingMinutes = hours * 60 + minutes + tillNowTotalMinutes;

    // Calculate new hours and minutes from the total working minutes
    let newHours = Math.floor(totalWorkingMinutes / 60);
    let newMinutes = totalWorkingMinutes % 60;

    let working = `${newHours}:${String(newMinutes).padStart(2, "0")}`;
    return working;
  }

  function mapping() {
    if (data.length !== 0) {
      var shiftInTime = new Date(data[0].PunchIn).toLocaleTimeString();
      var shiftOutTime = new Date(data[0].PunchOut).toLocaleTimeString();
      const staffSalary = parseFloat(data[0].Amount, 10) || 0;
      var ActualWorkHour = 0;
      if (data[0].LateHalf.toString() === "shifttime") {
        Late_Mark_In = convertedInTime(
          shiftInTime,
          latePunnches[0].Late_Mark_In
        );
        Late_Mark_Out = convertedOutTime(
          shiftOutTime,
          latePunnches[0].Late_Mark_Out
        );
        HalfDay_Mark_In = convertedInTime(
          shiftInTime,
          latePunnches[0].HalfDay_Mark_In
        );
        HalfDay_Mark_Out = convertedOutTime(
          shiftOutTime,
          latePunnches[0].HalfDay_Mark_Out
        );
        Absentism_In = convertedInTime(
          shiftInTime,
          latePunnches[0].Absentism_In
        );
        Absentism_Out = convertedOutTime(
          shiftOutTime,
          latePunnches[0].Absentism_Out
        );
        Latemark_Count = latePunnches[0].Late_Amt;
      } else if (data[0].LateHalf.toString() === "Total Working Hours") {
        ActualWorkHour = getWorkingHours(
          ActualWorkHour,
          shiftInTime,
          shiftOutTime
        );
        Late_Mark_In = latePunnches[0].Late_Mark_In;
        Late_Mark_Out = latePunnches[0].Late_Mark_Out;
        HalfDay_Mark_In = latePunnches[0].HalfDay_Mark_In;
        HalfDay_Mark_Out = latePunnches[0].HalfDay_Mark_Out;
        Absentism_In = latePunnches[0].Absentism_In;
        Absentism_Out = latePunnches[0].Absentism_Out;
        Latemark_Count = latePunnches[0].Late_Amt;
      }
      let punchStatus = "Present",
        currentStatus = "Present",
        punchRemark = "";
      let Presents = 0,
        Absents = 0,
        Lates = 0,
        HalfDays = 0,
        Weekoffs = 0,
        TotalHolidays = 0,
        TotalAdjustment = 0,
        leaves = 0;
      const allDate = monthDate.map((item) => {
        const attendance = data.find(
          (punchData) => punchData.Date === item.mDate
        );
        const [day, month, year] = item.mDate.split("/");

        if (attendance !== undefined) {
          debugger 
          let workingHr = 0;
          punchStatus = "Present";
          currentStatus = "Present";
          punchRemark = "--";
          //converting all the punches of the date into array
          const TimeLogArray = attendance.TimeLog.replace(/,\s*/g, ",").split(
            ","
          );
          //when punch count is odd number
          if (TimeLogArray.length % 2 !== 0) {
            punchStatus = "Absent";
            currentStatus = "Absent";
            if (TimeLogArray.length > 1) {
              var inTime = 0,
                outTime = 0;
              for (let i = 0; i <= TimeLogArray.length - 1; i++) {
                if ((i + 1) % 2 !== 0) {
                  inTime = TimeLogArray[i];
                } else {
                  outTime = TimeLogArray[i];
                }

                if (inTime !== 0 && outTime !== 0) {
                  workingHr = getWorkingHours(workingHr, inTime, outTime);
                  inTime = 0;
                  outTime = 0;
                }
              }
            }
            Absents++;
            const adjustment = adjustments.find(
              (a) => a.Adjustment_Date === item.mDate
            );
            if (adjustment) {
              //when date matches with adjustments
              TotalAdjustment++;
              punchStatus = adjustment.Adjustment;
              punchRemark = adjustment.Remark;
              if (currentStatus !== "Late" && punchStatus === "Late") {
                Lates++;
              } else if (
                currentStatus !== "Half Day" &&
                punchStatus === "Half Day"
              ) {
                HalfDays++;
              } else if (
                currentStatus !== "Absent" &&
                punchStatus === "Absent"
              ) {
                Absents++;
                Presents--;
              } else if (currentStatus !== "Leave" && punchStatus === "Leave") {
                Presents++;
                leaves++;
              } else if (
                currentStatus !== "Holiday" &&
                punchStatus === "Holiday"
              ) {
                TotalHolidays++;
              } else if (
                currentStatus !== "Week Off" &&
                punchStatus === "Week Off"
              ) {
                Weekoffs++;
              } else if (
                currentStatus === "Absent" &&
                punchStatus === "Present"
              ) {
                Presents++;
              }
              //adjusting count of status if adjustment is done
              if (currentStatus === "Absent") {
                Absents--;
              } else if (currentStatus === "Late") {
                Lates--;
              } else if (currentStatus === "Half Day") {
                HalfDays--;
              } else if (currentStatus === "Leave") {
                Presents--;
              } else if (currentStatus === "Holiday") {
                TotalHolidays--;
              } else if (currentStatus === "Week Off") {
                Weekoffs--;
              }
            }
          } else if (attendance.LateHalf.toString() === "Shift Timing") {
            if (punchStatus !== "Absent") {
              //checking In Time
              var punchTInime = TimeLogArray[0];
              if (
                punchTInime >= Late_Mark_In &&
                punchTInime < HalfDay_Mark_In
              ) {
                punchStatus = "Late";
                currentStatus = "Late";
                Lates++;
                Presents++;
              } else if (
                punchTInime >= HalfDay_Mark_In &&
                punchTInime < Absentism_In
              ) {
                punchStatus = "Half Day";
                currentStatus = "Half Day";
                HalfDays++;
                Presents++;
              } else if (punchTInime >= Absentism_In) {
                punchStatus = "Absent";
                currentStatus = "Absent";
                Absents++;
              } else {
                Presents++;
              }

              //checking Out Time
              var punchTOutime =
                TimeLogArray[TimeLogArray.length - 1].toString();
              if (punchStatus === "Present") {
                if (
                  punchTOutime <= Late_Mark_Out &&
                  punchTOutime > HalfDay_Mark_Out
                ) {
                  punchStatus = "Late";
                  currentStatus = "Late";
                  Lates++;
                } else if (
                  punchTOutime <= HalfDay_Mark_Out &&
                  punchTOutime > Absentism_Out
                ) {
                  punchStatus = "Half Day";
                  currentStatus = "Half Day";
                  HalfDays++;
                } else if (punchTOutime <= Absentism_Out) {
                  punchStatus = "Absent";
                  currentStatus = "Absent";
                  Absents++;
                  Presents--;
                }
              } else if (punchStatus === "Late") {
                if (
                  punchTOutime <= HalfDay_Mark_Out &&
                  punchTOutime > Absentism_Out
                ) {
                  punchStatus = "Half Day";
                  currentStatus = "Half Day";
                  HalfDays++;
                  Presents++;
                } else if (punchTOutime <= Absentism_Out) {
                  punchStatus = "Absent";
                  currentStatus = "Absent";
                  Absents++;
                  Presents--;
                }
              } else if (punchStatus === "Half Day") {
                if (punchTOutime <= Absentism_Out) {
                  punchStatus = "Absent";
                  currentStatus = "Absent";
                  Absents++;
                  Presents--;
                }
              }
              const adjustment = adjustments.find(
                (a) => a.Adjustment_Date === item.mDate
              );
              if (adjustment) {
                //when date matches with adjustments
                TotalAdjustment++;
                punchStatus = adjustment.Adjustment;
                punchRemark = adjustment.Remark;
                if (currentStatus !== "Late" && punchStatus === "Late") {
                  Lates++;
                } else if (
                  currentStatus !== "Half Day" &&
                  punchStatus === "Half Day"
                ) {
                  HalfDays++;
                } else if (
                  currentStatus !== "Absent" &&
                  punchStatus === "Absent"
                ) {
                  Absents++;
                  Presents--;
                } else if (
                  currentStatus !== "Leave" &&
                  punchStatus === "Leave"
                ) {
                  Presents++;
                  leaves++;
                } else if (
                  currentStatus !== "Holiday" &&
                  punchStatus === "Holiday"
                ) {
                  TotalHolidays++;
                } else if (
                  currentStatus !== "Week Off" &&
                  punchStatus === "Week Off"
                ) {
                  Weekoffs++;
                } else if (
                  currentStatus === "Absent" &&
                  punchStatus === "Present"
                ) {
                  Presents++;
                }
                //adjusting count of status if adjustment is done
                if (currentStatus === "Absent") {
                  Absents--;
                } else if (currentStatus === "Late") {
                  Lates--;
                } else if (currentStatus === "Half Day") {
                  HalfDays--;
                } else if (currentStatus === "Leave") {
                  Presents--;
                } else if (currentStatus === "Holiday") {
                  TotalHolidays--;
                } else if (currentStatus === "Week Off") {
                  Weekoffs--;
                }
              }
              debugger 
              var inTime = 0,
                outTime = 0;
              for (let i = 0; i <= TimeLogArray.length - 1; i++) {
                if ((i + 1) % 2 !== 0) {
                  inTime = TimeLogArray[i];
                } else {
                  outTime = TimeLogArray[i];
                }

                if (inTime !== 0 && outTime !== 0) {
                  workingHr = getWorkingHours(workingHr, inTime, outTime);
                  inTime = 0;
                  outTime = 0;
                }
              }
            }
          } else if (attendance.LateHalf.toString() === "Total Working Hours") {
            var inTime = 0,
              outTime = 0;
            for (let i = 0; i <= TimeLogArray.length - 1; i++) {
              if ((i + 1) % 2 !== 0) {
                inTime = TimeLogArray[i];
              } else {
                outTime = TimeLogArray[i];
              }

              if (inTime !== 0 && outTime !== 0) {
                workingHr = getWorkingHours(workingHr, inTime, outTime);
                inTime = 0;
                outTime = 0;
              }
            }
            let NewActualWorkHour = ActualWorkHour.replace(":", ".");
            let NewworkingHr = workingHr.replace(":", ".");
            let totalHours = parseFloat(NewActualWorkHour).toFixed(2);
            let totalWorkedHours = parseFloat(NewworkingHr);
            if (totalWorkedHours >= totalHours) {
              punchStatus = "Present";
              currentStatus = "Present";
              Presents++;
            } else {
              const [firstHours, firstMinutes] =
                ActualWorkHour.split(":").map(parseFloat);
              const [secondHours, secondMinutes] = workingHr
                .split(":")
                .map(parseFloat);

              const diffFirstHours = firstHours * 60 + firstMinutes;
              const diffrSecondHours = secondHours * 60 + secondMinutes;

              const totalMinutes = diffFirstHours - diffrSecondHours;
              if (
                totalMinutes >= Late_Mark_In &&
                totalMinutes < HalfDay_Mark_In
              ) {
                punchStatus = "Late";
                currentStatus = "Late";
                Presents++;
                Lates++;
              } else if (
                totalMinutes >= HalfDay_Mark_In &&
                totalMinutes < Absentism_In
              ) {
                punchStatus = "Half Day";
                currentStatus = "Half Day";
                HalfDays++;
                Presents++;
              } else if (totalMinutes > Absentism_In) {
                punchStatus = "Absent";
                currentStatus = "Absent";
                Absents++;
              } else {
                Presents++;
              }
            }
            const adjustment = adjustments.find(
              (a) => a.Adjustment_Date === item.mDate
            );
            if (adjustment) {
              //when date matches with adjustments
              TotalAdjustment++;
              punchStatus = adjustment.Adjustment;
              punchRemark = adjustment.Remark;
              if (currentStatus !== "Late" && punchStatus === "Late") {
                Lates++;
              } else if (
                currentStatus !== "Half Day" &&
                punchStatus === "Half Day"
              ) {
                HalfDays++;
              } else if (
                currentStatus !== "Absent" &&
                punchStatus === "Absent"
              ) {
                Absents++;
                Presents--;
              } else if (currentStatus !== "Leave" && punchStatus === "Leave") {
                Presents++;
                leaves++;
              } else if (
                currentStatus !== "Holiday" &&
                punchStatus === "Holiday"
              ) {
                TotalHolidays++;
              } else if (
                currentStatus !== "Week Off" &&
                punchStatus === "Week Off"
              ) {
                Weekoffs++;
              } else if (
                currentStatus === "Absent" &&
                punchStatus === "Present"
              ) {
                Presents++;
              }
              //adjusting count of status if adjustment is done
              if (currentStatus === "Absent") {
                Absents--;
              } else if (currentStatus === "Late") {
                Lates--;
              } else if (currentStatus === "Half Day") {
                HalfDays--;
              } else if (currentStatus === "Leave") {
                Presents--;
              } else if (currentStatus === "Holiday") {
                TotalHolidays--;
              } else if (currentStatus === "Week Off") {
                Weekoffs--;
              }
            }
          }
          return {
            staff_Id: data[0].staff_Id,
            UserId: attendance.UserId,
            Name: attendance.Name,
            PayType: attendance.PayType,
            Amount: attendance.Amount,
            Overtime: attendance.Overtime_Consideration,
            Deductions: attendance.Deductions,
            WeekOff: attendance.WeekOff,
            SinglePunch: attendance.SinglePunch,
            LateHalf: attendance.LateHalf,
            Date: item.mDate,
            Day: item.Day,
            TimeLog: attendance.TimeLog,
            Status: punchStatus,
            hours: workingHr,
            Remark: punchRemark,
          };
        }
        //when no entry found in biometric server for the date
        else {
          let workingHr = 0,
            punchRemark = "--";

          //checking the date is holiday
          const holiday = holidays.find((h) => h.Holiday_Date === item.mDate);

          //checking id day matches with weekoff
          if (data[0].WeekOff.toString() === item.Day.toString()) {
            punchStatus = "Week Off";
            currentStatus = "Week Off";
            Weekoffs++;
          } else if (holiday) {
            //when date matches with holiday
            punchStatus = "Holiday";
            currentStatus = "Holiday";
            punchRemark = holiday.Discription;
            TotalHolidays++;
          } else {
            punchStatus = "Absent";
            currentStatus = "Absent";
            Absents++;
          }
          const adjustment = adjustments.find(
            (a) => a.Adjustment_Date === item.mDate
          );
          if (adjustment) {
            //when date matches with adjustments
            TotalAdjustment++;
            punchStatus = adjustment.Adjustment;
            punchRemark = adjustment.Remark;
            if (currentStatus !== "Late" && punchStatus === "Late") {
              Lates++;
            } else if (
              currentStatus !== "Half Day" &&
              punchStatus === "Half Day"
            ) {
              HalfDays++;
            } else if (currentStatus !== "Absent" && punchStatus === "Absent") {
              Absents++;
              Presents--;
            } else if (currentStatus !== "Leave" && punchStatus === "Leave") {
              Presents++;
              leaves++;
            } else if (
              currentStatus !== "Holiday" &&
              punchStatus === "Holiday"
            ) {
              TotalHolidays++;
            } else if (
              currentStatus !== "Week Off" &&
              punchStatus === "Week Off"
            ) {
              Weekoffs++;
            } else if (
              currentStatus === "Absent" &&
              punchStatus === "Present"
            ) {
              Presents++;
            }
            //adjusting count of status if adjustment is done
            if (currentStatus === "Absent") {
              Absents--;
            } else if (currentStatus === "Late") {
              Lates--;
            } else if (currentStatus === "Half Day") {
              HalfDays--;
            } else if (currentStatus === "Leave") {
              Presents--;
            } else if (currentStatus === "Holiday") {
              TotalHolidays--;
            } else if (currentStatus === "Week Off") {
              Weekoffs--;
            }
          }
          return {
            staff_Id: data[0].staff_Id,
            UserId: data[0].UserId,
            Name: data[0].Name,
            PayType: data[0].PayType,
            Amount: data[0].Amount,
            Overtime: data[0].Overtime_Consideration,
            Deductions: data[0].Deductions,
            WeekOff: data[0].WeekOff,
            SinglePunch: data[0].SinglePunch,
            LateHalf: data[0].LateHalf,
            Date: item.mDate,
            Day: item.Day,
            TimeLog: "0",
            Status: punchStatus,
            hours: workingHr,
            Remark: punchRemark,
          };
        }
      });
      let totalDaysInMonth = getDaysInMonth(currentMonth, currentYear).length;
      let lessHolidays = parseInt(TotalHolidays, 10) || 0;
      let lessWeekOffs = parseInt(Weekoffs, 10) || 0;
      let totalDays = parseInt(totalDaysInMonth, 10) || 0;
      let workingDays = totalDays - (lessHolidays + lessWeekOffs);
      let Monthly_Fixed_Salary = 0;
      let Salary_per_Day = 0;
      let Absent_Salary = 0;
      let Late_Salary = 0;
      let Half_Day_Salary = 0;
      let Net_Salary = 0;
      let Total_Deduction = 0;
      if (data[0].PayType == "Fixed") {
        Monthly_Fixed_Salary = staffSalary.toFixed(2);
        Salary_per_Day = parseFloat(
          staffSalary / parseInt(totalDaysInMonth, 10) || 0
        ).toFixed(2);
        Absent_Salary = parseFloat(Absents * Salary_per_Day).toFixed(2);
        if (Latemark_Count > 0) {
          Late_Salary = parseFloat(
            Math.floor(Lates / Latemark_Count) * Salary_per_Day
          ).toFixed(2);
        }
        Half_Day_Salary = parseFloat((HalfDays * Salary_per_Day) / 2).toFixed(
          2
        );
        Net_Salary = parseFloat(
          Monthly_Fixed_Salary - Absent_Salary - Late_Salary - Half_Day_Salary
        ).toFixed(2);
        Total_Deduction = parseFloat(Monthly_Fixed_Salary - Net_Salary).toFixed(
          2
        );
      } else {
        const [firstHours, firstMinutes] =
          ActualWorkHour.split(":").map(parseFloat);
        const diffFirstHours = firstHours * 60 + firstMinutes; //total minutes of total working hour
        Salary_per_Day = parseFloat(
          (diffFirstHours / 60) * staffSalary
        ).toFixed(2); //converting minutes to hour to calculate per day salary
        Monthly_Fixed_Salary = parseFloat(
          Salary_per_Day * totalDaysInMonth
        ).toFixed(2);
        Absent_Salary = parseFloat(Absents * Salary_per_Day).toFixed(2);
        if (Latemark_Count > 0) {
          Late_Salary = parseFloat(
            Math.floor(Lates / Latemark_Count) * Salary_per_Day
          ).toFixed(2);
        }
        Half_Day_Salary = parseFloat((HalfDays * Salary_per_Day) / 2).toFixed(
          2
        );
        Net_Salary = parseFloat(
          Monthly_Fixed_Salary - Absent_Salary - Late_Salary - Half_Day_Salary
        ).toFixed(2);
        Total_Deduction = parseFloat(Monthly_Fixed_Salary - Net_Salary).toFixed(
          2
        );
      }
      setCount((prevCount) => ({
        ...prevCount,
        staff_Ids: data[0].staff_Id,
        Name: data[0].Name,
        PayType: data[0].PayType,
        WeekOff: data[0].WeekOff,
        Total_Days: totalDaysInMonth.toString(),
        Less_Holidays: TotalHolidays.toString(),
        Less_Week_Offs: Weekoffs.toString(),
        Working_Days: workingDays.toString(),
        No_Of_Present_Days: Presents.toString(),
        No_Of_Absent_Days: Absents.toString(),
        No_Of_Late_Marks: Lates.toString(),
        No_Of_Half_Days: HalfDays.toString(),
        No_Of_Adjustments: TotalAdjustment.toString(),
        No_Of_Leaves: leaves.toString(),
        Monthly_Fixed_Salary: Monthly_Fixed_Salary.toString(),
        Salary_per_Day: Salary_per_Day.toString(),
        Absent_Salary: Absent_Salary.toString(),
        Late_Salary: Late_Salary.toString(),
        Half_Day_Salary: Half_Day_Salary.toString(),
        Total_Deduction: Total_Deduction.toString(),
        Net_Salary: Net_Salary.toString(),
        deduction: data[0].Deductions,
        OT: data[0].Overtime,
        Month: currentMonth,
        Year: currentYear,
      }));
      return allDate;
    }
  }
  const getMonthName = (monthNumber) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return months[monthNumber - 1];
  };

  useEffect(() => {
    const selectedMonthName = getMonthName(currentMonth);
    setCount((prevCount) => ({
      ...prevCount,
      MonthName: selectedMonthName,
    }));
  }, [currentMonth]);

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }
  const monthChanged = (event) => {
    const month = parseInt(event.target.value);
    setCurrentMonth(month);
  };
  const yearChanged = (event) => {
    const year = parseInt(event.target.value);
    setCurrentYear(year);
  };
  const Filter = async () => {
    try {
      showLoader();
      const result = await axios.get(
        `${apiUrl}/getAttendance1?id=${Id}&month=${currentMonth}&year=${currentYear}`
      );
      if (result.data === 0) {
        setAttendanceData([]);
        hideLoader();
      } else {
        setAttendanceData(result.data);
        setDate(getDaysInMonth(currentMonth, currentYear));
        setIsDataCreated(true);
        hideLoader();
      }
    } catch (error) {
      hideLoader();
      setAttendanceData([]);
    }
  };

  useEffect(() => {
  
    if (data.length === 0) {
      setIsDataCreated(false);
    } else {
      if (isDataCreated) {
        showLoader();
        if (monthDate.length > 0) {
          setPayRoll(mapping());
        }
        hideLoader();
        setIsDataCreated(false);
      }
    }
  }, [isDataCreated]);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const renderTimeLog = (timeLog) => {
    let times = timeLog;
    if (timeLog !== "0") {
      times = timeLog.split(",");
      // Further logic with the split times array
    } else {
      times = timeLog;
    }
    if (times.length === 1) {
      return <pre>(In {times[0]})</pre>;
    } else if (times.length === 2) {
      return (
        <pre>
          (In {times[0]} Out{times[1]})
        </pre>
      );
    } else if (times.length > 2) {
      let formattedLogs = times
        .map((time, index) => {
          if (index % 2 === 0) {
            return `(In ${time}`;
          } else {
            return `Out ${time})`;
          }
        })
        .join(", ");
      formattedLogs = formattedLogs.replace("),", "),\n");
      return (
        <pre>
          {formattedLogs}
          {!formattedLogs.endsWith(")") && ")"}
        </pre>
      );
    }
  };

  const handlePunchStatusClick = (id, date) => {
    // Toggle the modal state when punchStatus is clicked

    setStaff_Id(id);
    setAdjustmetDate(date);
    setShowModal(!showModal);
  };
  const handleCloseModal = async () => {
    showLoader();
    const adjustmentsResult = await axios.get(
      `${apiUrl}/getAdjustments?id=${Id}`
    ); //Fetching Adjustments data;
    setadjustments(adjustmentsResult.data);
    setShowModal(false);
    setIsDataCreated(true);
    hideLoader();
  };

  const addCollective = () => {
    showLoader();
    axios
      .post(`${apiUrl}/addCollective`, count)
      .then((res) => {
        Swal.fire(
          "Success!",
          "Salary Detail Added to Collective Report.",
          "success"
        );

        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        hideLoader(); // Hide loader if there's an error
      });
  };
  return (
    <div>
      <div>
        <div className="row  mt-3">
          <div className="col-12">
            <div className="card">
              <h4 className="card-title" style={{ margin: "10px 0 0 10px" }}>
                Filters
              </h4>
              <div className="card-body" style={{ paddingTop: "5px" }}>
                <div className="row justify-content-center mt-2">
                  <div className="col-4">
                    <label htmlFor="example-select" className="form-label">
                      Month
                    </label>
                    <select
                      className="form-select"
                      id="example-select"
                      onChange={monthChanged}
                      defaultValue={currentMonth}
                    >
                      <option value={1}>January</option>
                      <option value={2}>February</option>
                      <option value={3}>March</option>
                      <option value={4}>April</option>
                      <option value={5}>May</option>
                      <option value={6}>June</option>
                      <option value={7}>July</option>
                      <option value={8}>August</option>
                      <option value={9}>September</option>
                      <option value={10}>October</option>
                      <option value={11}>November</option>
                      <option value={12}>December</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <label htmlFor="example-select" className="form-label">
                      Year
                    </label>
                    <select
                      className="form-select"
                      id="example-select"
                      onChange={yearChanged}
                      defaultValue={currentYear}
                    >
                      <option value={2022}>2022</option>
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                      <option value={2025}>2025</option>
                    </select>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-2">
                    <button
                      className="btn btn-primary"
                      onClick={Filter}
                      style={{ width: "100%" }}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className=" table-responsive">
                  <table
                    id="basic-datatable"
                    className="table table-striped dt-responsive nowrap w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">Sr. No.</th>
                        <th className="text-center">Date</th>
                        <th className="text-center">Day</th>
                        <th className="text-center">Worked Hours</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length !== 0 ? (
                        PayRoll.map((item, index) => {
                          const currentDate = new Date();
                          const [day, month, year] = item.Date.split("/");
                          const itemDate = new Date(`${year}-${month}-${day}`);
                          if (currentDate >= itemDate) {
                            return (
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-center">{item.Date}</td>
                                <td className="text-center">{item.Day}</td>
                                <td className="text-center">
                                  {item.TimeLog !== "0" ? (
                                    <LightTooltip
                                      title={renderTimeLog(item.TimeLog)}
                                    >
                                      <p style={{ cursor: "pointer" }}>
                                        {item.hours}
                                      </p>
                                    </LightTooltip>
                                  ) : (
                                    <p>{item.hours}</p>
                                  )}
                                </td>
                                <td className="text-center">
                                  <a
                                    id="punchStatus"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handlePunchStatusClick(
                                        item.staff_Id,
                                        item.Date
                                      );
                                    }}
                                    className={`badge rounded-pill ${
                                      item.Status === "Present"
                                        ? "bg-success-subtle text-success"
                                        : item.Status === "Absent"
                                        ? "bg-danger-subtle text-danger"
                                        : item.Status === "Week Off"
                                        ? "bg-dark-subtle text-dark"
                                        : item.Status === "Late"
                                        ? "bg-info-subtle text-info"
                                        : item.Status === "Half Day"
                                        ? "bg-warning-subtle text-warning"
                                        : "bg-primary-subtle text-primary"
                                    } rounded-pill`}
                                  >
                                    <LightTooltip title="Adjust Punch Status">
                                      {item.Status}
                                    </LightTooltip>
                                  </a>
                                </td>
                                <td className="text-center">{item.Remark}</td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })
                      ) : (
                        <tr>
                          <td className="text-center text-danger" colspan={6}>
                            No Data Found for Selected Month and Year
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {data.length !== 0 && (
                  <div>
                    <table className="tables productTable display dataTable table-bordered ">
                      <tbody>
                        <tr style={{ background: "#005A9B", color: "#fff" }}>
                          <td colspan="8">Attendance For -{count.MonthName}</td>
                        </tr>
                        <tr>
                          <th rowspan="2">
                            <strong>DAYS</strong>
                          </th>
                          <th>
                            <strong>Total Days</strong>
                          </th>

                          <th>
                            <strong>Less Holidays</strong>
                          </th>
                          <th>
                            <strong>Less Week Offs</strong>
                          </th>
                          <th style={{ display: "none" }}>
                            <strong>Adjustments</strong>
                          </th>
                          <th>
                            <strong>Working Days</strong>
                          </th>

                          <td
                            style={{
                              background: "#005a9b",
                              border: "2px solid #005a9b",
                              borderBottom: "none",
                              color: "#fff",
                              display: "none",
                            }}
                          >
                            <strong>Halfday/LateMark deductions</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>{count.Total_Days}</span>
                          </td>

                          <td>
                            <span>{count.Less_Holidays}</span>
                          </td>
                          <td>
                            <span>{count.Less_Week_Offs}</span>
                          </td>
                          <td style={{ display: "none" }}>
                            <span>0</span>
                          </td>
                          <td>
                            <span>{count.Working_Days}</span>
                          </td>
                          <td
                            style={{
                              border: "2px solid #005a9b",
                              borderTop: "none",
                              display: "none",
                            }}
                          >
                            <span>YES</span>
                          </td>
                        </tr>
                        <tr>
                          <th rowspan="2">
                            <strong>
                              DEDUCTIONS
                              <br />
                              FOR DAYS
                            </strong>
                          </th>
                          <th>
                            <strong>No Of Present Days</strong>
                          </th>
                          <th>
                            <strong>No Of Absent Days</strong>
                          </th>
                          <th>
                            <strong>No Of Late Marks</strong>
                          </th>
                          <th>
                            <strong>No Of Half Days</strong>
                          </th>
                          <th style={{ display: "none" }}>
                            <strong>Leave</strong>
                          </th>
                          <td
                            style={{
                              background: "#005a9b",
                              border: "2px solid #005a9b",
                              borderBottom: "none",
                              color: "#fff",
                              display: "none",
                            }}
                          >
                            <strong>Total Present (including L.M.,H.D.)</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span id="ContentPlaceHolder1_lblPresentCount">
                              {count.No_Of_Present_Days}
                            </span>
                          </td>
                          <td>
                            <span id="ContentPlaceHolder1_lblabsentCount">
                              {count.No_Of_Absent_Days}
                            </span>
                          </td>
                          <td>
                            <span id="ContentPlaceHolder1_lblLatemarkCount">
                              {count.No_Of_Late_Marks}
                            </span>
                          </td>
                          <td>
                            <span id="ContentPlaceHolder1_lblHalfdaysCount">
                              {count.No_Of_Half_Days}
                            </span>
                          </td>
                          <td style={{ display: "none" }}>
                            <span id="ContentPlaceHolder1_lblLeaveCount">
                              0
                            </span>
                          </td>
                          <td
                            style={{
                              border: "2px solid #005a9b",
                              borderTop: "none",
                              display: "none",
                            }}
                          >
                            <span id="ContentPlaceHolder1_lblTotalPresentCount"></span>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="1">
                            <strong>DEDUCTION AMT</strong>
                          </td>
                          <td>--</td>
                          <td>
                            <span id="ContentPlaceHolder1_lblAbsentDeduction">
                              {count.Absent_Salary}
                            </span>
                          </td>
                          <td>
                            <span id="ContentPlaceHolder1_lblLateMarkDeduction">
                              {count.Late_Salary}
                            </span>
                            <input
                              type="hidden"
                              name="ctl00$ContentPlaceHolder1$hfLatemarkDeductionAmt"
                              id="ContentPlaceHolder1_hfLatemarkDeductionAmt"
                              value="3.00"
                            />
                          </td>
                          <td>
                            <span id="ContentPlaceHolder1_lblHalfDayDeduction">
                              {count.Half_Day_Salary}
                            </span>
                          </td>
                          <td style={{ display: "none" }}>-</td>
                          <td
                            style={{
                              border: "2px solid #005a9b",
                              borderTop: "none",
                              display: "none",
                            }}
                          >
                            <strong>OT (hh:mm)</strong>
                            <span id="ContentPlaceHolder1_lblOverTime">
                              3:51
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="tables productTable display dataTable table-bordered ">
                      <tbody>
                        <tr id="ContentPlaceHolder1_trmonthsal">
                          <td style={{ width: "100%" }}>
                            Monthly Fixed Salary
                          </td>
                          <td>
                            <span
                              id="ContentPlaceHolder1_lblFixedSal"
                              style={{ fontWeight: "bold" }}
                            >
                              {count.Monthly_Fixed_Salary}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "100%" }}>
                            <span id="ContentPlaceHolder1_lblPayper">
                              Salary per Day
                            </span>
                          </td>
                          <td>
                            <span
                              id="ContentPlaceHolder1_lblDailySal"
                              style={{ fontWeight: "bold" }}
                            >
                              {count.Salary_per_Day}
                            </span>
                          </td>
                        </tr>
                        <tr style={{ display: "none" }}>
                          <td style={{ width: "100%" }}>Gross Salary</td>
                          <td>
                            <span
                              id="ContentPlaceHolder1_lblGrossSal"
                              style={{ fontWeight: "bold" }}
                            >
                              4400.00
                            </span>
                            <input
                              type="hidden"
                              name="ctl00$ContentPlaceHolder1$hfGrossSal"
                              id="ContentPlaceHolder1_hfGrossSal"
                              value="102.85"
                            />
                          </td>
                        </tr>
                        <tr style={{ width: "100%", display: "none" }}>
                          <td style={{ width: "100%" }}>
                            <span class="aspNetDisabled">
                              <input
                                id="ContentPlaceHolder1_chkOT"
                                type="checkbox"
                                name="ctl00$ContentPlaceHolder1$chkOT"
                                disabled="disabled"
                                onclick="javascript:setTimeout('__doPostBack(\'ctl00$ContentPlaceHolder1$chkOT\',\'\')', 0)"
                              />
                              <label for="ContentPlaceHolder1_chkOT">
                                OT (Over Time)
                              </label>
                            </span>
                          </td>
                          <td>
                            <span
                              id="ContentPlaceHolder1_lblOTSal"
                              style={{ fontWeight: "bold" }}
                            >
                              00.00
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            borderTop: "1px solid #8c8686",
                            display: "none",
                          }}
                        >
                          <td style={{ width: "100%" }}>
                            <span class="aspNetDisabled">
                              <input
                                id="ContentPlaceHolder1_chkDeduction"
                                type="checkbox"
                                name="ctl00$ContentPlaceHolder1$chkDeduction"
                                checked="checked"
                                disabled="disabled"
                                onclick="javascript:setTimeout('__doPostBack(\'ctl00$ContentPlaceHolder1$chkDeduction\',\'\')', 0)"
                              />
                              <label for="ContentPlaceHolder1_chkDeduction">
                                Total Deduction
                              </label>
                            </span>
                          </td>
                          <td>
                            <span
                              id="ContentPlaceHolder1_lblTotalDeduction"
                              style={{ fontWeight: "bold" }}
                            >
                              00.00
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            background: "#005A9B",
                            color: "#fff",
                          }}
                        >
                          <td style={{ width: "100%" }}>
                            Net Salary (After deduction)
                          </td>
                          <td>
                            <span
                              id="ContentPlaceHolder1_lblFinalSalary"
                              style={{ fontWeight: "bold" }}
                            >
                              {count.Net_Salary}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <center>
                <button
                  className="btn btn-primary mb-3"
                  onClick={addCollective}
                >
                  Add to Collective
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          showModal={showModal}
          onClose={handleCloseModal}
          id={staff_Id}
          date={adjustmetDate}
        />
      )}
      {loader}
    </div>
  );
};
export default PayRoll;
