import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../CSS/datatable.css";
import "datatables.net";
import "jspdf-autotable";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import $ from "jquery";
import "../../CSS/datatable.css";
import "datatables.net";

const Getdata = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setAttendanceData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isFilterd, setisFilterd] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [staffId, setstaffId] = useState("-1");
  const staffLoad = async () => {
    showLoader();
    const staffResult = await axios.get(`${apiUrl}/bindStaffDrop`);
    hideLoader();
    return staffResult;
  };

  const Load = async () => {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/biometricReport?id=${staffId}&month=${currentMonth}&year=${currentYear}`
    );
    hideLoader();
    return result;
  };

  const Filter = async () => {
    const table = $("#basic-datatable").DataTable();
    if (table !== null && table !== undefined) {
      table.destroy();
    }
    const result = await Load();
    if (result.data === 0) {
      setAttendanceData([]);
      hideLoader();
    } else {
      setAttendanceData(result.data);
      setisFilterd(true);
      hideLoader();
    }
  };
  useEffect(() => {
    (async () => {
      const staffResult = await staffLoad();
      setStaffData(staffResult.data);
      const result = await Load();
      if (result.data === 0) {
        setAttendanceData([]);
      } else {
        setAttendanceData(result.data);
        setIsDataLoaded(true);
      }
    })();
  }, []);

  useEffect(() => {
  
    if (isDataLoaded) {
      const table = $("#basic-datatable");
      table.DataTable({
        columnDefs: [
          {
            // targets: -1,
            // orderable: false,
          },
        ],
      });
    }
  }, [isDataLoaded, setAttendanceData]);
  useEffect(() => {
    if (isFilterd) {
      console.log(isFilterd);
      const table = $("#basic-datatable");
      table.DataTable({
        columnDefs: [
          {
            // targets: -1,
            // orderable: false,
          },
        ],
      });
      setisFilterd(false);
    }
  }, [isFilterd]);
  const renderTimeLog = (timeLog) => {
    const times = timeLog.split(",");
    if (times.length === 1) {
      return <pre>(In {times[0]})</pre>;
    } else if (times.length === 2) {
      return (
        <pre>
          (In {times[0]} Out{times[1]})
        </pre>
      );
    } else if (times.length > 2) {
      let formattedLogs = times
        .map((time, index) => {
          if (index % 2 === 0) {
            return `(In ${time}`;
          } else {
            return `Out ${time})`;
          }
        })
        .join(", ");
      formattedLogs = formattedLogs.replace("),", "),\n");
      return (
        <pre>
          {formattedLogs}
          {!formattedLogs.endsWith(")") && ")"}
        </pre>
      );
    }
  };
  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }
  const monthChanged = (event) => {
    const month = parseInt(event.target.value);
    setCurrentMonth(month);
  };
  const yearChanged = (event) => {
    const year = parseInt(event.target.value);
    setCurrentYear(year);
  };
  const SelectedStaff = (event) => {
    const id = parseInt(event.target.value);
    setstaffId(id);
  };
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box justify-content-between d-flex align-items-md-center flex-md-row flex-column">
              <h4 className="page-title">View Staff Attendance</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <h4 className="card-title" style={{ margin: "10px 0 0 10px" }}>
                Filters
              </h4>
              <div className="card-body" style={{ paddingTop: "5px" }}>
                <div className="row justify-content-center mt-2">
                  <div className="col-4">
                    <label htmlFor="example-select" className="form-label">
                      Staff
                    </label>
                    <select
                      className="form-select"
                      id="example-select"
                      onChange={SelectedStaff}
                    >
                      <option value={-1}>All</option>
                      {staffData.map((staff, index) => (
                        <option key={index} value={staff.UUID}>
                          {staff.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-4">
                    <label htmlFor="example-select" className="form-label">
                      Month
                    </label>
                    <select
                      className="form-select"
                      id="example-select"
                      onChange={monthChanged}
                      defaultValue={currentMonth}
                    >
                      <option value={1}>January</option>
                      <option value={2}>February</option>
                      <option value={3}>March</option>
                      <option value={4}>April</option>
                      <option value={5}>May</option>
                      <option value={6}>June</option>
                      <option value={7}>July</option>
                      <option value={8}>August</option>
                      <option value={9}>September</option>
                      <option value={10}>October</option>
                      <option value={11}>November</option>
                      <option value={12}>December</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <label htmlFor="example-select" className="form-label">
                      Year
                    </label>
                    <select
                      className="form-select"
                      id="example-select"
                      onChange={yearChanged}
                      defaultValue={currentYear}
                    >
                      <option value={2022}>2022</option>
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                      <option value={2025}>2025</option>
                    </select>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-2">
                    <button
                      className="btn btn-primary"
                      onClick={Filter}
                      style={{ width: "100%" }}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className=" table-responsive">
                  <table
                    id="basic-datatable"
                    className="table table-striped dt-responsive nowrap w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">Sr. No.</th>
                        <th className="text-center">Biometric Id</th>
                        <th className="text-center">Staff Name</th>
                        <th className="text-center">Date</th>
                        <th className="text-center">Time Log</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{item.UserId}</td>

                          <td className="text-center">{item.Name}</td>
                          <td className="text-center">
                            {formatDate(item.Date)}
                          </td>
                          <td className="text-center">
                            {renderTimeLog(item.TimeLog)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};
export default Getdata;
