import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";

const Getdata = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { Id } = useParams();
  const [data, setAttendanceData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [staffId, setstaffId] = useState();

  useEffect(() => {
    if (Id) {
      showLoader();
      axios
        .get(`${apiUrl}/getStaff/${Id}`)
        .then((res) => {
          const uuid = res.data.UUID;
          setstaffId(uuid);
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          console.error(error);
        });
    }
  }, [Id]);
  
  const [isLoading, setIsLoading] = useState(false); 

  useEffect(() => {
    if (staffId) {
      (async () => {
        try {
            showLoader();
          setIsLoading(true); // Set loading state to true
          const result = await axios.get(
            `${apiUrl}/getAttendance?id=${staffId}&month=${currentMonth}&year=${currentYear}`
          );
          if (result.data.length === 0) {
            setAttendanceData([]);
        
          } else {
            setAttendanceData(result.data);
    
          }
          setIsLoading(false); 
          hideLoader();
        } catch (error) {
          setIsLoading(false); 
          hideLoader();
          console.error(error);
        }
      })();
    } else {
      setAttendanceData([]); 
      setIsLoading(false);
      hideLoader();
    }
  }, [staffId, currentMonth, currentYear]);
  

  const monthChanged = (event) => {
    const month = parseInt(event.target.value);
    setCurrentMonth(month);
  };

  const yearChanged = (event) => {
    const year = parseInt(event.target.value);
    setCurrentYear(year);
  };


  const columns = [
    {
      name: "Day",
      selector: (row) =>
        new Date(row.Date).toLocaleDateString("en-GB", { weekday: "long" }),
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.Date),
      sortable: true,
      center: true,
      // width: "120px",
    },
    {
      name: "Time Log",
      cell: (row) => renderTimeLog(row.TimeLog),
      sortable: true,
      center: true,
    },
  ];

  const renderTimeLog = (timeLog) => {
    const times = timeLog.split(",");
    if (times.length === 1) {
      return <pre>(In {times[0]})</pre>;
    } else if (times.length === 2) {
      return (
        <pre>
          (In {times[0]} Out{times[1]})
        </pre>
      );
    } else if (times.length > 2) {
      let formattedLogs = times
        .map((time, index) => {
          if (index % 2 === 0) {
            return `(In ${time}`;
          } else {
            return `Out ${time})`;
          }
        })
        .join(", ");
      formattedLogs = formattedLogs.replace("),", "),\n");
      return (
        <pre>
          {formattedLogs}
          {!formattedLogs.endsWith(")") && ")"}
        </pre>
      );
    }
  };
  function formatDate(dateString) {
  
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }

  return (
    <div>
     
      <div className="card-body" style={{ paddingTop: "5px" }}>
        <div className="row justify-content-center mt-2">
     
          <div className="col-4">
            <label htmlFor="example-select" className="form-label">
              Month
            </label>
            <select
              className="form-select"
              id="example-select"
              onChange={monthChanged}
              defaultValue={currentMonth}
            >
              <option value={1}>January</option>
              <option value={2}>February</option>
              <option value={3}>March</option>
              <option value={4}>April</option>
              <option value={5}>May</option>
              <option value={6}>June</option>
              <option value={7}>July</option>
              <option value={8}>August</option>
              <option value={9}>September</option>
              <option value={10}>October</option>
              <option value={11}>November</option>
              <option value={12}>December</option>
            </select>
          </div>
          <div className="col-4">
            <label htmlFor="example-select" className="form-label">
              Year
            </label>
            <select
              className="form-select"
              id="example-select"
              onChange={yearChanged}
              defaultValue={currentYear}
            >
              <option value={2022}>2022</option>
              <option value={2023}>2023</option>
              <option value={2024}>2024</option>
              <option value={2025}>2025</option>
            </select>
          </div>
        </div>
      </div>

      <div className="table-responsive">
  {isLoading ? (
    <p className="text-center"> No data available for the selected month and year.</p>
  ) : (
    <React.Fragment>
      {data.length === 0 ? (
        <p className="text-center">
          No data available for the selected month and year.
        </p>
      ) : (
        <DataTable
          columns={columns}
          data={data}
          pagination
        />
      )}
    </React.Fragment>
  )}
</div>
      {loader}
    </div>
  );
};
export default Getdata;
