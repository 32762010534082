import { useState, useEffect } from "react";
import axios from "axios";
import "../../CSS/datatable.css";
import "datatables.net";
import "jspdf-autotable";
import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import dayjs from "dayjs";

const IncomeExpense = () => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [data, setData] = useState([]);
    const [expData, setExpData] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [filter, setFilter] = useState({
        FromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        ToDate: dayjs().endOf("month").format("YYYY-MM-DD")
    });
    async function Load() {
        showLoader();
        const result = await axios.get(`${apiUrl}/getIncomeExpenses?fromdate=${filter.FromDate}&todate=${filter.ToDate}`);
        if (result.data.length === 0) {
            setData([]);
            hideLoader();
        } else {
            setData(result.data);
            hideLoader();
        }
        return result;
    }
    async function innerDataLoad(date) {
        debugger
        showLoader();
        const expResult = await axios.get(`${apiUrl}/getAllExpenses?date=${date}`);
        if (expResult.data.length === 0) {
            setExpData([]);
        } else {
            setExpData(expResult.data);
        }

        const OrderResult = await axios.get(`${apiUrl}/getAllIncomes?date=${date}`);
        if (OrderResult.data.length === 0) {
            setOrderData([]);
        } else {
            setOrderData(OrderResult.data);
        }

        hideLoader();
    }
    useEffect(() => {
        if (userData) {
            Load();
        }
    }, [userData]);
    const FilterClick = () => {
        Load();
        innerTable("");
    }
    const [expanded, setExpanded] = useState(null);
    const innerTable = (date) => {

        if (expanded === date) {
            
            setExpanded(null);
            // If the clicked item is already expanded, collapse it
        } else {
            // Expand the clicked item and collapse the previously expanded item
            setExpanded(date);
            innerDataLoad(date);
        }
    };
    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
                    <h4 className="page-title">Income Expenses Report</h4>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row justify-content-center mt-2">
                            <div className="col-6">
                                <label htmlFor="example-select" className="form-label">
                                    From Date
                                </label>
                                <input type="Date" id="txFromtDate" value={filter.FromDate} name="txFromtDate" className="form-control" placeholder="Date"
                                    onChange={(e) => {
                                        setFilter({
                                            ...filter,
                                            FromDate: e.target.value,
                                        })
                                    }} />
                            </div>
                            <div className="col-6">
                                <label htmlFor="example-select" className="form-label">
                                    To Date
                                </label>
                                <input type="Date" id="txtToDate" value={filter.ToDate} name="txtToDate" className="form-control" placeholder="Date"
                                    onChange={(e) => {
                                        setFilter({
                                            ...filter,
                                            ToDate: e.target.value,
                                        })
                                    }} />
                            </div>
                        </div>
                        <div className="row justify-content-center mt-2">
                            <div className="col-2">
                                <button className="btn btn-primary" onClick={FilterClick} style={{ width: "100%" }}> Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className=" table-responsive" style={{ minHeight: "220px" }}>
                            <table id="basic-datatable" className="table table-striped dt-responsive nowrap w-100">
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ padding: "10px 5px" }}>Sr. No.</th>
                                        <th className="text-center" style={{ padding: "10px 5px" }}>Date</th>
                                        <th className="text-center" style={{ padding: "10px 5px" }}>Income</th>
                                        <th className="text-center" style={{ padding: "10px 5px" }}>Expense</th>
                                        <th className="text-center" style={{ padding: "10px 5px" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.length > 0 ? (
                                        data.map((item, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{index + 1}</td>
                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{new Date(item.Date).toLocaleDateString("en-GB")}</td>
                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{item.Income}</td>
                                                    <td className="text-center" style={{ padding: "10px 5px" }}>{item.Expense}</td>
                                                    <td className="text-center" style={{ padding: "10px 5px" }}>
                                                        <a
                                                            class="text-dark"
                                                            data-bs-toggle="collapse"
                                                            href={`#todayTasks${index}`}
                                                            onClick={() => innerTable(dayjs(item.Date).format("DD/MM/YYYY"))}
                                                            role="button"
                                                            aria-expanded={expanded === dayjs(item.Date).format("DD/MM/YYYY")}
                                                            aria-controls={`todayTasks${index}`}
                                                        >
                                                            <i class={expanded === dayjs(item.Date).format("DD/MM/YYYY") ? "ri-indeterminate-circle-fill" : "ri-add-circle-fill"}></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr class={expanded === dayjs(item.Date).format("DD/MM/YYYY") ? "collapse show" : "collapse"} id={`todayTasks${index}`}>
                                                    <td colSpan={6}>

                                                        <h4 style={{ marginTop: "10px" }}>Income</h4>
                                                        <table id="basic-datatable" className="table table-bordered dt-responsive nowrap w-100">
                                                            <thead className="table-dark">
                                                                <tr>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Sr. No.</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Order_No</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Client</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Order Date</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Amount</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Payment Detail</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {orderData && orderData.length > 0 ? (
                                                                    orderData.map((item, index) => (
                                                                        <tr>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{index + 1}</td>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Order_No}</td>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Client}</td>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{new Date(item.Order_Date).toLocaleDateString("en-GB")}</td>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Amount}</td>
                                                                            <td className="text-right" style={{ padding: "10px 5px" }}>
                                                                                <b>Payment Date :</b> {new Date(item.Payment_Date).toLocaleDateString("en-GB")} <br />
                                                                                <b>Payment Mode :</b> {item.Payment_Mode}<br />
                                                                                {item.Payment_Mode === 'Cheque' && (
                                                                                    <>
                                                                                        <b>Bank :</b>{item.Bank},
                                                                                        <b>Cheque No :</b>{item.Cheque_No},
                                                                                        <b>Cheque Date :</b>{new Date(item.Cheque_Date).toLocaleDateString("en-GB")},
                                                                                    </>
                                                                                )}
                                                                                {item.Payment_Mode === 'Online' && (
                                                                                    <>
                                                                                        <b>Reference No :</b> {item.Reference_No}
                                                                                    </>
                                                                                )}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr >
                                                                        <td className="text-center text-danger"
                                                                            colSpan={7}>No Records Found!!</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>

                                                        <h4>Expenses</h4>
                                                        <table id="basic-datatable" className="table table-bordered dt-responsive nowrap w-100">
                                                            <thead className="table-dark">
                                                                <tr>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Sr. No.</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Bill No</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Bill Date</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Category</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Supplier</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Title</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Amount</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px", width:"300px" }}>Payment Detail</th>
                                                                    <th className="text-center" style={{ padding: "10px 5px" }}>Desciption</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {expData && expData.length > 0 ? (
                                                                    expData.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{index + 1}</td>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Bill_No}</td>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{new Date(item.Bill_Date).toLocaleDateString("en-GB")}</td>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Category}</td>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Supplier}</td>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Title}</td>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Amount}</td>
                                                                            <td className="text-right" style={{ padding: "10px 5px" }}>
                                                                                <b>Payment Date :</b> {new Date(item.Payment_Date).toLocaleDateString("en-GB")} <br />
                                                                                <b>Payment Mode :</b> {item.Payment_Mode}<br />
                                                                                {item.Payment_Mode === 'Cheque' && (
                                                                                    <>
                                                                                        <b>Bank :</b>{item.Bank},
                                                                                        <b>Cheque No :</b>{item.Cheque_No},
                                                                                        <b>Cheque Date :</b>{new Date(item.Cheque_Date).toLocaleDateString("en-GB")},
                                                                                    </>
                                                                                )}
                                                                                {item.Payment_Mode === 'Online' && (
                                                                                    <>
                                                                                        <b>Reference No :</b> {item.Reference_No}
                                                                                    </>
                                                                                )}</td>
                                                                            <td className="text-center" style={{ padding: "10px 5px" }}>{item.Remarks}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr >
                                                                        <td className="text-center text-danger"
                                                                            colSpan={9}>No Records Found!!</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                    ) : (
                                        <tr >
                                            <td className="text-center text-danger"
                                                colSpan={5}>No Records Found!!</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table >
                        </div>
                    </div>
                </div>
                {loader}
            </div>
        </div>
    )
}
export default IncomeExpense