import React from "react";
import ProformaTableTd from "./ProformaTableTd";

const AddproformaProduct = (props) => {
  const handleRemove = (productId) => {
    props.onRemove(productId);
  };

  return (
    <tbody>
      {props.productList.map((productDetail, index) => (
        <ProformaTableTd
          key={productDetail.id}
          name={productDetail.name}
          price={productDetail.price}
          quantity={productDetail.quantity}
          total={productDetail.total}
          productId={productDetail.id}
          onRemove={() => handleRemove(index)}
        ></ProformaTableTd>
      ))}
    </tbody>
  );
};
export default AddproformaProduct;
