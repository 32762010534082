import * as React from "react";
import * as XLSX from "xlsx";
import Table from "@mui/material/Table";
import { Link } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";
import useFullPageLoader from "../../mui/useFullPageLoader";
import dayjs from "dayjs";

const PrintData = () => {
  const [page, setPage] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [fromDate, setFromDate] = useState(dayjs().startOf("month"));
  const [toDate, setToDate] = useState(dayjs().endOf("month"));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [Sr_No, setSrNo] = useState(null);
  const [Particulars, setParticulars] = useState("");
  const [Size, setSize] = useState("");
  const [Date, setDate] = useState("");
  const [data, setData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [error, setError] = useState("");
  const { userData } = useUser();
  const [Quantity, setQuantity] = useState("");
  const [Quality, setQuality] = useState("");

  useEffect(() => {
    (async () => {
      const result = await Load();
      setData(result.data);
    })();
  }, []);

  async function Load() {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getPrintData?&fromDate=${fromDate.format(
        "YYYY-MM-DD"
      )}&toDate=${toDate.format("YYYY-MM-DD")}`
    );
    const sortedData = result.data;
    setData(sortedData);
    setRecords(sortedData);
    hideLoader();
    return result;
  }

  const handleDateRangeFilter = async () => {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getPrintData?&fromDate=${fromDate.format(
        "YYYY-MM-DD"
      )}&toDate=${toDate.format("YYYY-MM-DD")}`
    );
    const sortedData = result.data;
    setData(sortedData);
    setRecords(sortedData);
    hideLoader();
  };

  async function save(event) {
    event.preventDefault();

    const error = {};

    if (Object.keys(error).length === 0) {
      try {
        showLoader();
        await axios.post(`${apiUrl}/addPrintData`, {
          Date: Date,
          Particulars: Particulars,
          Size: Size,
          Quantity: Quantity,
          Quality: Quality,
          Added_By_Id: userData.userId,
          Added_By: `${userData.firstName} ${userData.lastName}`,
        });
        Swal.fire("Success!", "Data has been Added.", "success");
        setError("");
        setDate("");
        setSize("");
        setParticulars("");
        setQuality("");
        setQuantity("");
        setSrNo(null);
        Load();
        hideLoader();
      } catch (err) {
        Swal.fire({
          title: "Error",
          text: "Failed to add Data. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        hideLoader();
      }
    } else {
      setError(error);
    }
  }

  async function editExpenses(data) {
    showLoader();
    setQuantity(data.Quantity);
    setQuality(data.Quality);
    setDate(data.Date);
    setSize(data.Size);
    setParticulars(data.Particulars);
    setSrNo(data.Sr_No);
    setEditingItem(data);
    hideLoader();
  }

  async function update(event) {
    event.preventDefault();

    try {
      const updatedData = {
        Sr_No: Sr_No,
        Date: Date,
        Particulars: Particulars,
        Size: Size,
        Quantity: Quantity,
        Quality: Quality,
        Added_By_Id: userData.userId,
        Added_By: `${userData.firstName} ${userData.lastName}`,
      };
      showLoader();
      await axios.put(`${apiUrl}/updatePrintData/` + Sr_No, updatedData);
      setData((prevData) =>
        prevData.map((data) =>
          data.Sr_No === Sr_No ? { ...data, ...updatedData } : data
        )
      );
      setDate("");
      setSize("");
      setParticulars("");
      setQuality("");
      setQuantity("");
      setSrNo(null);
      setEditingItem(null);
      Load();
      hideLoader();
      Swal.fire("Success!", "Data updated successfully.", "success");
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Failed to Update. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
      hideLoader();
    }
  }

  const deleteUser = (Sr_No) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No);
      }
    });
  };

  async function handleDelete(Sr_No) {
    showLoader();
    await axios.delete(`${apiUrl}/deletePrintData/${Sr_No}`);
    Swal.fire("Deleted!", "Your data has been Deleted.", "success");
    Load();
    hideLoader();
  }

  const handleChangePage = (event, newPage) => {
    console.log("New Page:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("New Rows Per Page:", +event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Data", 20, 10);

    const columns = ["Date", "Particulars", "Size", "Quantity", "Quality"];

    const rows = records.map((row) => [
      formatDate(row.Date),
      row.Particulars,
      row.Size,
      row.Quantity,
      row.Quality,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("PrintData.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Date", "Particulars", "Size", "Quantity", "Quality"];
    const rows = [columns];
    records.forEach((row) => {
      rows.push([
        formatDate(row.Date),
        row.Particulars,
        row.Size,
        row.Quantity,
        row.Quality,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Data");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "PrintData.xlsx");
  };

  const [records, setRecords] = useState(data);

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (formatDate(row.Date) &&
          formatDate(row.Date).toLowerCase().includes(filterValue)) ||
        (row.Particulars &&
          row.Particulars.toLowerCase().includes(filterValue)) ||
        (row.Size && row.Size.toLowerCase().includes(filterValue)) ||
        (row.Quantity &&
          row.Quantity.toString().toLowerCase().includes(filterValue)) ||
        (row.Quality && row.Quality.toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  const formatDate = (dateString) => {
    const date = dayjs(dateString);
    const formattedDate = date.format("DD/MM/YYYY");
    return formattedDate;
  };

  function formatInputDate(dateString) {
    if (!dateString) {
      return "";
    }

    const date = dayjs(dateString);

    const formattedDate = date.format("YYYY-MM-DD");
    return formattedDate;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Print Data</h4>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <Stack>
                  <form
                    className="form-horizontal"
                    onSubmit={editingItem ? update : save}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Date">
                            Date*
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="Date"
                            required
                            value={formatInputDate(Date)}
                            onChange={(event) => {
                              setDate(event.target.value);
                              setError("");
                            }}
                            id="Date"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Particulars">
                            Particulars*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="Particulars"
                            required
                            value={Particulars}
                            onChange={(event) => {
                              setParticulars(event.target.value);
                              setError("");
                            }}
                            id="Particulars"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Size">
                            Size*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="Size"
                            required
                            value={Size}
                            onChange={(event) => {
                              setSize(event.target.value);
                              setError("");
                            }}
                            id="Size"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Quantity">
                            Quantity*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="Quantity"
                            required
                            value={Quantity}
                            onChange={(event) => {
                              const input = event.target.value;
                              const numericInput = input.replace(/[^0-9]/g, "");
                              setQuantity(numericInput);
                              setError("");
                            }}
                            id="Quantity"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Product_Price">
                            Quality*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="Quality"
                            required
                            value={Quality}
                            onChange={(event) => {
                              setQuality(event.target.value);
                              setError("");
                            }}
                            id="Quality"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <div>
                          {editingItem ? (
                            <Button
                              variant="contained"
                              endIcon={<AddCircleIcon />}
                              type="submit"
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              endIcon={<AddCircleIcon />}
                              type="submit"
                            >
                              Add
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </Stack>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title" style={{ margin: "10px 0 0 10px" }}>
              Filter
            </h4>
            <div className="card-body">
              <div className="row   justify-content-center">
                <div className="col-4">
                  {/* Your From Date DatePicker */}
                  <label className="form-label mb-2">From Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate.format("YYYY-MM-DD")}
                    onChange={(date) => setFromDate(dayjs(date.target.value))}
                  />
                </div>
                <div className="col-4">
                  {/* Your To Date DatePicker */}
                  <label className="form-label mb-2">To Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate.format("YYYY-MM-DD")}
                    onChange={(date) => setToDate(dayjs(date.target.value))}
                  />
                </div>
              </div>
              <div className="row mt-3 justify-content-center">
                <div className="col-2">
                  <Button variant="contained" onClick={handleDateRangeFilter}>
                    Filter
                  </Button>
                </div>
              </div>
            </div>
            <hr style={{ borderWidth: "2px", margin: 0 }} />
            <Stack
              direction="row"
              spacing={2}
              className="mx-2 my-2 mb-2"
              sx={{ zIndex: 0, position: "relative" }}
            >
              <TextField
                onChange={handleFilter}
                size="small"
                label="Search..."
              />
              <div>
                <Tooltip title="Export to PDF">
                  <IconButton
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      DownloadPDF();
                    }}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Export to Excel">
                  <IconButton
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      DownloadExcel();
                    }}
                  >
                    {" "}
                    <Icon path={mdiFileExcel} size={1} />
                  </IconButton>
                </Tooltip>
              </div>
            </Stack>
            <Box height={14} />

            <TableContainer
              className=""
              sx={{
                maxHeight: "100%",
                minHeight: "170px",
                zIndex: 0,
                position: "relative",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Date
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Particulars
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Size
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Quantity
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: "100px" }}>
                      Quality
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          style={{ height: "30px" }}
                        >
                          <TableCell align="left" style={{ padding: "5px" }}>
                            {formatDate(row.Date)}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "5px" }}>
                            {row.Particulars}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "5px" }}>
                            {row.Size}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "5px" }}>
                            {row.Quantity}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "5px" }}>
                            {row.Quality}
                          </TableCell>

                          <TableCell
                            className="text-cneter"
                            style={{ padding: "5px" }}
                          >
                            <IconButton
                              type="button"
                              className="btn shadow-none"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ padding: "0px", border: 0 }}
                            >
                              <i
                                className="ri-more-2-fill"
                                style={{ fontSize: "16px" }}
                              ></i>
                            </IconButton>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={() => editExpenses(row)}
                                >
                                  <i
                                    className="ri-edit-2-line"
                                    style={{
                                      color: "blue",
                                      fontSize: "16px",
                                    }}
                                  ></i>
                                  <span> Update</span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => {
                                    deleteUser(row.Sr_No); 
                                  }}
                                  className="dropdown-item cursor-pointer"
                                >
                                  <i
                                    className="ri-delete-bin-3-line"
                                    style={{
                                      color: "red",
                                      fontSize: "16px",
                                    }}
                                  ></i>
                                  <span> Delete</span>
                                </Link>
                              </li>
                            </ul>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="mt-3"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={records.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};

export default PrintData;
