import React from "react";
import "./App.css";
import SetTarget from "./pages/Target/SetTarget";
import ViewTarget from "./pages/Target/ViewTarget";
import EditTarget from "./pages/Target/EditTarget";
import Dashboard from "./componets/dashboard.js";
import AddClient from "./pages/Client/addClient.js";
import EditClient from "./pages/Client/editClient.js";
import OrgCategory from "./pages/Client/OrgCategory";
import EditStaff from "./pages/Staff/editStaff.js";
import ViewClient from "./pages/Client/viewClient.js";
import AddStaff from "./pages/Staff/addStaff.js";
import ViewStaff from "./pages/Staff/viewStaff.js";
import AddVender from "./pages/Venders/addVender";
import ViewVender from "./pages/Venders/viewVender";
import EditVendor from "./pages/Venders/editVender";
import CreateBill from "./pages/Expenses/CreateBill";
import ViewBill from "./pages/Expenses/ViewBill";
import ExpensesCategory from "./pages/Expenses/ExpensesCategory";
import Reporting from "./pages/Proforma/Reporting";
import Invoice from "./pages/Order/Invoice.js";
import Home from "./pages/Home/Home.js";
import { UserProvider } from "./componets/UserContext";
import Login from "./componets/Login.js";
import AddUser from "./pages/User/AddUser.js";
import ViewUser from "./pages/User/ViewUser.js";
import UpdateUser from "./pages/User/updateUser.js";
import ClientsOrder from "./pages/Client/ClientsOrder.js";
import Proforma from "./pages/Proforma/proforma";
import ViewProforma from "./pages/Proforma/ViewProforma";
import AddVisit from "./pages/DailyVisit/AddVisit";
import SelectedRemark from "./pages/DailyVisit/Selected_Remarks";
import ViewVisit from "./pages/DailyVisit/ViewVisit";
import AddProduct from "./pages/Order/Add_Products";
import ProductCategory from "./pages/Order/Product_Category";
import UnitOfMeasurement from "./pages/Order/Unit_Of_Measurement";
import CreateOrder from "./pages/Order/CreateOrder";
import UpdateOrder from "./pages/Order/UpdateOrder.js";
import ViewOrder from "./pages/Order/ViewOrder";
import LateHalfDay from "./pages/Staff/LateHalfDay.js";
import Holiday from "./pages/Staff/Holiday.js";
import BiometricReport from "./pages/Staff/StaffAttendance.js";
import CollectiveReport from "./pages/Staff/collective.js";
import PrintData from "./pages/Proforma/PrintData.js";
import IncomeExpensesReport from "./pages/Reports/IncomeExpensesReport.js";
import Ledger from "./pages/Reports/SalesLedger.js";
import IncomeExpensesLedger from "./pages/Reports/IncomeExpensesLedger.js";
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";

function App() {
  return (
      <UserProvider>
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<Dashboard />}>
              <Route path="" element={<Home />} />
              <Route path="/addUser" element={<AddUser />} />
              <Route path="/viewUser" element={<ViewUser />} />
              <Route path="/updateUser/:UserId" element={<UpdateUser />} />
              <Route path="/addClient" element={<AddClient />} />
              <Route path="/updateClient/:Sr_No" element={<EditClient />} />
              <Route path="/updateStaff/:Id" element={<EditStaff />} />
              <Route path="/viewClient" element={<ViewClient />} />
              <Route path="/orgCategory" element={<OrgCategory />} />
              <Route path="/addStaff" element={<AddStaff />} />
              <Route path="/viewStaff" element={<ViewStaff />} />
              <Route path="/addVender" element={<AddVender />} />
              <Route path="/viewVender" element={<ViewVender />} />
              <Route path="/createBill" element={<CreateBill />} />
              <Route path="/viewBill" element={<ViewBill />} />
              <Route path="/updateSupplier/:Sr_No" element={<EditVendor />} />
              <Route path="/addVisit" element={<AddVisit />} />
              <Route path="/viewVisit" element={<ViewVisit />} />
              <Route path="/setTarget" element={<SetTarget />} />
              <Route path="/viewTarget" element={<ViewTarget />} />
              <Route path="/updateTarget/:Id" element={<EditTarget />} />
              <Route path="/remarks" element={<SelectedRemark />} />
              <Route path="/proforma" element={<Proforma />} />
              <Route path="/viewProforma" element={<ViewProforma />} />
              <Route path="/expenses" element={<ExpensesCategory />} />
              <Route path="/reporting/:Sr_No" element={<Reporting />} />
              <Route path="/invoice/:Sr_No" element={<Invoice />} />
              <Route
                path="/unitOfMeasurement"
                element={<UnitOfMeasurement />}
              />
              <Route
                path="/holiday"
                element={<Holiday />}
              />
              <Route
                path="/lateHalfDay"
                element={<LateHalfDay />}
              />
              <Route path="/productCategory" element={<ProductCategory />} />
              <Route path="/addProduct" element={<AddProduct />} />
              <Route path="/order" element={<CreateOrder />} />
              <Route path="/updateOrder/:Sr_No" element={<UpdateOrder/>} />
              <Route path="/viewOrder" element={<ViewOrder />} />
              <Route path="/biometricReport" element={<BiometricReport />} />
              <Route path="/collectiveReport" element={<CollectiveReport />} />
              <Route path="/ClientsOrder/:Sr_No" element={<ClientsOrder />} />
              <Route path="/PrintData" element={<PrintData />} />
              <Route path="/IncomeExpensesReport" element={<IncomeExpensesReport />} />
              <Route path="/incomeExpensesLedger" element={<IncomeExpensesLedger />} />
              <Route path="/salesLedger" element={<Ledger />} />
            </Route>

            {/* <Route path="/salesPersonDashBoard" element={<SalesPersonDashboard />}>
         
         
        </Route> */}
            <Route path="/login" element={<Login />} />
            {/* <Route path="/salesPersonLogin" element={<SalesLogin />}/> */}
          </Routes>
        </BrowserRouter>
      </UserProvider>
  );
}

export default App;
